import React, { useContext, useState, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { MaxButton } from '../Pool/styleds'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { Text } from 'rebass'
import { useTokenBalance, useWalletState } from 'state/wallet/hooks'
import { useAllTokens, useToken } from 'hooks/Tokens'
import { ButtonError } from '../../components/Button'
import { Input as NumericalInput } from '../../components/NumericalInput'
import { BigNumber, ethers } from 'ethers'
import { Fraction, Rounding } from '@uniswap/sdk-core'
import { AMBI_TOKEN_ADDRESSES, CURRENT_CHAIN_ID, LP_TOKEN_DECIMAL, FarmAction } from '../../constants'
import {
  ConfirmationPendingContent,
  TransactionSubmittedContent,
} from './../../components/TransactionConfirmationModal/index'
import { getTokenId } from 'utils/hederaUtils'
import useConnector from 'hooks/useConnector'

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const BalanceContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f6f6f7;
  padding: 18px;
`

const MainSection = styled.div`
  margin: 40px 0;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 24px;
  font-weight: 600;
  font-size: 18px;
  color: #27292c;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const PercentButton = styled(MaxButton)`
  background-color: white;
  color: #848485;
`
const PercentContainer = styled.div`
  display: flex;
  justify-content: center;
  ${PercentButton} {
    border-radius: 0px;
    padding: 0px 16px;
    border: 1px solid transparent;
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    &:last-child {
      border-right: none;
    }
  }
`
type StakeFarmModalProps = {
  toggleModal: () => void
  lpAddress: string
  lpSymbol: string
  onConfirm: (amount: BigNumber) => void
  attemptingTxn: boolean
  hash: string
  type: FarmAction
  totalStakedAmountLP: any
  totalStakedAmountAMBI: any
}

export default function StakeFarmModal({
  toggleModal,
  lpAddress,
  lpSymbol,
  onConfirm,
  attemptingTxn = false,
  hash = '',
  type,
  totalStakedAmountLP,
  totalStakedAmountAMBI,
}: StakeFarmModalProps) {
  const { account, chainId } = useWalletState()
  const connector = useConnector()
  const [balance, setBalance] = useState(BigNumber.from(0))
  const [amount, setAmount] = useState('')

  const convertToSignificant = (amount: BigNumber) => {
    return new Fraction(amount.toString(), Math.pow(10, LP_TOKEN_DECIMAL)).toFixed(6, undefined, Rounding.ROUND_DOWN)
  }

  const convertToBigNumber = (amount: string) => {
    return ethers.utils.parseUnits(Number(amount).toFixed(LP_TOKEN_DECIMAL), LP_TOKEN_DECIMAL)
  }

  const isValid = (amount: string) => {
    return amount.replaceAll('.', '').replaceAll('0', '').length > 0
  }

  const onPercent = (percent: number) => {
    setAmount(convertToSignificant(balance.mul(percent).div(100)))
  }

  useEffect(() => {
    if (account && connector) {
      if (type === FarmAction.STAKE_LP || type === FarmAction.STAKE_AMBI) {
        const lpTokenId = getTokenId(type === FarmAction.STAKE_LP ? lpAddress : AMBI_TOKEN_ADDRESSES[CURRENT_CHAIN_ID])
        connector.getTokenBalances(account).then(({ tokens }) => {
          if (lpTokenId && tokens && tokens.get(lpTokenId)) {
            const numerator = BigNumber.from((tokens.get(lpTokenId) ?? 0).toString())
            setBalance(numerator)
          }
        })
      }
      if (type === FarmAction.UNSTAKE_LP || type === FarmAction.UNSTAKE_AMBI) {
        setBalance(
          BigNumber.from(
            parseFloat((type === FarmAction.UNSTAKE_LP ? totalStakedAmountLP : totalStakedAmountAMBI) ?? 0) *
              Math.pow(10, LP_TOKEN_DECIMAL)
          )
        )
      }
    }
  }, [account, connector, lpAddress, totalStakedAmountAMBI, totalStakedAmountLP, type])

  const pendingText = type.replace('ake', 'aking').concat(' tokens')

  return (
    <UpperSection>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={toggleModal} pendingText={pendingText} />
      ) : hash.length ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={toggleModal} />
      ) : (
        <>
          <HeaderRow>
            <CloseIcon onClick={toggleModal}>
              <CloseColor />
            </CloseIcon>
            {type.includes('Unstake') ? 'Unstake' : 'Stake'} {type.includes('AMBI') ? 'AMBI' : lpSymbol}
          </HeaderRow>
          <MainSection>
            <AutoColumn gap="30px">
              <Row justify="center">
                <NumericalInput
                  className="token-amount-input"
                  value={amount}
                  onUserInput={(val) => {
                    setAmount(val)
                  }}
                  style={{ color: '#27292c66', fontSize: '30px' }}
                  autoFocus={true}
                />
              </Row>
              <PercentContainer>
                <PercentButton width="20%" onClick={() => onPercent(25)}>
                  25%
                </PercentButton>
                <PercentButton width="20%" onClick={() => onPercent(50)}>
                  50%
                </PercentButton>
                <PercentButton width="20%" onClick={() => onPercent(75)}>
                  75%
                </PercentButton>
                <PercentButton width="20%" onClick={() => onPercent(100)}>
                  Max
                </PercentButton>
              </PercentContainer>
              <BalanceContainer>
                <Text fontWeight={500} color="#4c5055" fontSize={16} paddingRight="5px">
                  {type.includes('Unstake') ? 'Currently staked: ' : 'Available wallet balance: '}
                </Text>
                <Text fontWeight={600} color="#27292C" fontSize={16}>
                  {convertToSignificant(balance)}
                </Text>
              </BalanceContainer>
            </AutoColumn>
            <AutoColumn style={{ padding: '0 24px' }}>
              <ButtonError
                error={false}
                style={{ backgroundColor: '#6437E7', marginTop: '40px' }}
                disabled={!isValid(amount)}
                onClick={() => onConfirm(convertToBigNumber(amount))}
              >
                <Text fontSize={16} fontWeight={500}>
                  {!isValid(amount) ? 'Enter a value' : 'Confirm'}
                </Text>
              </ButtonError>
            </AutoColumn>
          </MainSection>
        </>
      )}
    </UpperSection>
  )
}
