import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
// import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Farm from './Farm'
import Lend from './Lend'
import LendList from './Lend/list'
import Pool from './Pool'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import {
  OpenClaimAddressModalAndRedirectToSwap,
  RedirectPathToSwapOnly,
  RedirectPathToLendListOnly,
  RedirectToSwap,
} from './Swap/redirects'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import LendPage from './Lend'
import CheckWhitelisted from './CheckWhitelisted'
import { PositionPage } from './Pool/PositionPage'
import { ThemedBackground } from '../theme'
import Sidebar from 'components/Sidebar'
import axios from 'axios'
import { BLOCKED_COUNTRIES } from '../constants/index'
import PrivateRoute from './PrivateRoute'
import { usePollCredentialTokens, useWalletState } from 'state/wallet/hooks'
import ChatBot from 'components/ChatBot'

const AppWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
`

const BodyWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  padding: 0 100px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
`
const HeaderNavWrapper = styled.div`
  display: flex;
  gap: 58px;
`
type HeaderNavProps = {
  active?: boolean
  status?: 'error' | 'success'
}
const HeaderNav = styled.button<HeaderNavProps>`
  background: none;
  outline: none;
  border: none;
  border-bottom: ${(props) => (props.active ? '2px solid #6437e7;' : 'none')};
  font-size: 16px;
  padding: 30px 0;
  color: ${(props) => (props.active ? '#6437e7;' : 'none')};
  font-weight: 700;
  cursor: pointer;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const IP_API_KEY = process.env.REACT_APP_IP_API_KEY ?? 'testnet'

export default function App() {
  const getCountryCode = async () => {
    // TODO: Commenting this out for now since we don't need the geofencing at the moment
    // const res = await axios.get(`https://pro.ip-api.com/json?key=${IP_API_KEY}`)
    // if (BLOCKED_COUNTRIES.includes(res?.data?.countryCode)) {
    //   alert('Users from Japan are not allowed to use our platform, sorry!')
    // }
  }

  const { account, credentialTokens } = useWalletState()

  useEffect(() => {
    getCountryCode()
  }, [])

  usePollCredentialTokens()

  if (account && !credentialTokens) return <></>

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <Sidebar />
        <BodyWrapper>
          {/* <HeaderWrapper>
            <HeaderNavWrapper>
              <HeaderNav>Dashboard</HeaderNav>
              <HeaderNav active>Loan Token List</HeaderNav>
            </HeaderNavWrapper>
            <Header />
          </HeaderWrapper> */}
          <ContentWrapper>
            {/* <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}> */}
            <Popups />
            {/* <Polling /> */}
            <Switch>
              {/* <PrivateRoute exact strict path="/vote" component={Vote} />
                <PrivateRoute exact strict path="/vote/:id" component={VotePage} />
                <PrivateRoute exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                <PrivateRoute exact strict path="/uni" component={Earn} />
                <PrivateRoute exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}

              <PrivateRoute exact strict path="/send" component={RedirectPathToSwapOnly} />
              <PrivateRoute exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <PrivateRoute exact strict path="/swap" component={Swap} />
              <PrivateRoute exact strict path="/farm" component={Farm} />
              <PrivateRoute exact strict path="/lend/detail/:loanId" component={Lend} />
              <PrivateRoute exact strict path="/lend/detail-testnet/:loanAddress" component={Lend} />
              <PrivateRoute exact strict path="/lend/list" component={LendList} />
              <PrivateRoute exact strict path="/find" component={PoolFinder} />
              <PrivateRoute exact strict path="/liquidity" component={PoolV2} />
              <PrivateRoute exact strict path="/liquidity/:tokenId" component={PositionPage} />

              <PrivateRoute
                exact
                strict
                path="/add/:currencyIdA?/:currencyIdB?"
                component={RedirectDuplicateTokenIdsV2}
              />
              <PrivateRoute exact strict path="lend" component={LendPage} />
              <Route exact strict path="/connect-wallet" component={CheckWhitelisted} />

              <PrivateRoute exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

              <Route component={RedirectPathToLendListOnly} />
            </Switch>
            {/* </div> */}
          </ContentWrapper>
        </BodyWrapper>
        <ChatBot />
      </AppWrapper>
    </Suspense>
  )
}
