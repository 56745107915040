import { getCreate2Address } from '@ethersproject/address'
import { keccak256, pack } from '@ethersproject/solidity'
import { ContractId, TokenId } from '@hashgraph/sdk'
import { CURRENT_CHAIN_ID, PAIR_INIT_CODE_HASH, V2_FACTORY_CONTRACT_IDS } from 'constants/index'

export function isTokenId(id: string): string | false {
  try {
    const tokenId = TokenId.fromString(id)
    return tokenId.toString()
  } catch (err) {
    return false
  }
}

export function getTokenId(addr: string): string | undefined {
  try {
    const tokenId = TokenId.fromSolidityAddress(addr)
    return tokenId.toString()
  } catch {
    return undefined
  }
}

export async function getPairAddress(tokenA: string, tokenB: string): Promise<string> {
  const tokens =
    tokenA.toLowerCase() < tokenB.toLowerCase()
      ? [tokenA.toLowerCase(), tokenB.toLowerCase()]
      : [tokenB.toLowerCase(), tokenA.toLowerCase()]

  const pairAddr = getCreate2Address(
    `0x${ContractId.fromString(V2_FACTORY_CONTRACT_IDS[CURRENT_CHAIN_ID]).toSolidityAddress()}`,
    keccak256(['bytes'], [pack(['address', 'address'], [tokens[0], tokens[1]])]),
    PAIR_INIT_CODE_HASH[CURRENT_CHAIN_ID]
  )

  return pairAddr
}
