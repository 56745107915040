import { Currency } from '@uniswap/sdk-core'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { ExternalLink } from '../../theme'
import { Text } from 'rebass'
import { CloseIcon, CustomLightSpinner } from '../../theme/components'
import { RowBetween, RowFixed } from '../Row'
import { AlertTriangle, ArrowUpCircle, CheckCircle } from 'react-feather'
import { ButtonPrimary, ButtonLight } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/images/confirmation-loader.svg'
import Check from '../../assets/images/check.svg'
import ExternalLinkIcon from '../../assets/images/external_link.svg'
import { getHashscanLink } from '../../utils'
import { useWalletState } from '../../state/wallet/hooks'
import { AmbidexChainId } from 'constants/networks'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '60px 0;')};
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`
const CloseIconContaiiner = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

export function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  pendingText: string
  inline?: boolean // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md" style={{ padding: '24px' }}>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={600} fontSize={24} textAlign="center">
            Waiting For Confirmation
          </Text>
          {!inline && (
            <CloseIconContaiiner>
              <CloseIcon onClick={onDismiss} />
            </CloseIconContaiiner>
          )}
        </AutoColumn>
        <ConfirmedIcon inline={inline}>
          <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={600} fontSize={16} color="#27292c" textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
        <Text fontSize={16} color="#27292c" textAlign="center" marginBottom={12} opacity={'0.6'}>
          Confirm this transaction in your wallet
        </Text>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: AmbidexChainId
  currencyToAdd?: Currency | undefined
  inline?: boolean // not in modal
}) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section inline={inline} style={{ padding: '24px' }}>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={600} fontSize={24} textAlign="center">
            Transaction Submitted
          </Text>
          {!inline && (
            <CloseIconContaiiner>
              <CloseIcon onClick={onDismiss} />
            </CloseIconContaiiner>
          )}
        </AutoColumn>
        <ConfirmedIcon inline={inline}>
          <img src={Check} alt="transaction check" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          {chainId && hash && (
            <ExternalLink href={getHashscanLink(chainId, hash, 'transaction')}>
              <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                View on HashScan
              </Text>
              <img src={ExternalLinkIcon} alt="external link" />
            </ExternalLink>
          )}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent?: () => React.ReactNode | undefined
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween padding="24px" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}>
          <Text fontWeight={600} fontSize={24}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      {bottomContent && <BottomSection gap="12px">{bottomContent()}</BottomSection>}
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section style={{ padding: '24px' }}>
        <RowBetween>
          <Text fontWeight={600} fontSize={24}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
        <BottomSection gap="12px">
          <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
        </BottomSection>
      </Section>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}: ConfirmationModalProps) {
  const { chainId } = useWalletState()
  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
