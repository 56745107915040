import React from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import arrowLeftSvg from '../../assets/images/arrow-left.svg'
import Header from '../../components/Header'

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 50px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 18px 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 18px 30px;
  `};
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const BackButton = styled.div`
  color: #27292c;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default function PageHeader() {
  const history = useHistory()

  return (
    <HeaderWrapper>
      <BackButton onClick={() => history.push('/lend/list')}>
        <img src={arrowLeftSvg} />
        <Text ml="1">All borrowers</Text>
      </BackButton>
      <Header />
    </HeaderWrapper>
  )
}
