import React, { ReactNode } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useWalletState } from '../state/wallet/hooks'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { isAuthed } = useWalletState()

  return (
    <Route exact {...rest}>
      {isAuthed ? children : <Redirect to="/connect-wallet" />}
    </Route>
  )
}

export default PrivateRoute
