import { Router, Trade as V2Trade, SwapParameters } from '@ambidex/sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { Percent, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import useTransactionDeadline from './useTransactionDeadline'
import useENS from './useENS'
import { useWalletState } from '../state/wallet/hooks'
import { CredentialTokenType } from './useCredentialTokens'
import useConnector from './useConnector'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName the ENS name or address of the recipient of the swap output
 * @param signatureData the signature data of the permit of the input token amount, if available
 */
function useSwapCallArguments(
  trade: V2Trade | V3Trade | undefined, // trade to execute, required
  allowedSlippage: Percent = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): SwapParameters | undefined {
  const { account, chainId } = useWalletState()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress
  const deadline = useTransactionDeadline()

  return useMemo(() => {
    if (!trade || !recipient || !account || !chainId || !deadline) return undefined

    if (trade instanceof V2Trade) {
      return Router.swapCallParameters(trade, {
        feeOnTransfer: false, // trade.tradeType === TradeType.EXACT_INPUT ? true : false,
        allowedSlippage,
        recipient,
        deadline: deadline.toNumber(),
      })
    } else {
      return undefined
    }
  }, [account, allowedSlippage, chainId, deadline, recipient, trade])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: V2Trade | V3Trade | undefined, // trade to execute, required
  allowedSlippage: Percent = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  dexCredentialToken: CredentialTokenType | undefined // DEX credential token
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId } = useWalletState()
  const connector = useConnector()

  const swapParameters = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName)
  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress
  return useMemo(() => {
    if (!trade || !account || !chainId || !connector) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }
    if (!dexCredentialToken) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing credential token' }
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async function (): Promise<string> {
        if (typeof swapParameters !== 'undefined') {
          const { serial_number } = dexCredentialToken
          const { methodName, args, value } = swapParameters
          try {
            return await connector.swap(methodName, args, value, serial_number)
          } catch (error: any) {
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error)
              throw new Error(`Swap failed. ${error.message}`)
            }
          }
        }
        throw new Error(`Swap failed: The swap parameters are empty`)
      },
      error: null,
    }
  }, [trade, account, chainId, recipient, recipientAddressOrName, allowedSlippage, swapParameters])
}
