import { blade, hashpack } from '../connectors'

import HASHPACK_ICON_URL from '../assets/images/hashpackIcon.svg'
import BLADE_ICON_URL from '../assets/images/bladeIcon.svg'

export interface WalletInfo {
  connector?: any
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  HASHPACK: {
    connector: hashpack,
    name: 'Hashpack',
    iconURL: HASHPACK_ICON_URL,
    description: 'Login using Hashpack wallet',
    href: null,
    color: '#00000099',
    mobile: true,
  },
  BLADE: {
    connector: blade,
    name: 'Blade',
    iconURL: BLADE_ICON_URL,
    description: 'Login using Blade wallet',
    href: null,
    color: '#00000099',
    mobile: true,
  },
}
