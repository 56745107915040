import { Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@ambidex/sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import React, { useContext, useState } from 'react'
import { ArrowDown, AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { AutoColumn } from '../Column'
import { FiatValue } from '../CurrencyInputPanel/FiatValue'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'

import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import { LightCard } from '../Card'

import { DarkGreyCard } from '../Card'
import TradePrice from '../swap/TradePrice'

export const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  position: relative;
  margin-top: -14px;
  margin-bottom: -14px;
  left: calc(50% - 16px);
  /* transform: rotate(90deg); */
  z-index: 2;
  transition: box-shadow 0.5s;
`
const ConfirmationTokenFormInput = styled.div<{ label: string }>`
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 24px 14px 16px 14px;
  font-size: 12px;
  color: #27292c;
  position: relative;
  &:after {
    content: '${(props) => props.label}';
    color: #27292c;
    font-size: 20px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    top: 4px;
    left: 14px;
  }
`
const SummaryCard = styled.div`
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border-radius: 12px;
  padding: 19px 14px;
`
export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: V2Trade | V3Trade
  allowedSlippage: Percent
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const maximumAmountIn = trade.maximumAmountIn(allowedSlippage)
  const minimumAmountOut = trade.minimumAmountOut(allowedSlippage)

  const theme = useContext(ThemeContext)

  const [showInverted, setShowInverted] = useState<boolean>(false)

  const fiatValueInput = useUSDCValue(maximumAmountIn)
  const fiatValueOutput = useUSDCValue(minimumAmountOut)

  return (
    <AutoColumn gap={'14px'} style={{ marginTop: '1rem' }}>
      <ConfirmationTokenFormInput label="From">
        <AutoColumn gap={'8px'}>
          <RowBetween align="center">
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={24}
                fontWeight={500}
                color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : ''}
              >
                {maximumAmountIn.toSignificant(6)}
              </TruncatedText>
              <span style={{ paddingLeft: '10px' }}>
                <FiatValue fiatValue={fiatValueInput} />
              </span>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.inputAmount.currency} size={'20px'} style={{ marginRight: '12px' }} />
              <Text fontSize={16} fontWeight={400}>
                {trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </ConfirmationTokenFormInput>
      <ArrowWrapper>
        <ArrowDown size="24" color={theme.text3} />
      </ArrowWrapper>
      <ConfirmationTokenFormInput label="To">
        <AutoColumn gap={'8px'}>
          <RowBetween align="center">
            <RowFixed gap={'0px'}>
              <TruncatedText fontSize={24} fontWeight={500}>
                {minimumAmountOut.toSignificant(6)}
              </TruncatedText>
              <TYPE.body fontSize={14} color={theme.text3} paddingLeft="10px">
                <FiatValue
                  fiatValue={fiatValueOutput}
                  priceImpact={computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)}
                />
              </TYPE.body>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.outputAmount.currency} size={'20px'} style={{ marginRight: '12px' }} />
              <Text fontSize={16} fontWeight={400}>
                {trade.outputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </ConfirmationTokenFormInput>

      <SummaryCard>
        <RowBetween>
          <TYPE.body color={theme.text2} fontWeight={400} fontSize={12}>
            {'Price'}
          </TYPE.body>
          <TradePrice
            price={trade.worstExecutionPrice(allowedSlippage)}
            showInverted={showInverted}
            setShowInverted={setShowInverted}
          />
        </RowBetween>
        <AdvancedSwapDetails trade={trade} />
      </SummaryCard>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <TYPE.main color={theme.primary1}> Price Updated</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
            >
              Accept
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}

      {/* <AutoColumn justify="flex-start" gap="sm" style={{ padding: '.75rem 1rem' }}>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.italic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
            {`Output is estimated. You will receive at least `}
            <b>
              {minimumAmountOut.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </TYPE.italic>
        ) : (
          <TYPE.italic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
            {`Input is estimated. You will sell at most `}
            <b>
              {maximumAmountIn.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </TYPE.italic>
        )}
      </AutoColumn> */}
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
