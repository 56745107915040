import { AccountId } from '@hashgraph/sdk'
import { createAsyncThunk, createReducer, isAnyOf } from '@reduxjs/toolkit'
import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
} from '@reduxjs/toolkit/dist/matchers'
import stringify from 'fast-json-stable-stringify'
import { AppState } from 'state'
import { getExchangeRate } from 'utils/hederaMirror'
import { axiosInstance as queryProxy } from 'utils/queryProxy'

import { Farm, FarmsState, FarmUserData, PublicFarmResponse } from './types'

const initialState: FarmsState = {
  data: [],
  loadingKeys: {},
  dailyVolumeDataLoaded: false,
  publicDataLoaded: false,
  userDataLoaded: false,
  fetchDataFailed: false,
}

// Async thunks
export const fetchFarmsDataAsync = createAsyncThunk<Farm[], void, { state: AppState }>(
  'farms/fetchFarmsDataAsync',
  async () => {
    const [{ data: farms }, exchangeRate] = await Promise.all([
      queryProxy.get<PublicFarmResponse[]>('/v2/farms'),
      getExchangeRate(),
    ])

    return farms.map((farm: PublicFarmResponse) => ({ ...farm, exchangeRate }))
  },
  {
    condition: (arg, { getState }) => {
      const { farms } = getState()
      if (farms.loadingKeys[stringify({ type: fetchFarmsDataAsync.typePrefix, arg })]) {
        console.debug('farms action is fetching, skipping here')
        return false
      }
      return true
    },
  }
)

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserData[], string, { state: AppState }>(
  'farms/fetchFarmUserDataAsync',
  async (account) => {
    const { data } = await queryProxy.get(`/v2/farms/user?account=${AccountId.fromString(account).toSolidityAddress()}`)

    return data
  },
  {
    condition: (arg, { getState }) => {
      const { farms } = getState()
      if (farms.loadingKeys[stringify({ type: fetchFarmUserDataAsync.typePrefix, arg })]) {
        console.debug('farms user action is fetching, skipping here')
        return false
      }
      return true
    },
  }
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus']
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchFarmsDataAsync.fulfilled, (state, action) => {
    state.data = action.payload
    state.publicDataLoaded = true
  })

  builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
    action.payload.forEach((userDataEl) => {
      const { pid } = userDataEl
      const index = state.data.findIndex((farm) => farm.pid === pid)
      state.data[index] = { ...state.data[index], userData: userDataEl }
    })
    state.userDataLoaded = true
  })

  builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.pending, fetchFarmsDataAsync.pending), (state, action) => {
    state.loadingKeys[serializeLoadingKey(action, 'pending')] = true
  })
  builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.fulfilled, fetchFarmsDataAsync.fulfilled), (state, action) => {
    state.loadingKeys[serializeLoadingKey(action, 'fulfilled')] = false
  })
  builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.rejected, fetchFarmsDataAsync.rejected), (state, action) => {
    state.loadingKeys[serializeLoadingKey(action, 'rejected')] = false
    state.fetchDataFailed = true
  })
})
