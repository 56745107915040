import { ChainId, Percent, Token, WETH9 } from '@uniswap/sdk-core'
import { ContractId, TokenId } from '@hashgraph/sdk'
import JSBI from 'jsbi'

import { AmbidexChainId } from './networks'

export { PRELOADED_PROPOSALS } from './proposals'

import { TESTNET_TOKENS, MAINNET_TOKENS } from './tokens'

// https://docs.hedera.com/guides/mainnet/mainnet-nodes
export const HEDERA_NODE_IDS = (process.env.REACT_APP_HEDERA_NODES || '3,4,5,6,7,8,9').split(',')
export const HEDERA_TOKEN_DECIMAL = 6 //TODO: Should be defined from v2-sdk
export const HBAR_DECIMAL = 8
export const CURRENT_CHAIN_ID: AmbidexChainId = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? AmbidexChainId.HEDERA_TESTNET.toString()
)
export const LP_TOKEN_DECIMAL = 6

export const V2_FACTORY_CONTRACT_IDS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0.0.2023204',
  [AmbidexChainId.HEDERA_TESTNET]: '0.0.3669218',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const V2_ROUTER_CONTRACT_IDS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0.0.2023326',
  [AmbidexChainId.HEDERA_TESTNET]: '0.0.3669222',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const V2_ROUTER_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: `0x${ContractId.fromString('0.0.2023326').toSolidityAddress()}`,
  [AmbidexChainId.HEDERA_TESTNET]: `0x${ContractId.fromString('0.0.3669222').toSolidityAddress()}`,
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const WHBAR_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0x00000000000000000000000000000000001edf17',
  [AmbidexChainId.HEDERA_TESTNET]: '0x000000000000000000000000000000000037FCDF',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const USDC_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: `0x${TokenId.fromString('0.0.456858').toSolidityAddress()}`,
  [AmbidexChainId.HEDERA_TESTNET]: `0x${TokenId.fromString('0.0.6024').toSolidityAddress()}`,
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const MASTERCHEF_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: `0x${ContractId.fromString('0.0.2023186').toSolidityAddress()}`,
  [AmbidexChainId.HEDERA_TESTNET]: `0x${ContractId.fromString('0.0.3669212').toSolidityAddress()}`,
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const AMBI_TOKEN_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0x00000000000000000000000000000000001edf0e',
  [AmbidexChainId.HEDERA_TESTNET]: '0x000000000000000000000000000000000037fcb6',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const PAIR_INIT_CODE_HASH: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0xa46f7ab029b76500bbb6a89374c08caac49138f45c7b9f759ca67e9296501ef1',
  [AmbidexChainId.HEDERA_TESTNET]: '0xa46f7ab029b76500bbb6a89374c08caac49138f45c7b9f759ca67e9296501ef1',
  [AmbidexChainId.MAINNET]: '0x0000000000000000000000000000000000000000000000000000000000000000',
  [AmbidexChainId.ROPSTEN]: '0x0000000000000000000000000000000000000000000000000000000000000000',
  [AmbidexChainId.RINKEBY]: '0x0000000000000000000000000000000000000000000000000000000000000000',
  [AmbidexChainId.GÖRLI]: '0x0000000000000000000000000000000000000000000000000000000000000000',
  [AmbidexChainId.KOVAN]: '0x0000000000000000000000000000000000000000000000000000000000000000',
}

export const LOAN_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0x0000000000000000000000000000000000000000',
  [AmbidexChainId.HEDERA_TESTNET]: '0x00000000000000000000000000000000000018af',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const MULTICALL2_ADDRESSES: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '',
  [AmbidexChainId.HEDERA_TESTNET]: '',
  [AmbidexChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [AmbidexChainId.ROPSTEN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [AmbidexChainId.KOVAN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [AmbidexChainId.RINKEBY]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [AmbidexChainId.GÖRLI]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const TokenList = {
  [AmbidexChainId.HEDERA_MAINNET]: [
    new Token(AmbidexChainId.HEDERA_MAINNET, WHBAR_ADDRESSES[AmbidexChainId.HEDERA_MAINNET], HBAR_DECIMAL, 'HBAR'),
    ...MAINNET_TOKENS.map(
      ({ symbol, id, decimals }) =>
        new Token(AmbidexChainId.HEDERA_MAINNET, '0x' + TokenId.fromString(id).toSolidityAddress(), decimals, symbol)
    ),
  ],
  [AmbidexChainId.HEDERA_TESTNET]: [
    new Token(AmbidexChainId.HEDERA_TESTNET, WHBAR_ADDRESSES[AmbidexChainId.HEDERA_TESTNET], HBAR_DECIMAL, 'HBAR'),
    ...TESTNET_TOKENS.map(
      ({ symbol, id, decimals }) =>
        new Token(AmbidexChainId.HEDERA_TESTNET, '0x' + TokenId.fromString(id).toSolidityAddress(), decimals, symbol)
    ),
  ],
  [AmbidexChainId.MAINNET]: [],
  [AmbidexChainId.ROPSTEN]: [],
  [AmbidexChainId.RINKEBY]: [],
  [AmbidexChainId.GÖRLI]: [],
  [AmbidexChainId.KOVAN]: [],
}

export const HEDERA_MIRROR_API: { [chainId in AmbidexChainId]?: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: 'https://mainnet-public.mirrornode.hedera.com/api/v1',
  [AmbidexChainId.HEDERA_TESTNET]: 'https://testnet.mirrornode.hedera.com/api/v1',
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const AMPL = new Token(
  AmbidexChainId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)
export const DAI = new Token(
  AmbidexChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC = new Token(CURRENT_CHAIN_ID, USDC_ADDRESSES[CURRENT_CHAIN_ID], 6, 'USDC', 'USD//C')
export const USDT = new Token(
  AmbidexChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(
  AmbidexChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const FEI = new Token(AmbidexChainId.MAINNET, '0x956F47F50A910163D8BF957Cf5846D573E7f87CA', 18, 'FEI', 'Fei USD')
export const TRIBE = new Token(
  AmbidexChainId.MAINNET,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
)
export const FRAX = new Token(AmbidexChainId.MAINNET, '0x853d955aCEf822Db058eb8505911ED77F175b99e', 18, 'FRAX', 'Frax')
export const FXS = new Token(
  AmbidexChainId.MAINNET,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
)
export const renBTC = new Token(
  AmbidexChainId.MAINNET,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
)

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
// HEDERA NODE IDS 3 - 28

export const UNI: { [chainId in AmbidexChainId]: Token } = {
  [AmbidexChainId.HEDERA_MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.HEDERA_TESTNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.RINKEBY]: new Token(ChainId.RINKEBY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.GÖRLI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [AmbidexChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock',
}

export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in AmbidexChainId]?: string } = {
  [AmbidexChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH9[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH9[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH9[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH9[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH9[ChainId.KOVAN]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [/*...WETH_ONLY[ChainId.MAINNET], DAI, */ USDC /*, USDT, WBTC */],
}

export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    // '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18': [UNI[ChainId.MAINNET]],
    // '0x561a4717537ff4AF5c687328c0f7E90a319705C0': [UNI[ChainId.MAINNET]],
    // [FEI.address]: [TRIBE],
    // [TRIBE.address]: [FEI],
    // [FRAX.address]: [FXS],
    // [FXS.address]: [FRAX],
    // [WBTC.address]: [renBTC],
    // [renBTC.address]: [WBTC],
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in AmbidexChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WETH9[ChainId.MAINNET]],
  // },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: Partial<ChainTokenList> = {
  [ChainId.MAINNET]: [DAI, USDC, USDT, WBTC],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin'),
    ],
    [USDC, USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = new Percent(10, 10_000)
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
]

export const ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS = '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8'

export const V1_MIGRATOR_ADDRESS = '0x16D4F26C15f3658ec65B1126ff27DD3dF2a2996b'

// TODO: We should investigate in the future how we might calculate/approximate the amount of gas needed, depending on the number of hops in the swap
export const GAS_SWAP_HBAR = 1_200_000
export const GAS_SWAP_NON_HBAR = 1_200_000

export const GAS_ADD_LIQUIDITY_NEW_PAIR = 6_300_000
export const GAS_ADD_LIQUIDITY_EXISTING_PAIR = 1_200_000
export const GAS_ADD_LIQUIDITY_EXISTING_HBAR_PAIR = 1_500_000
export const GAS_REMOVE_LIQUIDITY = 300_000
export const GAS_REMOVE_LIQUIDITY_HBAR = 1_800_000
export const GAS_CREATE_PAIR = 3_500_000

export enum FarmAction {
  IDLE = 'Idle',
  STAKE_LP = 'Stake LP',
  UNSTAKE_LP = 'Unstake LP',
  STAKE_AMBI = 'Stake AMBI',
  UNSTAKE_AMBI = 'Unstake AMBI',
  HARVEST = 'Harvest',
}

export const GAS_MASTERCHEF_ACTION: Record<FarmAction, number> = {
  [FarmAction.STAKE_LP]: 300_000,
  [FarmAction.UNSTAKE_LP]: 300_000,
  [FarmAction.STAKE_AMBI]: 400_000,
  [FarmAction.UNSTAKE_AMBI]: 400_000,
  [FarmAction.IDLE]: 0,
  [FarmAction.HARVEST]: 250_000,
}

export const GAS_LOAN_ACTION = 500_000
export const GAS_LOAN_STAKING_ACTION = 500_000

export const USD_AMOUNT_CONTRACT_CREATE = 1.2 // in dollar

export const FUNCTIONS = {
  V2_ROUTER_CONTRACT_ID: {
    getPair: 'getPair',
  },
}

export const RESERVE_CACHE_DURATION = 300 * 1000 // 30 seconds

export const ERROR_MESSAGE = {
  INVALID_CONTRACT: 'ERROR_INVALID_CONTRACT',
}

export const BLOCKED_COUNTRIES = ['JP']

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const GA4_ID = {
  MAINNET: 'G-56XQB2Q0ZL',
  TESTNET: 'G-BRV9ZMLX9B',
}

export const CREDENTIAL_TOKEN_IDS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: '0.0.2023175',
  [AmbidexChainId.HEDERA_TESTNET]: '0.0.3669159',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const DEX_CREDENTIAL_TOKEN_METADATAS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_TESTNET]: 'ipfs://QmR69q2J6BrqseM9WjY2BzpGXxi6H1Q5fzBjfP1yjbxPct',
  [AmbidexChainId.HEDERA_MAINNET]: 'ipfs://QmWC4NyuqR229UHBzf17wr2dnAptfzcJ4V4aGDVpmLyXPQ',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const PERSONA_ENVIRONMENT_IDS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: 'env_iRTaNHWkWsmGE9frPFjKFXXA',
  [AmbidexChainId.HEDERA_TESTNET]: 'env_kxEWvkiLeNr9i4HBuGzNddwk',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}

export const BLADE_WALLET_LOCALSTORAGE_KEY = 'AmbidexBladeWalletData'
export const HASHPACK_LOCALSTORAGE_KEY = 'hashconnectData'

export const AMBIDEX_CHATBOT_API_URLS: { [chainId in AmbidexChainId]: string } = {
  [AmbidexChainId.HEDERA_MAINNET]: 'https://ambidex-chatbot-api.herokuapp.com',
  [AmbidexChainId.HEDERA_TESTNET]: 'https://ambidex-chatbot-api.herokuapp.com',
  [AmbidexChainId.MAINNET]: '',
  [AmbidexChainId.ROPSTEN]: '',
  [AmbidexChainId.KOVAN]: '',
  [AmbidexChainId.RINKEBY]: '',
  [AmbidexChainId.GÖRLI]: '',
}
