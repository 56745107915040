import { ChainId } from '@uniswap/sdk-core'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import styled from 'styled-components'

import Row, { RowFixed } from '../Row'
import { useTranslation } from 'react-i18next'
import { useWalletState } from '../../state/wallet/hooks'
import { LOAN_ADDRESSES, CURRENT_CHAIN_ID } from '../../constants'

import MenuArrows from '../../assets/images/menu/menu_arrows.svg'
import MenuLiquidity from '../../assets/images/menu/menu_liquidity.svg'
import MenuFarm from '../../assets/images/menu/menu_farming.svg'
import MenuLend from '../../assets/images/menu/menu_lend.svg'
import MenuExchange from '../../assets/images/menu/menu_exchange.svg'
import AdxLogo from '../../assets/images/adx_logo.svg'
import UnionSocial from '../../assets/images/social/union.svg'
import DiscordSocial from '../../assets/images/social/discord.svg'
import TwitterSocial from '../../assets/images/social/twitter.svg'
import GitBookSocial from '../../assets/images/social/gitbook.svg'
import { Box } from 'rebass'

const SidebarFrame = styled.div`
  background-color: #6437e7;
  min-width: 340px;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none
  `};
  font-family: 'Mulish', 'Kanit', 'Open Sans', sans-serif;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 32px;
  font-size: 16px;
  width: auto;
  opacity: 0.5;
  &.${activeClassName} {
    background-color: #aeaeae13;
    color: #ffffff;
    opacity: 1;
  }

  :hover,
  :focus {
    background-color: #00000013;
  }
  img {
    margin-right: 15px;
  }
  :first-of-type {
    margin-top: 0px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 10px;
    width: 50px;
    overflow: hidden;
    border-radius: 0px;
  `};
`

const StyledNavText = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px;
  outline: none;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 32px;
  font-size: 16px;
  width: auto;
  opacity: 0.5;

  img {
    margin-right: 15px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 10px;
    width: 50px;
    overflow: hidden;
    border-radius: 0px;
  `};
`

const Logo = styled.div`
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  margin: 24px 32px 0 32px;
`

const NavLinkWrapper = styled.div`
  padding-top: 0px;
`

const SidebarFooter = styled.div`
  padding: 32px;
`

const SidebarContainer = styled.div`
  height: 100%;
`

const SidebarFooterTitle = styled.div`
  color: white;
  letter-spacing: 0.5em;
  text-transform: uppercase;
`

const SidebarDivider = styled.div`
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.16);
`

const SidebarSocialLinkWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`

const SidebarSocialLink = styled.a`
  align-items: center;
  justify-content: flex-start;
`

export default function Sidebar() {
  const { t } = useTranslation()
  const { isAuthed } = useWalletState()

  if (!isAuthed) return null

  return (
    <SidebarFrame>
      <SidebarContainer>
        <Logo>
          <img src={AdxLogo} alt="logo" width="180px" />
          <SidebarDivider />
        </Logo>
        <NavLinkWrapper>
          <StyledNavLink id={`lend-nav-link`} to={`/lend/list`}>
            <img src={MenuLend} alt="icon-lend" />
            {t('Lend')}
          </StyledNavLink>
          <Box>
            <StyledNavText>
              <img src={MenuExchange} alt="icon-lend" />
              Exchange
            </StyledNavText>
            <Box px="32px" mt="8px">
              <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                <img src={MenuArrows} alt="icon-swap" />
                Trade
              </StyledNavLink>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/liquidity'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/increase') ||
                  pathname.startsWith('/find')
                }
              >
                <img src={MenuLiquidity} alt="icon-liquidity" />
                Market Making
              </StyledNavLink>
              <StyledNavLink id={`farm-nav-link`} to={'/farm'}>
                <img src={MenuFarm} alt="icon-farm" />
                Incentives
              </StyledNavLink>
            </Box>
          </Box>
        </NavLinkWrapper>
      </SidebarContainer>

      <SidebarFooter>
        <SidebarFooterTitle>EXPLORE US</SidebarFooterTitle>
        <SidebarDivider />
        <SidebarSocialLinkWrapper>
          <SidebarSocialLink href="https://www.ambidex.fi/">
            <img src={UnionSocial} alt="icon-union" />
          </SidebarSocialLink>
          <SidebarSocialLink href="https://discord.com/invite/nMMbYYGZxg">
            <img src={DiscordSocial} alt="icon-dsicord" />
          </SidebarSocialLink>
          <SidebarSocialLink href="https://twitter.com/ambidexfi">
            <img src={TwitterSocial} alt="icon-twitter" />
          </SidebarSocialLink>
          <SidebarSocialLink href="https://docs.ambidex.fi/en_main/">
            <img src={GitBookSocial} alt="icon-gitbook" />
          </SidebarSocialLink>
        </SidebarSocialLinkWrapper>
      </SidebarFooter>
    </SidebarFrame>
  )
}
