import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk-core'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import Row, { RowBetween, RowFixed, RowFlat } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'

const ConfirmAddModalBottomWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`

const Summary = styled(AutoColumn)`
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border-radius: 12px;
  padding: 19px 14px;
  row-gap: 16px;
`

export function ConfirmAddModalBottom({
  noLiquidity,
  allowedSlippage,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  allowedSlippage: Percent
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  return (
    <ConfirmAddModalBottomWrapper>
      <Summary>
        <RowBetween>
          <Text fontSize={14} fontWeight={400}>
            {currencies[Field.CURRENCY_A]?.symbol} Deposited
          </Text>
          <RowFixed>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
            <Text fontSize={14} fontWeight={600}>
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <Text fontSize={14} fontWeight={400}>
            {currencies[Field.CURRENCY_B]?.symbol} Deposited
          </Text>
          <RowFixed>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
            <Text fontSize={14} fontWeight={600}>
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <Text fontSize={14} fontWeight={400}>
            Rates
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
              currencies[Field.CURRENCY_B]?.symbol
            }`}
          </Text>
        </RowBetween>
        <RowBetween style={{ justifyContent: 'flex-end' }}>
          <Text fontSize={14} fontWeight={600}>
            {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
              currencies[Field.CURRENCY_A]?.symbol
            }`}
          </Text>
        </RowBetween>
        <RowBetween>
          <Text fontSize={14} fontWeight={400}>
            Share of Pool
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%
          </Text>
        </RowBetween>
      </Summary>
      {!noLiquidity && (
        <Row justify="center">
          <TYPE.italic fontSize={14} textAlign="center" maxWidth={376}>
            {`Output is estimated. If the price changes by more than ${allowedSlippage.toSignificant(
              4
            )}% your transaction will revert.`}
          </TYPE.italic>
        </Row>
      )}
      <ButtonPrimary style={{ backgroundColor: '#6437E7' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </Text>
      </ButtonPrimary>
    </ConfirmAddModalBottomWrapper>
  )
}
