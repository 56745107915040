import axios from 'axios'
import { AMBIDEX_CHATBOT_API_URLS, CURRENT_CHAIN_ID } from 'constants/index'
import React, { createContext, useContext, useState } from 'react'

type ChatMessageType = {
  id: string
  type: 'question' | 'answer'
  text: string | null
}

type ChatMessagesState = {
  messages?: ChatMessageType[]
  askQuestion?: (question: string) => void
}

const ChatMessagesContext = createContext<ChatMessagesState>({ messages: [] })

export const useChatMessages = () => useContext(ChatMessagesContext)

export const ChatMessagesProvider = ({ children }: { children: any }) => {
  const [messages, setMessages] = useState<ChatMessageType[]>([])

  const askQuestion = (question: string) => {
    const timestamp = Date.now()
    const qId = `q-${timestamp}`
    const aId = `a-${timestamp}`
    setMessages([...messages, { id: qId, text: question, type: 'question' }, { id: aId, text: null, type: 'answer' }])
    axios
      .get(`${AMBIDEX_CHATBOT_API_URLS[CURRENT_CHAIN_ID]}/?question=${encodeURIComponent(question)}`)
      .then(({ data }) => {
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages]
          const index = newMessages.findIndex((msg) => msg.id === aId)
          if (index >= 0) {
            newMessages[index].text = data as string
          }
          return newMessages
        })
      })
      .catch((err: any) => {
        console.log(err, aId)
      })
  }

  return <ChatMessagesContext.Provider value={{ messages, askQuestion }}>{children}</ChatMessagesContext.Provider>
}
