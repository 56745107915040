import axios from 'axios'
import ReactGA from 'react-ga4'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_QUERY_PROXY_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Logs all failed proxy calls(farm, liquidity, swap)
axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const {
      request: { responseURL: url },
      status: statusCode,
      config: { method },
    } = error.response

    ReactGA.event({
      category: 'Proxy Error',
      action: `${method.toUpperCase()} ${statusCode}`,
      label: url,
    })
    return Promise.reject(error)
  }
)

export const queryProxyCall = async (
  contractAddress: string,
  functionName: string,
  functionArgs: any[]
): Promise<any[]> => {
  return await axiosInstance
    .get<string[]>('contract-call', {
      params: {
        contractAddress,
        functionName,
        functionArgs: JSON.stringify(functionArgs),
      },
    })
    .then(({ data }) => data)
}

export const pairProxyCall = async (
  contractAddress: string,
  functionName: string,
  functionArgs: any[]
): Promise<any> => {
  return await axiosInstance
    .get<any>('pair-call', {
      params: {
        contractAddress,
        functionName,
        functionArgs: JSON.stringify(functionArgs),
      },
    })
    .then(({ data }) => data)
}

export const tokenInfoProxyCall = async (tokenId: string): Promise<any> => {
  return await axiosInstance
    .get<any>('token-info-call', {
      params: {
        tokenId,
      },
    })
    .then(({ data }) => data)
}
