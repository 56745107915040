import { blade, hashpack } from 'connectors/index'
import React from 'react'
import { useWalletState } from 'state/wallet/hooks'

export default function useConnector() {
  const { name } = useWalletState()

  if (name === 'Hashpack') {
    return hashpack
  }

  if (name === 'Blade') {
    return blade
  }

  return undefined
}
