import { BladeSigner, HederaNetwork } from '@bladelabs/blade-web3.js'
import {
  LoanActionArgs,
  LoanActionMethod,
  LoanStakingActionArgs,
  LoanStakingActionMethod,
  MasterChefArgs,
  NetworkConnector,
  NetworkConnectorArguments,
} from './NetworkConnector'
import { BLADE_WALLET_LOCALSTORAGE_KEY, CURRENT_CHAIN_ID, WHBAR_ADDRESSES } from 'constants/index'
import { AmbidexChainId } from 'constants/networks'
import { TransactionResponse } from '@hashgraph/sdk'

export class Bladeconnect extends NetworkConnector {
  private bladeSigner: BladeSigner

  installedExtension = false

  private bladeSignerParams = {
    network: CURRENT_CHAIN_ID === AmbidexChainId.HEDERA_MAINNET ? HederaNetwork.Mainnet : HederaNetwork.Testnet,
    dAppCode: 'Ambidex',
  }

  constructor(networkConnectArgs: NetworkConnectorArguments) {
    super(networkConnectArgs)

    this.bladeSigner = new BladeSigner()

    this.bladeSigner.onAccountChanged(this.accountChangedHandler)

    this.initializeBladeWallet()
  }

  accountChangedHandler = () => {
    this.installedExtension = true
  }

  public async initializeBladeWallet() {
    const localData = localStorage.getItem(BLADE_WALLET_LOCALSTORAGE_KEY)
    if (localData) {
      await this.activate()
    }
  }

  public async getProviderClient() {
    return (this.bladeSigner as any)._activeWallet._provider._client
  }

  public async activate(): Promise<any> {
    let loggedId = ''

    try {
      await this.bladeSigner.createSession(this.bladeSignerParams)
      loggedId = this.bladeSigner.getAccountId().toString()
    } catch (e) {
      if (typeof e === 'function') {
        const { message } = e()
        console.error(message)
      } else if (typeof e === 'string') {
        console.error(e)
      } else if (e instanceof Error) {
        console.error(e.message)
      }
    } finally {
      if (!loggedId) {
        console.error('Cannot find connected account id in Blade wallet!')
      } else {
        if (!localStorage.getItem(BLADE_WALLET_LOCALSTORAGE_KEY)) {
          console.log('Blade Wallet has been connected!')
        }
        localStorage.setItem(BLADE_WALLET_LOCALSTORAGE_KEY, JSON.stringify({ bladeAccountId: loggedId }))

        return {
          provider: this.bladeSigner,
          chainId: CURRENT_CHAIN_ID,
          account: loggedId,
        }
      }
    }
  }

  public deactivate() {
    localStorage.removeItem(BLADE_WALLET_LOCALSTORAGE_KEY)
  }

  public async createPair(token1: string, account: string, token2?: string) {
    const tx = await super.getCreatePairTx(token1, account, token2)
    if (!tx) return

    const response = (await this.bladeSigner.call(tx)) as TransactionResponse
    if (!response) {
      throw new Error('createPair: response error')
    }

    this.deleteLpToken(token1, token2)

    return response.transactionId
  }

  public async addLiquidityHBAR(args: any, serialNumber: any) {
    const [tokenAddr, account] = args

    await this.createPair(tokenAddr, account)

    await new Promise((resolve) => setTimeout(resolve, 3000))

    const tx = super.getAddLiquidityHBARTx(args, serialNumber)
    const response = (await this.bladeSigner.call(tx)) as TransactionResponse
    if (!response) {
      throw new Error('addLiquidityHBAR: response error')
    }

    this.saveLocalPair(account, tokenAddr, WHBAR_ADDRESSES[CURRENT_CHAIN_ID])
    this.removeReserveCache()

    return response.transactionId.toString()
  }

  public async addLiquidity(args: any, serialNumber: any) {
    const [token1Addr, token2Addr, account] = args
    const tx = await super.getAddLiquidityTx(args, serialNumber)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('addLiquidity: error')
    }

    this.saveLocalPair(account, token1Addr, token2Addr)
    this.removeReserveCache()

    return response.transactionId.toString()
  }

  public async removeLiquidityHBAR(args: any, serialNumber: any): Promise<string> {
    const tx = super.getRemoveLiquidityHBARTx(args, serialNumber)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('removeLiquidityHBAR: error')
    }

    this.removeReserveCache()
    return response.transactionId.toString()
  }

  public async removeLiquidity(args: any, serialNumber: any): Promise<string> {
    const tx = super.getRemoveLiquidityTx(args, serialNumber)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('removeLiquidity: error')
    }

    this.removeReserveCache()
    return response.transactionId.toString()
  }

  public async swap(methodName: string, args: any, value: string, serialNumber: any): Promise<string> {
    const tx = super.getSwapTx(methodName, args, value, serialNumber)
    if (!tx) return ''

    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('swap: error')
    }

    this.removeReserveCache()
    return response.transactionId.toString()
  }

  public async associateToken(account: string | undefined, tokenAddr: string): Promise<string> {
    if (account) {
      const tx = super.getAssociateTokenTx(account, tokenAddr)
      const response = await this.bladeSigner.call(tx)
      if (!response) {
        throw new Error('associateToken: error')
      }

      return response.transactionId.toString()
    }

    return ''
  }

  public async masterChefAction(methodName: string, args: MasterChefArgs, gas: number): Promise<string> {
    const tx = super.getMasterChefActionTx(methodName, args, gas)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('masterChefAction: error')
    }

    return response.transactionId.toString()
  }

  public async loanAction(loanAddress: string, methodName: LoanActionMethod, args: LoanActionArgs): Promise<string> {
    const tx = super.getLoanActionTx(loanAddress, methodName, args)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('loanAction: error')
    }

    return response.transactionId.toString()
  }

  public async loanStakingAction(
    loanStakingAddress: string,
    methodName: LoanStakingActionMethod,
    args: LoanStakingActionArgs
  ): Promise<string> {
    const tx = super.getLoanStakingActionTx(loanStakingAddress, methodName, args)
    const response = await this.bladeSigner.call(tx)
    if (!response) {
      throw new Error('loanStakingAction: error')
    }

    return response.transactionId.toString()
  }
}
