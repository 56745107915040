import BN from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import { ConfirmationPendingContent, TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import Row, { AutoRow, RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import { BigNumber, ethers } from 'ethers'
import numeral from 'numeral'
import AppBody from 'pages/AppBody'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import { useWalletState } from 'state/wallet/hooks'
import { useLoanData, useLoans } from 'state/loans/hooks'
import styled, { ThemeContext } from 'styled-components'
import Bitskilogo from '../../assets/images/bitski.png'
import QuestionHelper from '../../components/QuestionHelper'
import { Input as NumericalInput } from '../../components/NumericalInput'
import { USDC, CURRENT_CHAIN_ID } from '../../constants'
import { Fraction, Percent, Rounding } from '@uniswap/sdk-core'
import Modal from './../../components/Modal/index'
import ReactGA from 'react-ga4'
import { LoanStatus, loanStatusNames, LoanInterestType } from '../../state/loans/types'
import CancelledIcon from '../../assets/images/cancelled.svg'
import CheckIcon from '../../assets/images/check-lend.svg'
import TopographicSvg from '../../assets/images/topographic.svg'
import ExclamationIcon from '../../assets/images/exclamation.svg'
import BgComingSoon from '../../assets/images/bg_coming_soon.png'
import atmImg from '../../assets/images/atm.png'
import last24HoursImg from '../../assets/images/24_hours.png'
import receiveDollarImg from '../../assets/images/receive_dollar.png'
import { BIG_TEN } from 'utils/bigNumber'
import { CustomLightSpinner } from '../../theme/components'
import { TYPE } from '../../theme'
import { Dots } from '../../components/swap/styleds'
import Circle from '../../assets/images/blue-loader.svg'
import { getTotalSupply } from 'utils/hederaMirror'
import { useAppDispatch } from 'state'
import { fetchLoanUserDataAsync } from 'state/loans/reducer'
import useCredentialTokens from 'hooks/useCredentialTokens'
import PageHeader from './header'
import LoanOverview from './overview'
import { Helmet } from 'react-helmet'
import LOANS from 'constants/loans'
import { AmbidexChainId } from 'constants/networks'
import { SuccessMessage, WarningMessage } from 'components/Message'
import { axiosInstance as queryProxy } from 'utils/queryProxy'
import LendComingSoon from './coming-soon'
import { getTokenId } from 'utils/hederaUtils'
import useConnector from 'hooks/useConnector'

const PageWrapper = styled(AppBody)`
  border-radius: 0px;
  max-width: 992px;
  width: 100%;
  padding: 30px;
  box-shadow: none;
  color: #27292c;
  margin-top: 16px;
`

const LogoWrapper = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 16px;
`

const LogoImg = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 50%;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const StatusBar = styled.div`
  margin-top: 16px;
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border-radius: 8px;
  width: 100%;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const GradientWrapper = styled.div`
  position: relative;
  padding: 1px;
  z-index: 1;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(92.37deg, #6437e7 0%, #d401c9 100%);
    z-index: -1;
    border-radius: 8px;
    overflow: hidden;
  }
  box-shadow: 8px 8px 60px rgba(0, 0, 0, 0.08);
`

const LendModal = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background: #fff;
`

const RefundModal = styled(LendModal)`
  margin-top: 16px;
`

const LendModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  border-radius: 0px 0px 8px 8px;
`

const Balance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const LendButton = styled.div<{
  disabled?: boolean
}>`
  padding: 18px 32px;
  background: #6437e7;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && '0.4'};
  font-weight: 700;
`

const Policy = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
`

const PercentRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 24px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`

const PercentButton = styled.div<{
  opacity?: number
}>`
  padding: 14px 16px;
  cursor: pointer;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

const InputRow = styled.div`
  padding: 18px 20px 24px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
`

const InputContainer = styled.div`
  margin: 0 auto;
  background: #f3effd;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 28px 19px;
`

const TermCheckBox = styled.input`
  cursor: pointer;
`

const ModalWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const ReclaimBox = styled.div`
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid rgba(255, 49, 49, 0.24);
  box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const Icon = styled.img`
  margin-right: 8px;
`

const CancelText = styled.div`
  display: flex;
  align-items: center;
  color: #ff3131;
  border-bottom: 1px dashed rgba(255, 49, 49, 0.4);
  padding: 28px 20px 20px;
`

const StakedUsdc = styled.div`
  margin: 0 auto;
`

const ReclaimButton = styled(LendButton)`
  margin-left: auto;
`

const MaturityBox = styled.div`
  background: #ffffff;
  box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
`

const MaturityText = styled.div`
  display: flex;
  align-items: center;
  padding: 28px 24px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  color: #6437e7;
  font-weight: 600;
`

const ReclaimableBalance = styled(RowBetween)`
  padding: 30px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const ReclaimableBalanceDetailsText = styled.div`
  padding: 24px 20px 16px;
`

const ReclaimableBalanceDetailsContent = styled.div`
  background: linear-gradient(93.52deg, rgba(249, 246, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 51.56%, #f9f6ff 100%);
`

const ReclaimableBalanceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
`

const ReclaimableBalancesTotal = styled.div`
  padding: 24px 20px 30px;
`

const InterestRow = styled.div`
  padding: 28px 20px;
  display: flex;
  align-items: center;
`

const WithdrawDepositRow = styled.div`
  background: #ffffff;
  box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;
`

const WithdrawButton = styled(LendButton)<{
  disabled: boolean
  hide: boolean
}>`
  margin-right: 16px;
  background: ${({ disabled }) => (disabled ? '#FAFAFA' : 'none')};
  border: ${({ disabled }) => (disabled ? '1px solid rgba(0, 0, 0, 0.08)' : '1px solid #6437e7')};
  color: ${({ disabled }) => (disabled ? '#27292C' : ' #6437e7')};
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`

const InterestModal = styled(LendModal)`
  position: relative;
  margin-top: 36px;
`

const PayoutSchedule = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 4px 8px;
  background: #e8e4f2;
  border-radius: 6px;
  width: fit-content;
  color: #6437e7;
`

export const InfoCard = styled.div`
  padding: 38px 76px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: 1;
  background: url(${TopographicSvg});
  background-repeat: no-repeat;
  background-position: right;
`

export default function LendPage({
  match: {
    params: { loanId, loanAddress },
  },
}: RouteComponentProps<{ loanId?: string; loanAddress?: string }>) {
  const loanConfig = LOANS[CURRENT_CHAIN_ID].find((l) =>
    loanId ? l.id === Number(loanId) : l.loanAddress === loanAddress
  )
  const loanContractAddress = loanAddress ?? (loanConfig?.loanAddress as string)
  const theme = useContext(ThemeContext)

  const { account, chainId } = useWalletState()
  const connector = useConnector()
  const [amount, setAmount] = useState('')
  const [usdcBalance, setUsdcBalance] = useState(BigNumber.from(0))
  const [termChecked, setTermChecked] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [loanTokenBalance, setLoanTokenBalance] = useState(BigNumber.from(0))
  const [actionType, setActionType] = useState('')
  const { isError, loans, userData } = useLoans()
  const [loan] = loans
  const [needAssociation, setNeedAssociation] = useState(false)
  const [totalSupply, setTotalSupply] = useState('')
  const dispatch = useAppDispatch()
  const credentialTokens = useCredentialTokens()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const convertToSignificant = (amount: BigNumber | string, decimalPlaces = 4) => {
    return new Fraction(amount.toString(), Math.pow(10, USDC.decimals)).toFixed(decimalPlaces)
  }

  const onPercent = (percent: number) => {
    setAmount(convertToSignificant(usdcBalance.mul(percent).div(100)))
  }

  const convertToMonths = (duration: any) => {
    return Math.floor(parseInt(duration?.toFixed(0), 10) / ((60 * 60 * 24 * 365) / 12))
  }

  const convertToWeeks = (duration: any) => {
    return Math.floor(parseInt(duration?.toFixed(0), 10) / (60 * 60 * 24 * 7))
  }

  const getNextInterestDate = () => {
    const elapsed = Date.now() - parseInt(loan?.loanTimeData.activeTimestamp?.toFixed(), 10) * 1000
    const payoutNum = Math.ceil(elapsed / (parseInt(loan?.loanInterestData.payoutSchedule?.toFixed(), 10) * 1000))
    const date = new Date(
      parseInt(loan?.loanTimeData.activeTimestamp?.toFixed(), 10) * 1000 +
        payoutNum * parseInt(loan?.loanInterestData.payoutSchedule?.toFixed(), 10) * 1000
    )

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const day = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()
    const monthName = months[monthIndex]

    return `${monthName} ${day}, ${year}`
  }

  const loanCredentialToken = useMemo(() => {
    if (credentialTokens && loan) {
      return credentialTokens.find((token) => token.metadata === loan.credentialType)
    }
    return null
  }, [credentialTokens, loan])

  const onLoanTx = async (loanAction: any, methodName: string, withAmount = false) => {
    if (!loanCredentialToken) return
    const { serial_number } = loanCredentialToken

    setModalOpen(true)
    setAttemptingTxn(true)
    try {
      const txId = await loanAction(serial_number)
      dispatch(fetchLoanUserDataAsync({ account, contractAddress: loanContractAddress }))
      setAttemptingTxn(false)
      setTxHash(txId)
      ReactGA.event({
        category: 'Lend',
        action: methodName,
        label: `${withAmount ? amount : ''} ${loan?.symbol}`,
      })
    } catch (error: any) {
      setModalOpen(false)
      setAttemptingTxn(false)
      const txId = error.message
      ReactGA.event({
        category: 'Error',
        action: `Error: Lend - ${methodName}`,
        label: `${loan?.symbol} - ${txId}`,
      })
      console.log('Error: ', error)
    }
  }

  const associateToken = async () => {
    setActionType('Associating')
    await connector?.associateToken(account, loan?.loanToken)
    setNeedAssociation(false)
    setActionType('')
  }

  const onLend = () => {
    if (
      termChecked &&
      account &&
      !BigNumber.from(parseFloat(amount === '' ? '0' : amount) * Math.pow(10, USDC.decimals)).eq(0)
    ) {
      onLoanTx(
        async (serialNumber: number) => {
          setActionType('Lending')
          const txId = await connector?.loanAction(loanContractAddress, 'purchaseLoan', {
            account,
            amount: BigNumber.from(parseFloat(amount === '' ? '0' : amount) * Math.pow(10, USDC.decimals)).toString(),
            serialNumber: BigNumber.from(serialNumber).toString(),
          })
          await fetchTotalSupply()
          return txId
        },
        'purchaseLoan',
        true
      )
    }
  }

  const onClaimInterest = () => {
    if (!userData?.pendingReward?.eq(0)) {
      onLoanTx(async (serialNumber: number) => {
        setActionType('Claiming interest')
        return await connector?.loanStakingAction(loan?.loanStaking, 'claimReward', {
          account,
          serialNumber: BigNumber.from(serialNumber).toString(),
        })
      }, 'claimReward')
    }
  }

  const onRefund = () => {
    if (account && !loanTokenBalance.eq(0)) {
      onLoanTx(async (serialNumber: number) => {
        setActionType('Refunding')
        const txId = await connector?.loanAction(loanContractAddress, 'refundLoan', {
          account,
          amount: loanTokenBalance.toString(),
          serialNumber: BigNumber.from(serialNumber).toString(),
        })
        setLoanTokenBalance(BigNumber.from(0))
        await fetchTotalSupply()
        return txId
      }, 'refundLoan')
    }
  }

  const onRedeem = () => {
    if (account && !loanTokenBalance.eq(0)) {
      onLoanTx(async (serialNumber: number) => {
        setActionType('Redeeming')
        return await connector?.loanAction(loanContractAddress, 'redeemAll', {
          account,
          serialNumber: BigNumber.from(serialNumber).toString(),
        })
      }, 'redeemAll')
    }
  }

  const onStake = () => {
    if (
      !loanTokenBalance.eq(0) &&
      !BigNumber.from(parseFloat(amount === '' ? '0' : amount) * Math.pow(10, USDC.decimals)).eq(0) &&
      parseFloat(convertToSignificant(loanTokenBalance)) >= parseFloat(amount.length ? amount : '0')
    ) {
      onLoanTx(
        async (serialNumber: number) => {
          setActionType('Depositing')
          return await connector?.loanStakingAction(loan?.loanStaking, 'stake', {
            account,
            amount: BigNumber.from(parseFloat(amount === '' ? '0' : amount) * Math.pow(10, USDC.decimals)).toString(),
            serialNumber: BigNumber.from(serialNumber).toString(),
          })
        },
        'stake',
        true
      )
    }
  }

  const onUnstake = () => {
    if (
      !userData?.stakedAmount?.eq(0) &&
      parseFloat(amount === '' ? '0' : amount) !== 0 &&
      (!userData?.stakedAmount ||
        (userData?.stakedAmount &&
          parseFloat(userData?.stakedAmount?.toFixed()) >= parseFloat(amount.length ? amount : '0')))
    ) {
      onLoanTx(
        async (serialNumber: number) => {
          setActionType('Withdrawing')
          return await connector?.loanStakingAction(loan?.loanStaking, 'unstake', {
            account,
            amount: BigNumber.from(parseFloat(amount === '' ? '0' : amount) * Math.pow(10, USDC.decimals)).toString(),
            serialNumber: BigNumber.from(serialNumber).toString(),
          })
        },
        'unstake',
        true
      )
    }
  }

  const getModalContent = () => {
    if (attemptingTxn) {
      return <ConfirmationPendingContent onDismiss={toggleModal} pendingText={`${actionType}....`} />
    }
    if (txHash) {
      return <TransactionSubmittedContent chainId={chainId} hash={txHash} onDismiss={toggleModal} />
    }
    return <></>
  }

  useEffect(() => {
    if (account && connector) {
      const usdcTokenId = getTokenId(USDC.address)
      connector?.getTokenBalances(account).then(({ tokens }) => {
        if (tokens && usdcTokenId && tokens.get(usdcTokenId)) {
          const numerator = BigNumber.from((tokens.get(usdcTokenId) ?? 0).toString())
          setUsdcBalance(numerator)
        }
      })
    }
  }, [account, connector, txHash, userData])

  useEffect(() => {
    if (account && loan?.loanToken && connector) {
      const loanTokenId = getTokenId(loan?.loanToken)
      connector?.getTokenBalances(account).then(({ tokens }) => {
        if (tokens && loanTokenId && tokens.get(loanTokenId)) {
          const numerator = BigNumber.from((tokens.get(loanTokenId) ?? 0).toString())
          setLoanTokenBalance(numerator)
        }
        if (tokens && loanTokenId && !tokens.get(loanTokenId)) {
          setNeedAssociation(true)
        }
      })
    }
  }, [account, connector, loan?.loanToken, txHash, userData])

  const fetchTotalSupply = async () => {
    const loanTokenId = getTokenId(loan?.loanToken)
    if (loanTokenId) {
      getTotalSupply(loanTokenId).then((supply) => {
        setTotalSupply(supply)
      })
    }
  }

  useEffect(() => {
    if (loan?.loanToken) {
      fetchTotalSupply()
    }
  }, [loan?.loanToken])

  useLoanData(loanContractAddress!)

  const maturityDate = useMemo(() => {
    if (!loan?.loanTimeData) return ''
    const timestamp = 1000 * (Number(loan?.loanTimeData.activeTimestamp) + Number(loan?.loanTimeData.duration))
    return dayjs(timestamp).format('D MMMM, YYYY')
  }, [loan])

  const totalReclaimableBalance = useMemo(() => {
    let total = loanTokenBalance
    if (userData) {
      total = total.add(ethers.utils.parseUnits(userData.stakedAmount.toJSON(), 6))
    }
    // redeemablePercentage is expressed in 6 digits, so we should divide by 10 ^ 8
    const reclaimableBalance = total
      .mul(loan?.redeemablePercentage ? BigNumber.from(loan?.redeemablePercentage?.toJSON()) : BigNumber.from('0'))
      .div(BigNumber.from(Math.pow(10, 8)))
    if (userData) {
      return new BN(
        ethers.utils.formatUnits(reclaimableBalance.add(ethers.utils.parseUnits(userData.pendingReward.toJSON(), 6)), 6)
      ).toFixed(2, BN.ROUND_DOWN)
    } else {
      return new BN(ethers.utils.formatUnits(reclaimableBalance, 6)).toFixed(2, BN.ROUND_DOWN)
    }
  }, [loanTokenBalance, userData, loan])

  const isMainnet = process.env.REACT_APP_CHAIN_ID == AmbidexChainId.HEDERA_MAINNET.toString()

  if (isMainnet && (loanAddress || !loanConfig)) {
    return <Redirect to="/lend/list" />
  }

  if (loanConfig?.status === 'Coming Soon') {
    return <LendComingSoon loan={loanConfig} />
  }

  return (
    <>
      <PageHeader />
      {loan && userData && credentialTokens ? (
        <>
          <Helmet>
            <title>{loan?.name ?? 'Loan'} | Ambidex</title>
          </Helmet>
          <PageWrapper>
            <RowBetween marginBottom="24px">
              <Title>
                <LogoWrapper>
                  <LogoImg src={loanConfig?.logoImg} alt="logo" />
                </LogoWrapper>
                <div>
                  <Text fontWeight={600} fontSize={24}>
                    {loan?.name}
                  </Text>
                </div>
              </Title>
              {loanCredentialToken ? (
                <SuccessMessage text="Your wallet is authorized to access this loan" />
              ) : (
                <WarningMessage text="Your wallet is not authorized to access this loan" />
              )}
            </RowBetween>
            <StatusBar>
              <RowBetween>
                <Column>
                  <Text fontSize={14} fontWeight={300}>
                    Ticker
                  </Text>
                  <Text fontSize={16} fontWeight={600} marginTop="4px">
                    {loan?.symbol}
                  </Text>
                </Column>
                <Column>
                  <Box>
                    <Flex fontSize={14} fontWeight={300}>
                      <div>APR</div>
                      <QuestionHelper text="This loan token will pay out rewards bi-annually, on the 30th of June and the 31st of December" />
                    </Flex>
                  </Box>
                  <Text fontSize={16} fontWeight={600} marginTop="4px">
                    {loan?.loanInterestData?.interestType !== undefined && (
                      <>
                        {loan?.loanInterestData.interestType === LoanInterestType.Fixed
                          ? `${loan?.loanInterestData.interestRate?.div(1e6).toFixed(2)}%`
                          : 'Variable'}
                      </>
                    )}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize={14} fontWeight={300}>
                    Duration
                  </Text>
                  <Text fontSize={16} fontWeight={600} marginTop="4px">
                    {loan?.loanTimeData?.duration && `${convertToMonths(loan?.loanTimeData.duration)} months`}
                  </Text>
                </Column>
                {loan?.loanState === LoanStatus.SaleStarted && (
                  <Column>
                    <Text fontSize={14} fontWeight={300}>
                      Issuing deadline
                    </Text>
                    <Text fontSize={16} fontWeight={600} marginTop="4px">
                      30 April, 2023
                    </Text>
                  </Column>
                )}
                {loan?.loanState === LoanStatus.Active && (
                  <Column>
                    <Text fontSize={14} fontWeight={300}>
                      Maturity date
                    </Text>
                    <Text fontSize={16} fontWeight={600} marginTop="4px">
                      {maturityDate}
                    </Text>
                  </Column>
                )}
                <Column>
                  <Text fontSize={14} fontWeight={300}>
                    Status
                  </Text>
                  <Text fontSize={16} fontWeight={600} color="#2192ff" marginTop="4px">
                    {loanStatusNames[loan?.loanState]
                      ? loan?.loanState === LoanStatus.Redeemable && loan?.loanTimeData.defaultedTimestamp.gt(0)
                        ? 'Redeemable'
                        : loanStatusNames[loan?.loanState]
                      : ''}
                  </Text>
                </Column>
              </RowBetween>
            </StatusBar>
            {LoanStatus.CancelledBeforeActive === loan?.loanState && !loanTokenBalance.eq(0) && (
              <ReclaimBox>
                <CancelText>
                  <Icon src={CancelledIcon} />
                  <Text fontSize={14}>
                    The loan was canceled, you can reclaim {convertToSignificant(loanTokenBalance)} USDC in exchange for
                    the {loan?.symbol} tokens in your wallet
                  </Text>
                </CancelText>
                <Flex padding="26px 20px" alignItems="center">
                  <Box>
                    <Text fontWeight={300}>Reclaimable balance</Text>
                    <Text marginTop="4px" fontWeight={800} fontSize={18}>
                      {convertToSignificant(loanTokenBalance, 0)} USDC
                    </Text>
                  </Box>
                  <ReclaimButton disabled={!account || loanTokenBalance.eq(0)} onClick={onRefund}>
                    Reclaim USDC
                  </ReclaimButton>
                </Flex>
              </ReclaimBox>
            )}
            {LoanStatus.SaleStarted === loan?.loanState && (
              <AutoRow marginTop="24px" marginBottom="24px">
                <RowBetween gap="40px">
                  <InfoCard>
                    <Text textAlign={'center'} opacity={0.72} fontWeight={300} fontSize={14}>
                      Amount raised to date
                    </Text>
                    <Text textAlign={'center'} fontSize={16} fontWeight={800} marginTop="8px">
                      {numeral(convertToSignificant(totalSupply, 0)).format('0,0')} USDC
                    </Text>
                  </InfoCard>
                  <InfoCard>
                    <Text textAlign={'center'} opacity={0.72} fontWeight={300} fontSize={14}>
                      Minimum USDC target
                    </Text>
                    <Text textAlign={'center'} fontSize={16} fontWeight={800} marginTop="8px">
                      {numeral(1000000).format('0,0')} USDC
                    </Text>
                  </InfoCard>
                </RowBetween>
              </AutoRow>
            )}
            {LoanStatus.SaleStarted === loan?.loanState && (
              <>
                <GradientWrapper>
                  <Box padding="4px" backgroundColor="#fff" style={{ borderRadius: '8px' }}>
                    <LendModal>
                      <Text padding={'20px 16px 20px 20px'} fontWeight={400}>
                        Please enter the amount of USDC to lend. You will receive an equivalent number of {loan?.symbol}{' '}
                        tokens. To learn more about the lending process, please{' '}
                        <a href="https://docs.ambidex.fi/en_main/">review our documentation</a>.
                      </Text>
                      <Box marginX="16px">
                        <GradientWrapper>
                          <InputContainer>
                            <NumericalInput
                              className="token-amount-input"
                              value={amount}
                              onUserInput={(val) => {
                                setAmount(val)
                              }}
                              style={{ color: '#6c6c6c', width: '100%', fontSize: '32px' }}
                              autoFocus={true}
                              align="center"
                              placeholder="0.00 USDC"
                            />
                          </InputContainer>
                          <PercentRow>
                            <PercentButton onClick={() => onPercent(25)}>25%</PercentButton>
                            <PercentButton onClick={() => onPercent(50)}>50%</PercentButton>
                            <PercentButton onClick={() => onPercent(75)}>75%</PercentButton>
                            <PercentButton onClick={() => onPercent(100)} opacity={1}>
                              <Text color="#6437E7">MAX</Text>
                            </PercentButton>
                          </PercentRow>
                        </GradientWrapper>
                      </Box>
                      <Policy>
                        <TermCheckBox type="checkbox" onClick={() => setTermChecked(!termChecked)} />
                        <Text fontWeight={400} marginLeft="12px">
                          I&apos;m aware of Ambidex risk policy by lending USDC. I have read the{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.ambidex.fi/en_main/legal/terms-and-conditions"
                          >
                            terms and conditions
                          </a>
                          &nbsp;carefully.
                        </Text>
                      </Policy>
                      <LendModalFooter>
                        <Balance>
                          <Text fontWeight={300}>Your available balance</Text>
                          <Text fontWeight={800} fontSize="20px">
                            {convertToSignificant(usdcBalance)} USDC
                          </Text>
                        </Balance>
                        {needAssociation ? (
                          <LendButton disabled={!termChecked || !account} onClick={associateToken}>
                            Associate {loan?.symbol}
                          </LendButton>
                        ) : (
                          <LendButton disabled={!termChecked || !account} onClick={onLend}>
                            Lend USDC
                          </LendButton>
                        )}
                      </LendModalFooter>
                    </LendModal>
                  </Box>
                </GradientWrapper>
                {loanTokenBalance.gt(0) && (
                  <RefundModal>
                    <LendModalFooter>
                      <Balance>
                        <Text fontWeight={300}>Loan tokens in your wallet</Text>
                        <Text fontWeight={800} fontSize="20px">
                          {convertToSignificant(loanTokenBalance)} {loan.symbol}
                        </Text>
                      </Balance>
                      <ReclaimButton disabled={!account || loanTokenBalance.eq(0)} onClick={onRefund}>
                        Reclaim USDC
                      </ReclaimButton>
                    </LendModalFooter>
                  </RefundModal>
                )}
              </>
            )}
            {LoanStatus.Redeemable === loan?.loanState && loanTokenBalance.gt(0) && (
              <MaturityBox>
                <MaturityText>
                  <Icon src={CheckIcon} />
                  <Text fontSize={16}>
                    Loan has reached its maturity date. You can now get back{' '}
                    {loan?.redeemablePercentage?.isLessThan(0)
                      ? '_'
                      : new Percent(loan?.redeemablePercentage.toString(), Math.pow(10, 8)).toSignificant(2)}
                    % of your original USDC principal.
                  </Text>
                </MaturityText>
                <ReclaimableBalance align="center">
                  <div>
                    <Text fontWeight={300}>Total reclaimable balance</Text>
                    <Text fontWeight={800} fontSize={18} marginTop="4px">
                      {totalReclaimableBalance} USDC
                    </Text>
                  </div>
                  <ReclaimButton disabled={!account || loanTokenBalance.eq(0)} onClick={onRedeem}>
                    Reclaim USDC
                  </ReclaimButton>
                </ReclaimableBalance>
                <ReclaimableBalanceDetailsText>
                  <Text fontWeight={700} color="#000">
                    Reclaimable balance details
                  </Text>
                </ReclaimableBalanceDetailsText>
                <ReclaimableBalanceDetailsContent>
                  <ReclaimableBalanceDetails>
                    <RowBetween>
                      <Text>{loan?.symbol} (Deposited)</Text>
                      <Text>
                        {userData?.stakedAmount
                          ? new Fraction(
                              ethers.utils
                                .parseUnits(userData.stakedAmount.toJSON(), 6)
                                .mul(loan?.redeemablePercentage?.toFixed())
                                .toString(),
                              Math.pow(10, USDC.decimals + 8)
                            ).toFixed(2, undefined, Rounding.ROUND_DOWN)
                          : '0.00'}{' '}
                        USDC
                      </Text>
                    </RowBetween>
                    <RowBetween>
                      <Text>{loan?.symbol} (In wallet)</Text>
                      <Text>
                        {new Fraction(
                          loanTokenBalance.mul(loan?.redeemablePercentage?.toFixed()).toString(),
                          Math.pow(10, USDC.decimals + 8)
                        ).toFixed(2, undefined, Rounding.ROUND_DOWN)}{' '}
                        USDC
                      </Text>
                    </RowBetween>
                    <RowBetween>
                      <Text>Claimable interest</Text>
                      <Text>
                        {userData?.pendingReward ? userData?.pendingReward.toFixed(2, BN.ROUND_DOWN) : '0.00'} USDC
                      </Text>
                    </RowBetween>
                  </ReclaimableBalanceDetails>
                  <ReclaimableBalancesTotal>
                    <RowBetween>
                      <Text>Total</Text>
                      <Text fontWeight={700}>{totalReclaimableBalance} USDC</Text>
                    </RowBetween>
                  </ReclaimableBalancesTotal>
                </ReclaimableBalanceDetailsContent>
              </MaturityBox>
            )}
            {(loan?.loanState === LoanStatus.Active ||
              loan?.loanState === LoanStatus.SaleEnded ||
              loan?.loanState === LoanStatus.Defaulted) &&
              (userData?.pendingReward?.isGreaterThan(0) ||
                userData?.stakedAmount.isGreaterThan(0) ||
                loanTokenBalance.gt(0)) && (
                <>
                  {(userData?.stakedAmount.isGreaterThan(0) || loanTokenBalance.gt(0)) && (
                    <WithdrawDepositRow>
                      <InputRow>
                        <GradientWrapper>
                          <InputContainer>
                            <NumericalInput
                              className="token-amount-input"
                              value={amount}
                              onUserInput={(val) => {
                                setAmount(val)
                              }}
                              style={{ color: '#6c6c6c', width: '100%', fontSize: '32px' }}
                              autoFocus={true}
                              align="center"
                              placeholder={`0.00 ${loan?.symbol}`}
                            />
                          </InputContainer>
                        </GradientWrapper>
                        {(userData?.stakedAmount.isGreaterThan(0) || loanTokenBalance.gt(0)) && (
                          <Text color={'#27292C'} paddingTop={'24px'} fontStyle="italic">
                            Ensure your loan tokens are staked before each interest payment date in order to receive
                            your payment.
                          </Text>
                        )}
                      </InputRow>
                      <AutoRow align="center" justify="space-between" padding="28px 20px">
                        <AutoColumn>
                          <Text fontWeight={300}>Tokens in your wallet</Text>
                          <Text fontWeight={800} fontSize={18} marginTop="4px">
                            {convertToSignificant(loanTokenBalance)} {loan?.symbol}
                          </Text>
                        </AutoColumn>
                        <AutoColumn>
                          <Text fontWeight={300}>Deposited {loan?.symbol} tokens</Text>
                          <Text fontWeight={800} fontSize={18} marginTop="4px">
                            {userData?.stakedAmount ? `${userData?.stakedAmount?.toFixed()} ${loan?.symbol}` : 'Error'}
                          </Text>
                        </AutoColumn>
                        <AutoColumn>
                          <AutoRow>
                            <WithdrawButton
                              disabled={
                                !account ||
                                userData?.stakedAmount?.eq(0) ||
                                parseFloat(amount.length ? amount : '0') === 0 ||
                                (userData?.stakedAmount &&
                                  parseFloat(userData?.stakedAmount?.toFixed()) <
                                    parseFloat(amount.length ? amount : '0'))
                              }
                              hide={!account || userData?.stakedAmount?.eq(0)}
                              onClick={onUnstake}
                            >
                              Withdraw
                            </WithdrawButton>
                            {(loan?.loanState === LoanStatus.Active || loan?.loanState === LoanStatus.SaleEnded) && (
                              <LendButton
                                disabled={
                                  !account ||
                                  loanTokenBalance.eq(0) ||
                                  parseFloat(amount.length ? amount : '0') === 0 ||
                                  parseFloat(convertToSignificant(loanTokenBalance)) <
                                    parseFloat(amount.length ? amount : '0')
                                }
                                onClick={onStake}
                              >
                                Deposit
                              </LendButton>
                            )}
                          </AutoRow>
                        </AutoColumn>
                      </AutoRow>
                    </WithdrawDepositRow>
                  )}
                  {(userData?.pendingReward?.isGreaterThan(0) || userData?.stakedAmount.isGreaterThan(0)) &&
                    loan?.loanTimeData?.activeTimestamp?.gt(0) && (
                      <InterestModal>
                        <PayoutSchedule>
                          <Text fontWeight={600}>
                            Interest payments made every {convertToWeeks(loan?.loanInterestData.payoutSchedule)} weeks
                          </Text>
                        </PayoutSchedule>
                        <InterestRow>
                          <div>
                            <AutoRow marginBottom="4px">
                              <Text fontWeight={300} marginRight="8px">
                                Next interest payment date
                              </Text>
                              <img src={ExclamationIcon} style={{ cursor: 'pointer' }} />
                            </AutoRow>
                            <Text fontSize={18} fontWeight={800}>
                              {getNextInterestDate()}
                            </Text>
                          </div>
                          <StakedUsdc>
                            <Text fontWeight={300} marginBottom="4px">
                              Available to claim
                            </Text>
                            <Text fontWeight={800} fontSize="18px">
                              {userData?.pendingReward ? `${userData?.pendingReward?.toFixed()} USDC` : 'Error'}{' '}
                            </Text>
                          </StakedUsdc>
                          <LendButton disabled={userData?.pendingReward?.eq(0)} onClick={onClaimInterest}>
                            Claim Interest
                          </LendButton>
                        </InterestRow>
                      </InterestModal>
                    )}
                </>
              )}
            <Modal isOpen={modalOpen} onDismiss={toggleModal} minHeight={false} maxHeight={90}>
              <ModalWrapper>{getModalContent()}</ModalWrapper>
            </Modal>
          </PageWrapper>
          <LoanOverview loanContractAddress={loanContractAddress} />
        </>
      ) : (
        <AutoRow marginTop={'35vh'}>
          <TYPE.body color={theme.text3} fontSize={24} textAlign="center" width="100%">
            {isError ? (
              <>An error occured, please try again.</>
            ) : (
              <>
                <CustomLightSpinner src={Circle} alt="loader" size={'16px'} style={{ marginRight: '10px' }} />
                <Dots>Loading</Dots>
              </>
            )}
          </TYPE.body>
        </AutoRow>
      )}
    </>
  )
}
