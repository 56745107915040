import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { AppState, useAppDispatch } from 'state'
import { useWalletState } from 'state/wallet/hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { reset } from './actions'
import { fetchLoanDataAsync, fetchLoanStakingAddress, fetchLoanUserDataAsync } from './reducer'

export const useLoanData = (loanContractAddress: string) => {
  const dispatch = useAppDispatch()
  const { account } = useWalletState()

  useSlowRefreshEffect(() => {
    if (!loanContractAddress) return
    dispatch(fetchLoanDataAsync(loanContractAddress))
    if (account) {
      dispatch(
        fetchLoanUserDataAsync({
          account,
          contractAddress: loanContractAddress,
        })
      )
      dispatch(fetchLoanStakingAddress(loanContractAddress))
    }
  }, [dispatch, account])
}

export const useLoans = () => {
  const loans = useSelector((state: AppState) => state.loans)
  return {
    isError: loans.isError,
    loans: loans.data,
    userData: loans.user[0],
  }
}

export const useResetLoans = () => {
  const dispatch = useAppDispatch()

  return useEffect(() => {
    dispatch(reset())
  }, [dispatch])
}
