import React from 'react'
import styled from 'styled-components'
import Header from './index'

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: flex-end;
  padding: 18px 50px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 18px 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 18px 30px;
  `};
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default function PageHeader() {
  return (
    <HeaderWrapper>
      <Header />
    </HeaderWrapper>
  )
}
