import { ContractExecuteTransaction, TokenAssociateTransaction } from '@hashgraph/sdk'
import { HashConnect, HashConnectTypes, MessageTypes } from 'hashconnect'
import { HashConnectConnectionState } from 'hashconnect/dist/types'

import {
  LoanActionArgs,
  LoanActionMethod,
  LoanStakingActionArgs,
  LoanStakingActionMethod,
  MasterChefArgs,
  NetworkConnector,
  NetworkConnectorArguments,
} from './NetworkConnector'
import { CURRENT_CHAIN_ID, WHBAR_ADDRESSES, HASHPACK_LOCALSTORAGE_KEY } from 'constants/index'

import { AmbidexChainId } from '../constants/networks'
import { makeBytes } from 'utils/hederaSign'

const NETWORK =
  process.env.REACT_APP_CHAIN_ID === AmbidexChainId.HEDERA_TESTNET.toString()
    ? 'testnet'
    : process.env.REACT_APP_CHAIN_ID === AmbidexChainId.HEDERA_MAINNET.toString()
    ? 'mainnet'
    : undefined

type HashConnectState = {
  availableExtension: HashConnectTypes.WalletMetadata
  state: HashConnectConnectionState
  topic: string
  pairingString: string
  pairingData: HashConnectTypes.SavedPairingData | null
}

export class HashConnector extends NetworkConnector {
  private hashConnect: any

  installedExtension = false

  IsIframeParent = false

  appConfig: HashConnectTypes.AppMetadata = {
    name: 'Ambidex',
    description: 'A Hedera DeFi App',
    icon: 'https://www.ambidex.fi/img/icons/adx_logo.png',
  }

  hashConnectState: Partial<HashConnectState> = {}

  constructor(networkConnectArgs: NetworkConnectorArguments) {
    super(networkConnectArgs)
    if (!this.hashConnect) {
      this.hashConnect = new HashConnect(true)
    }

    this.hashConnect.foundExtensionEvent.on(this.foundExtensionEventHandler)
    this.hashConnect.foundIframeEvent.on(this.foundIframeEventHandler)

    this.initializeHashConnect()
  }

  foundExtensionEventHandler = (data: any) => {
    this.hashConnectState = {
      ...this.hashConnectState,
      availableExtension: data,
    }
    this.installedExtension = true
  }

  foundIframeEventHandler = (data: any) => {
    this.hashConnectState = {
      ...this.hashConnectState,
      pairingData: {
        ...this.hashConnectState.pairingData,
        ...data,
      },
    }
    this.IsIframeParent = true
  }

  public async initializeHashConnect() {
    const hashConnectInitData = await this.hashConnect.init(this.appConfig, NETWORK, false)

    if (hashConnectInitData.savedPairings.length > 0) {
      this.hashConnectState = {
        ...this.hashConnectState,
        topic: hashConnectInitData.topic,
        pairingString: hashConnectInitData.pairingString,
        pairingData: hashConnectInitData.savedPairings[0],
      }
    } else {
      this.hashConnectState = {
        ...this.hashConnectState,
        topic: hashConnectInitData.topic,
        pairingString: hashConnectInitData.pairingString,
      }
    }
  }

  public async activate(): Promise<any> {
    try {
      if (
        typeof this.hashConnect.hcData.pairingString === 'undefined' ||
        this.hashConnect.hcData.pairingString === ''
      ) {
        throw new Error('No pairing key generated! Initialize HashConnect first!')
      }

      if (!this.hashConnectState.availableExtension || !this.hashConnect) {
        throw new Error('Hashpack wallet is not installed!')
      }

      this.hashConnect.connectToLocalWallet()

      return new Promise((resolve, reject) => {
        this.hashConnect.pairingEvent.on(async (data: any) => {
          this.hashConnectState = {
            ...this.hashConnectState,
            pairingData: {
              ...this.hashConnectState.pairingData,
              ...data,
            },
          }
          const [account] = data.accountIds
          const provider = await this.hashConnect.getProvider(NETWORK, this.hashConnectState.topic, account)

          resolve({
            provider,
            chainId: CURRENT_CHAIN_ID,
            account,
          })
        })
        // setTimeout(() => {
        //   reject()
        // }, 10000)
      })
    } catch (e) {
      if (typeof e === 'string') {
        console.error(e)
      } else if (e instanceof Error) {
        console.error(e.message)
      }
    }
  }

  public async getProvider() {
    const provider = await this.hashConnect.getProvider(
      NETWORK,
      this.hashConnectState.topic,
      this.hashConnectState.pairingData?.accountIds[0]
    )

    return provider
  }

  public async getProviderClient() {
    const provider = await this.hashConnect.getProvider(
      NETWORK,
      this.hashConnectState.topic,
      this.hashConnectState.pairingData?.accountIds[0]
    )

    return provider.client
  }

  async sendTransaction(
    tx: ContractExecuteTransaction | TokenAssociateTransaction,
    account: string,
    return_trans = false
  ) {
    const signedTx: Uint8Array = makeBytes(tx, account)
    if (!signedTx) {
      throw new Error('Transaction not signed')
    }

    const transaction: MessageTypes.Transaction = {
      topic: this.hashConnectState.topic!,
      byteArray: signedTx,
      metadata: {
        accountToSign: account,
        returnTransaction: return_trans,
      },
    }

    return await this.hashConnect.sendTransaction(this.hashConnectState.topic, transaction)
  }

  async requestAccountInfo() {
    const request: MessageTypes.AdditionalAccountRequest = {
      topic: this.hashConnectState.topic!,
      network: NETWORK!,
      multiAccount: true,
    }

    await this.hashConnect.requestAdditionalAccounts(this.hashConnectState.topic, request)
  }

  clearPairings() {
    localStorage.removeItem(HASHPACK_LOCALSTORAGE_KEY)
  }

  public async createPair(token1: string, account: string, token2?: string) {
    const tx = await super.getCreatePairTx(token1, account, token2)
    if (!tx) return

    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    this.deleteLpToken(token1, token2)

    return response.response.transactionId
  }

  public async addLiquidityHBAR(args: any, serialNumber: any) {
    const tokenAddr = args[0]
    const account = args[4]

    await this.createPair(tokenAddr, account)

    await new Promise((resolve) => setTimeout(resolve, 3000))

    const tx = super.getAddLiquidityHBARTx(args, serialNumber)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    this.saveLocalPair(account, tokenAddr, WHBAR_ADDRESSES[CURRENT_CHAIN_ID])
    this.removeReserveCache()
    return response.response.transactionId
  }

  public async addLiquidity(args: any, serialNumber: any) {
    const [token1Addr, token2Addr] = args
    const account = args[6]
    const tx = await super.getAddLiquidityTx(args, serialNumber)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    this.saveLocalPair(account, token1Addr, token2Addr)
    this.removeReserveCache()
    return response.response.transactionId
  }

  public async removeLiquidityHBAR(args: any, serialNumber: any) {
    const account = args[4]
    const tx = super.getRemoveLiquidityHBARTx(args, serialNumber)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    this.removeReserveCache()
    return response.response.transactionId
  }

  public async removeLiquidity(args: any, serialNumber: any) {
    const account = args[5]
    const tx = super.getRemoveLiquidityTx(args, serialNumber)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }
    this.removeReserveCache()
    return response.response.transactionId
  }

  public async swap(methodName: string, args: any, value: string, serialNumber: any) {
    const account =
      methodName === 'swapExactHBARForTokensSupportingFeeOnTransferTokens' ||
      methodName === 'swapExactHBARForTokens' ||
      methodName === 'swapHBARForExactTokens'
        ? args[2]
        : args[3]
    const tx = super.getSwapTx(methodName, args, value, serialNumber)
    if (!tx) return

    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }
    this.removeReserveCache()
    return response.response.transactionId
  }

  public async associateToken(account: string | undefined, tokenAddr: string) {
    if (account) {
      const tx = super.getAssociateTokenTx(account, tokenAddr)
      const response = await this.sendTransaction(tx, account)
      if (!response.success) {
        throw new Error(response.error.transactionId)
      }

      return response.response.transactionId
    }
  }

  public async masterChefAction(methodName: string, args: MasterChefArgs, gas: number) {
    const { account } = args
    const tx = super.getMasterChefActionTx(methodName, args, gas)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    return response.response.transactionId
  }

  /**
   * Perform on-chain action on loan contract
   * @param {string} loanAddress Solidity address for the Loan
   * @param {LoanActionMethod} methodName - Loan action method name
   * @param {LoanActionArgs} args - Loan action argument
   */
  public async loanAction(loanAddress: string, methodName: LoanActionMethod, args: LoanActionArgs) {
    const { account } = args
    const tx = super.getLoanActionTx(loanAddress, methodName, args)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    return response.response.transactionId
  }

  /**
   * Perform on-chain action on loan staking contract
   * @param {string} loanStakingAddress Solidity address for the LoanStaking
   * @param {LoanStakingActionMethod} methodName - LoanStaking action method name
   * @param {LoanStakingActionsArgs} args - LoanStaking action argument
   */
  public async loanStakingAction(
    loanStakingAddress: string,
    methodName: LoanStakingActionMethod,
    args: LoanStakingActionArgs
  ) {
    const { account } = args
    const tx = super.getLoanStakingActionTx(loanStakingAddress, methodName, args)
    const response = await this.sendTransaction(tx, account)
    if (!response.success) {
      throw new Error(response.error.transactionId)
    }

    return response.response.transactionId
  }
}
