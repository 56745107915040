import { Percent, TokenAmount, Rounding } from '@uniswap/sdk-core'
import { JSBI, Pair } from '@ambidex/sdk'
import { darken } from 'polished'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { Button as RebassButton } from 'rebass/styled-components'
import { usePairTotalSupply, useTotalSupply } from '../../hooks/useTotalSupply'

import { usePairTokenBalance, useTokenBalance, useWalletState } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary } from '../Button'

import Card, { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
import DoubleCurrencyLogo from '../DoubleLogo'
import Row, { RowBetween, RowFixed, AutoRow } from '../Row'
import { Dots } from '../swap/styleds'
import { BIG_INT_ZERO } from '../../constants'
import StarIcon from '../../assets/images/star_icon.svg'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const AddButton = styled(RebassButton)`
  padding: 10px 32px;
  width: 96px;
  background-color: #6437e7;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  &:hover {
    transform: scale(0.95);
  }

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`

const RemoveButton = styled(RebassButton)`
  padding: 10px 0px;
  width: 96px;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  &:hover {
    transform: scale(0.99);
  }

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`
interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useWalletState()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <AutoColumn gap="12px">
          <FixedHeightRow>
            <RowFixed>
              <Text fontWeight={500} fontSize={16}>
                Your position
              </Text>
            </RowFixed>
          </FixedHeightRow>
          <FixedHeightRow onClick={() => setShowMore(!showMore)}>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
              <Text fontWeight={500} fontSize={20}>
                {currency0.symbol}/{currency1.symbol}
              </Text>
            </RowFixed>
            <RowFixed>
              <Text fontWeight={500} fontSize={20}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </RowFixed>
          </FixedHeightRow>
          <AutoColumn gap="4px">
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your pool share:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                {currency0.symbol}:
              </Text>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                {currency1.symbol}:
              </Text>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
          </AutoColumn>
        </AutoColumn>
      ) : (
        <Row align="flex-start">
          <img src={StarIcon} alt="star icon" />
          <Text fontSize={14} fontWeight={400} paddingLeft={10}>
            By adding liquidity you&apos;ll earn {pair.swapFee / 10}% of all trades on this pair proportional to your
            share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your
            liquidity.
          </Text>
        </Row>
      )}
    </>
  )
}

const LiquidityTokensManageItemCell = styled.td`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px 0;
`

export default function FullPositionCard({ pair, stakedBalance }: PositionCardProps) {
  const { account } = useWalletState()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const userDefaultPoolBalance = usePairTokenBalance(account ?? undefined, pair)
  const totalPoolTokens = usePairTotalSupply(pair)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return !token0Deposited || !token1Deposited || !poolTokenPercentage ? null : (
    <>
      <LiquidityTokensManageItemCell>
        <AutoRow>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
          <Text fontWeight={700} fontSize={18} paddingLeft="18px">
            {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
          </Text>
        </AutoRow>
      </LiquidityTokensManageItemCell>
      <LiquidityTokensManageItemCell>
        <Text fontSize={16} fontWeight={400}>
          {userPoolBalance ? userPoolBalance.toFixed(2, undefined, Rounding.ROUND_DOWN) : '-'}
        </Text>
      </LiquidityTokensManageItemCell>
      <LiquidityTokensManageItemCell>
        <Row>
          {token0Deposited ? (
            <Text fontSize={16} fontWeight={400}>
              {`${token0Deposited?.toFixed(2, undefined, Rounding.ROUND_DOWN)} ${currency0.symbol}`}
            </Text>
          ) : (
            '-'
          )}
          <Text padding="0px 8px">+</Text>
          {token1Deposited ? (
            <Text fontSize={16} fontWeight={400}>
              {`${token1Deposited?.toFixed(2, undefined, Rounding.ROUND_DOWN)} ${currency1.symbol}`}
            </Text>
          ) : (
            '-'
          )}
        </Row>
      </LiquidityTokensManageItemCell>
      <LiquidityTokensManageItemCell>
        <Text fontSize={16} fontWeight={400}>
          {poolTokenPercentage
            ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
            : '-'}
        </Text>
      </LiquidityTokensManageItemCell>
      <LiquidityTokensManageItemCell>
        <AutoColumn gap="8px">
          {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.raw, BIG_INT_ZERO) && (
            <RowBetween>
              <RemoveButton as={Link} to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                <Text fontSize={16} fontWeight={600} color="#FF3131">
                  Remove
                </Text>
              </RemoveButton>
              <AddButton as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                <Text fontSize={16} fontWeight={600}>
                  Add
                </Text>
              </AddButton>
            </RowBetween>
          )}
          {stakedBalance && JSBI.greaterThan(stakedBalance.raw, BIG_INT_ZERO) && (
            <ButtonPrimary
              padding="8px"
              borderradius="8px"
              as={Link}
              to={`/uni/${currencyId(currency0)}/${currencyId(currency1)}`}
              width="100%"
            >
              Manage Liquidity in Rewards Pool
            </ButtonPrimary>
          )}
        </AutoColumn>
      </LiquidityTokensManageItemCell>
    </>
  )
}
