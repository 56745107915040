/* eslint-disable react/no-children-prop */
import AppBody from 'pages/AppBody'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components'
import BgComingSoon from '../../assets/images/bg_coming_soon.png'
import atmImg from '../../assets/images/atm.png'
import last24HoursImg from '../../assets/images/24_hours.png'
import receiveDollarImg from '../../assets/images/receive_dollar.png'
import PageHeader from './header'
import { Helmet } from 'react-helmet'
import { axiosInstance as queryProxy } from 'utils/queryProxy'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const PageWrapper = styled(AppBody)`
  border-radius: 0px;
  max-width: 992px;
  width: 100%;
  padding: 30px;
  box-shadow: none;
  color: #27292c;
  margin-top: 16px;
`

const AmbiMoneyLogo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
`

const TickerBox = styled.div`
  border-radius: 100px;
  border: 1px solid rgb(0, 0, 0, 0.12);
  font-size: 24px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const SubscribeBox = styled.div`
  position: relative;
  background: url(${BgComingSoon});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  text-align: center;
  padding-top: 72px;
  padding-bottom: 96px;
  margin-top: 32px;
  text-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
`

const SubscribeEmailBox = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 16px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 18px 30px;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
`

const SubscribeEmailInput = styled.div`
  text-shadow: none;
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 10px;
  width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    font-size: 18px;
    border: 0;
    outline: 0;
    background: transparent;
    flex: 1;
  }

  button {
    border: none;
    outline: none;
    background: #ff0000;
    color: #fff;
    font-size: 18px;
    padding: 12px 18px;
    box-shadow: 8px 16px 80px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    cursor: pointer;
  }
`

const SubscriptionSuccess = styled.div`
  background: #ffffff;
  border: 1px solid #6437e7;
  text-shadow: none;
  color: #6437e7;
  box-shadow: 2px 4px 16px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 18px 36px;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 360px;

  @media (min-width: 596px) {
    width: 540px;
  }
`

const ComingSoonFeaturesGrid = styled.div`
  display: grid;
  gap: 36px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 32px;

  @media (min-width: 596px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ComingSoonFeature = styled.div`
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function LendComingSoon({ loan }: { loan: any }) {
  const [markdown, setMarkdown] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const onSubscribe = async () => {
    setIsSubscribing(true)
    try {
      const { data } = await queryProxy.post('/v2/mailchimp/subscribe', {
        email_address: emailAddress,
        loan_tag: loan?.tag,
      })

      if (data.success) {
        setIsSubscribed(true)
      }
    } catch (err) {
      console.log(err)
    }
    setIsSubscribing(false)
  }

  useEffect(() => {
    if (loan && loan.markdownPath) {
      fetch(loan.markdownPath)
        .then((res) => res.text())
        .then((text) => setMarkdown(text))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{loan.name} | Ambidex</title>
      </Helmet>
      <PageHeader />
      <PageWrapper>
        <Flex flexDirection="column" alignItems="center">
          <AmbiMoneyLogo>
            <img src={loan.logoImg} alt="logo" style={{ width: 56, height: 56 }} />
          </AmbiMoneyLogo>
          <Text fontWeight={700} fontSize={40} lineHeight="50px" my="8px">
            {loan.name}
          </Text>
          <TickerBox>
            <Text color="rgba(39, 41, 44, 0.6)">Ticker:</Text>
            <Text fontWeight={700}>{loan.symbol}</Text>
          </TickerBox>
        </Flex>
        <SubscribeBox>
          <Text color="#6437E7" fontSize={100} lineHeight="90px" fontWeight={900}>
            COMING
            <br />
            SOON
          </Text>
          {!isSubscribed ? (
            <SubscribeEmailBox>
              <SubscribeEmailInput>
                <input
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  placeholder="Enter your email address"
                />
                <button onClick={onSubscribe} disabled={isSubscribing}>
                  Get Notified
                </button>
              </SubscribeEmailInput>
            </SubscribeEmailBox>
          ) : (
            <SubscriptionSuccess>
              Thanks for subscribing! We will keep you in the loop with the latest news and updates.
            </SubscriptionSuccess>
          )}
        </SubscribeBox>
        <Text mt="82px" fontSize="20px" lineHeight="30px">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={markdown}
            linkTarget="_blank"
            transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.REACT_APP_BASE_URL}${uri}`)}
          />
        </Text>
        {loan.id === 4 && (
          <ComingSoonFeaturesGrid>
            <ComingSoonFeature>
              <img src={atmImg} alt="atm" style={{ width: 64, height: 64 }} />
              <Text fontSize="20px" fontWeight={600} mt="24px">
                No withdrawal fees
              </Text>
            </ComingSoonFeature>
            <ComingSoonFeature>
              <img src={last24HoursImg} alt="atm" style={{ width: 64, height: 64 }} />
              <Text fontSize="20px" fontWeight={600} mt="24px">
                24h redemptions
              </Text>
            </ComingSoonFeature>
            <ComingSoonFeature>
              <img src={receiveDollarImg} alt="atm" style={{ width: 64, height: 64 }} />
              <Text fontSize="20px" fontWeight={600} mt="24px">
                Up to 5% APR
              </Text>
            </ComingSoonFeature>
          </ComingSoonFeaturesGrid>
        )}
      </PageWrapper>
    </>
  )
}
