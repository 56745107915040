import BigNumber from 'bignumber.js'

export enum LoanStatus {
  Initial,
  SaleStarted,
  SaleEnded,
  CancelledBeforeActive,
  Active,
  Defaulted,
  Redeemable,
}

export const loanStatusNames = {
  [LoanStatus.Initial]: 'New',
  [LoanStatus.SaleStarted]: 'Issuing',
  [LoanStatus.SaleEnded]: 'Issued',
  [LoanStatus.CancelledBeforeActive]: 'Canceled',
  [LoanStatus.Active]: 'Active',
  [LoanStatus.Defaulted]: 'Defaulted',
  [LoanStatus.Redeemable]: 'Matured',
}

export enum LoanInterestType {
  Fixed,
  Dynamic,
}

type LoanInterestData = {
  interestType: LoanInterestType // Loan Interest Type
  payoutSchedule: BigNumber // Interest Payment Payout Interval in seconds
  interestRate: BigNumber // Interest Payment APY in percentage in 6 decimals
}

type LoanTimeData = {
  activeTimestamp: BigNumber // Timestamp that the loan is activated at in seconds
  duration: BigNumber // Loan Duration in seconds
  defaultedTimestamp: BigNumber
}

export interface Loan {
  name: string // Loan Issuer
  symbol: string // Loan Ticker
  credentialType: string // Loan Credential Type
  maxSupply: BigNumber // Max Supply
  loanToken: string // Loan Token
  loanState: LoanStatus // Loan State
  redeemablePercentage: BigNumber // Redeemable percentage in 6 decimals
  loanInterestData: LoanInterestData
  loanTimeData: LoanTimeData
  loanStaking: string
}

export interface LoanUserData {
  stakedAmount: BigNumber
  pendingReward: BigNumber
}

export interface LoanState {
  data: Loan[]
  user: LoanUserData[]
  isError: boolean
}
