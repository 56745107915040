import React from 'react'
import styled from 'styled-components'

import errorImg from 'assets/images/error.png'

const MessageWrapper = styled.div`
  background: rgba(255, 213, 0, 0.16);
  box-shadow: 8px 16px 60px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 8px 16px;
  color: #27292c;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const WarningMessage = ({ text }: { text: string }) => {
  return (
    <MessageWrapper>
      <img src={errorImg} alt="error" />
      {text}
    </MessageWrapper>
  )
}
