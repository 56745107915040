import { Currency, ETHER, Token, TokenAmount } from '@uniswap/sdk-core'
import { JSBI } from '@ambidex/sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { Text } from 'rebass'
import { useLocation } from 'react-router'
import { hashpack } from 'connectors'
import styled from 'styled-components'
import { ButtonDropdownLight } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FindPoolTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row from '../../components/Row'
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal'
import { PairState, useV2Pair } from '../../hooks/useV2Pairs'
import { usePairAdder } from '../../state/user/hooks'
import { usePairTokenBalance, useWalletState } from '../../state/wallet/hooks'
import { StyledInternalLink, theme } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
import { BlueCard, GradientCard } from '../../components/Card'
import { TYPE } from '../../theme'
import PageHeader from '../../components/Header/PageHeader'
import { AppWrapper, CenterredCardAppWrapper } from 'pages/styled'
import { themes } from '@storybook/theming'
import useConnector from 'hooks/useConnector'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function PoolFinder() {
  const query = useQuery()

  const { account } = useWalletState()
  const connector = useConnector()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(null)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [loading, setLoading] = useState(false)
  const [pairExists, setPairExists] = useState(false)
  const [balanceExists, setBalanceExists] = useState(false)

  const fetchBalanceState = async () => {
    if (!currency0 || !currency1 || !connector) return

    const [pairState, balanceState] = await connector.getPairBalanceState(
      account,
      currency0 as Token,
      currency1 as Token
    )
    setPairExists(pairState)
    setBalanceExists(balanceState)
    setLoading(false)
    if (balanceState) {
      connector.saveLocalPair(account, (currency0 as Token).address, (currency1 as Token).address)
    }
  }

  useEffect(() => {
    if (currency0 && currency1) {
      setLoading(true)
      fetchBalanceState()
    }
  }, [currency0, currency1])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text textAlign="center">
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </LightCard>
  )

  return (
    <>
      <PageHeader />
      <AppWrapper>
        <AppBody>
          <CenterredCardAppWrapper>
            <FindPoolTabs origin={query.get('origin') ?? '/liquidity'} />
            <AutoColumn style={{ padding: '1rem' }} gap="md">
              <GradientCard>
                <TYPE.link fontWeight={400} color="#27292C" paddingX="8px" paddingY="2px" lineHeight="24px">
                  <b>Tip:</b> Use this tool to find pairs that don&apos;t automatically appear in the interface.
                </TYPE.link>
              </GradientCard>
              <ButtonDropdownLight
                onClick={() => {
                  setShowSearch(true)
                  setActiveField(Fields.TOKEN0)
                }}
              >
                {currency0 ? (
                  <Row>
                    <CurrencyLogo currency={currency0} />
                    <Text fontWeight={500} fontSize={16} marginLeft={'12px'}>
                      {currency0.symbol}
                    </Text>
                  </Row>
                ) : (
                  <Text fontWeight={500} fontSize={16} marginLeft={'12px'}>
                    Select a Token
                  </Text>
                )}
              </ButtonDropdownLight>

              <ColumnCenter>
                <Plus size="16" color="#888D9B" />
              </ColumnCenter>

              <ButtonDropdownLight
                onClick={() => {
                  setShowSearch(true)
                  setActiveField(Fields.TOKEN1)
                }}
              >
                {currency1 ? (
                  <Row>
                    <CurrencyLogo currency={currency1} />
                    <Text fontWeight={500} fontSize={16} marginLeft={'12px'}>
                      {currency1.symbol}
                    </Text>
                  </Row>
                ) : (
                  <Text fontWeight={500} fontSize={16} marginLeft={'12px'}>
                    Select a Token
                  </Text>
                )}
              </ButtonDropdownLight>
              {account &&
                currency0 &&
                currency1 &&
                (loading ? (
                  <LightCard padding="45px 10px">
                    <AutoColumn gap="sm" justify="center">
                      <Text textAlign="center">
                        Loading
                        <Dots />
                      </Text>
                    </AutoColumn>
                  </LightCard>
                ) : balanceExists ? (
                  <ColumnCenter
                    style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
                  >
                    <Text textAlign="center" fontWeight={500}>
                      Pool Imported!
                    </Text>
                    <StyledInternalLink to={`/liquidity`}>
                      <Text textAlign="center" fontWeight={600} paddingTop={10}>
                        Manage this pool
                      </Text>
                    </StyledInternalLink>
                  </ColumnCenter>
                ) : (
                  <ColumnCenter
                    style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
                  >
                    <Text textAlign="center" fontWeight={500}>
                      {!pairExists && 'Pool not found!'}
                      {pairExists && 'You don’t have liquidity in this pool yet.'}
                    </Text>
                    <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                      <Text textAlign="center" fontWeight={600} paddingTop={10}>
                        Add liquidity
                      </Text>
                    </StyledInternalLink>
                  </ColumnCenter>
                ))}
              {(!account || !currency0 || !currency1) && prerequisiteMessage}
            </AutoColumn>

            <CurrencySearchModal
              isOpen={showSearch}
              onCurrencySelect={handleCurrencySelect}
              onDismiss={handleSearchDismiss}
              showCommonBases
              selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
            />
          </CenterredCardAppWrapper>
        </AppBody>
      </AppWrapper>
    </>
  )
}
