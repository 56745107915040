import { AmbidexChainId } from 'constants/networks'
import athensgateMd from './templates/athensgate.md'
import ambimoneyMd from './templates/ambimoney.md'
import agrifiMd from './templates/agrifi.md'
import greenfiMd from './templates/greenfi.md'

type LoanType = {
  id: number
  name: string
  industry: string
  status: string
  apr: string
  duration: number
  backImg: string
  logoImg: string
  loanAddress: string
  markdownPath?: string
  investmentOffer?: any
  news?: any
  tag?: string
  symbol: string
}

export const TESTNET_LOANS: LoanType[] = [
  {
    id: 1,
    name: 'Knightsbridge (Issuing)',
    industry: 'Real Estate',
    status: 'Issuing',
    apr: 'Variable',
    duration: 7,
    symbol: 'KDCO',
    backImg: '/images/lend/athensgate/back.jpeg',
    logoImg: '/images/lend/athensgate/logo.png',
    loanAddress: '0x00000000000000000000000000000000003835ff',
    markdownPath: athensgateMd,
    investmentOffer: {
      apr: 'Up to 15%',
      maturityDate: '01/03/2027',
      nextInterestPaymentDate: '06/06/2023',
      financingVolume: '$10,000',
      loanType: 'Subordinated loan with qualified subordination',
      interestPaymentFrequency: 'Bi-annually',
      fullyIssuedAsOf: '01/02/2023',
      minimumInvestmentAmount: 100,
      maximumInvestmentAmount: 'No upper limit, corresponding to to the remaining quota available',
      relevantDocuments: [],
      interestPaymentSchedules: [
        { date: '01/06/2023', interest: '50,000 USDC', status: 'Completed' },
        { date: '01/12/2023', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
      ],
      financingStructure:
        'The SPV will be financed 100% by subordinated loans from crowd investors. No other investors or lenders will be involved, ensuring the risk profile for crowd investors will not change throughout the lifespan of this loan.',
    },
    news: [
      {
        date: 'January 20, 2023',
        content:
          'The Knightsdoor loan is now available for investment. Please ensure you have done your own due diligence and manage your risk accordingly before investing.',
      },
    ],
  },
  {
    id: 2,
    name: 'Knightsbridge (Active)',
    industry: 'Real Estate',
    status: 'Active',
    apr: 'Variable',
    duration: 7,
    symbol: 'KDCO',
    backImg: '/images/lend/athensgate/back.jpeg',
    logoImg: '/images/lend/athensgate/logo.png',
    loanAddress: '0x00000000000000000000000000000000003836fc',
    markdownPath: athensgateMd,
    investmentOffer: {
      apr: 'Up to 15%',
      maturityDate: '01/03/2027',
      nextInterestPaymentDate: '06/06/2023',
      financingVolume: '$10,000',
      loanType: 'Subordinated loan with qualified subordination',
      interestPaymentFrequency: 'Bi-annually',
      fullyIssuedAsOf: '01/02/2023',
      minimumInvestmentAmount: 100,
      maximumInvestmentAmount: 'No upper limit, corresponding to to the remaining quota available',
      relevantDocuments: [],
      interestPaymentSchedules: [
        { date: '01/06/2023', interest: '50,000 USDC', status: 'Completed' },
        { date: '01/12/2023', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
      ],
      financingStructure:
        'The SPV will be financed 100% by subordinated loans from crowd investors. No other investors or lenders will be involved, ensuring the risk profile for crowd investors will not change throughout the lifespan of this loan.',
    },
    news: [
      {
        date: 'January 20, 2023',
        content:
          'The Knightsdoor loan is now available for investment. Please ensure you have done your own due diligence and manage your risk accordingly before investing.',
      },
    ],
  },
  {
    id: 3,
    name: 'Knightsbridge (Matured)',
    industry: 'Real Estate',
    status: 'Matured',
    apr: 'Variable',
    duration: 7,
    symbol: 'KDCO',
    backImg: '/images/lend/athensgate/back.jpeg',
    logoImg: '/images/lend/athensgate/logo.png',
    loanAddress: '0x0000000000000000000000000000000000383791',
    markdownPath: athensgateMd,
    investmentOffer: {
      apr: 'Up to 15%',
      maturityDate: '01/03/2027',
      nextInterestPaymentDate: '06/06/2023',
      financingVolume: '$10,000',
      loanType: 'Subordinated loan with qualified subordination',
      interestPaymentFrequency: 'Bi-annually',
      fullyIssuedAsOf: '01/02/2023',
      minimumInvestmentAmount: 100,
      maximumInvestmentAmount: 'No upper limit, corresponding to to the remaining quota available',
      relevantDocuments: [],
      interestPaymentSchedules: [
        { date: '01/06/2023', interest: '50,000 USDC', status: 'Completed' },
        { date: '01/12/2023', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
      ],
      financingStructure:
        'The SPV will be financed 100% by subordinated loans from crowd investors. No other investors or lenders will be involved, ensuring the risk profile for crowd investors will not change throughout the lifespan of this loan.',
    },
    news: [
      {
        date: 'January 20, 2023',
        content:
          'The Knightsdoor loan is now available for investment. Please ensure you have done your own due diligence and manage your risk accordingly before investing.',
      },
    ],
  },
  {
    id: 4,
    name: 'AmbiMoney',
    industry: 'Money Market Fund',
    status: 'Coming Soon',
    apr: '5%',
    duration: 5,
    symbol: 'ABM',
    backImg: '/images/lend/ambimoney/back.png',
    logoImg: '/images/lend/ambimoney/logo.png',
    markdownPath: ambimoneyMd,
    loanAddress: '',
    tag: 'TESTNET_AMBIMONEY',
  },
  {
    id: 5,
    name: 'AgriFi (SPV) Ltd.',
    industry: 'Agriculture Trade Financing',
    status: 'Coming Soon',
    apr: '15%',
    duration: 5,
    symbol: 'AGF',
    backImg: '/images/lend/agrifi/back.jpg',
    logoImg: '/images/lend/agrifi/logo.png',
    markdownPath: agrifiMd,
    loanAddress: '',
    tag: 'TESTNET_AGRIFI',
  },
  {
    id: 6,
    name: 'GreenFi (SPV) Ltd.',
    industry: 'Renewable Energy Financing',
    status: 'Coming Soon',
    apr: '5.5%',
    duration: 5,
    symbol: 'GFI',
    backImg: '/images/lend/greenfi/back.jpg',
    logoImg: '/images/lend/greenfi/logo.png',
    markdownPath: greenfiMd,
    loanAddress: '',
    tag: 'TESTNET_GREENFI',
  },
]

export const MAINNET_LOANS: LoanType[] = [
  {
    id: 1,
    name: 'Knightsbridge',
    industry: 'Real Estate',
    status: 'Issuing',
    apr: 'Variable',
    duration: 7,
    symbol: 'KBR',
    backImg: '/images/lend/athensgate/back.jpeg',
    logoImg: '/images/lend/athensgate/logo.png',
    loanAddress: '0x00000000000000000000000000000000001eedd5',
    markdownPath: athensgateMd,
    investmentOffer: {
      apr: 'Up to 15%',
      maturityDate: '01/03/2027',
      nextInterestPaymentDate: '06/06/2023',
      financingVolume: '$10,000',
      loanType: 'Subordinated loan with qualified subordination',
      interestPaymentFrequency: 'Bi-annually',
      fullyIssuedAsOf: '01/02/2023',
      minimumInvestmentAmount: 100,
      maximumInvestmentAmount: 'No upper limit, corresponding to to the remaining quota available',
      relevantDocuments: [],
      interestPaymentSchedules: [
        { date: '01/06/2023', interest: '50,000 USDC', status: 'Completed' },
        { date: '01/12/2023', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2024', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2025', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2026', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2027', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2028', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/06/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
        { date: '01/12/2029', interest: '20,000 - 100,000 USDC', status: 'Pending' },
      ],
      financingStructure:
        'The SPV will be financed 100% by subordinated loans from crowd investors. No other investors or lenders will be involved, ensuring the risk profile for crowd investors will not change throughout the lifespan of this loan.',
    },
    news: [
      {
        date: 'January 20, 2023',
        content:
          'The Knightsbridge loan is now available for investment. Please ensure you have done your own due diligence and manage your risk accordingly before investing.',
      },
    ],
  },
  {
    id: 2,
    name: 'AmbiMoney',
    industry: 'Money Market Fund',
    status: 'Coming Soon',
    apr: '5%',
    duration: 5,
    symbol: 'ABM',
    backImg: '/images/lend/ambimoney/back.png',
    logoImg: '/images/lend/ambimoney/logo.png',
    markdownPath: ambimoneyMd,
    loanAddress: '',
    tag: 'MAINNET_AMBIMONEY',
  },
  {
    id: 3,
    name: 'AgriFi (SPV) Ltd.',
    industry: 'Agriculture Trade Financing',
    status: 'Coming Soon',
    apr: '15%',
    duration: 5,
    symbol: 'AGF',
    backImg: '/images/lend/agrifi/back.jpg',
    logoImg: '/images/lend/agrifi/logo.png',
    markdownPath: agrifiMd,
    loanAddress: '',
    tag: 'MAINNET_AGRIFI',
  },
  {
    id: 4,
    name: 'GreenFi (SPV) Ltd.',
    industry: 'Renewable Energy Financing',
    status: 'Coming Soon',
    apr: '5.5%',
    duration: 5,
    symbol: 'GFI',
    backImg: '/images/lend/greenfi/back.jpg',
    logoImg: '/images/lend/greenfi/logo.png',
    markdownPath: greenfiMd,
    loanAddress: '',
    tag: 'MAINNET_GREENFI',
  },
]

const LOANS: { [chainId in AmbidexChainId]: LoanType[] } = {
  [AmbidexChainId.HEDERA_MAINNET]: MAINNET_LOANS,
  [AmbidexChainId.HEDERA_TESTNET]: TESTNET_LOANS,
  [AmbidexChainId.MAINNET]: [],
  [AmbidexChainId.ROPSTEN]: [],
  [AmbidexChainId.KOVAN]: [],
  [AmbidexChainId.RINKEBY]: [],
  [AmbidexChainId.GÖRLI]: [],
}

export default LOANS
