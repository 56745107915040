import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, TokenAmount, WETH9 } from '@uniswap/sdk-core'
import React, { useCallback, useContext, useState } from 'react'
import { Plus } from 'react-feather'
import ReactGA from 'react-ga4'
import { RouteComponentProps } from 'react-router-dom'
import { Flex, Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { stubFalse } from 'lodash'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard, GradientCard, CircleGradientCard } from '../../components/Card'
import Column, { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'

import { DEX_CREDENTIAL_TOKEN_METADATAS, V2_ROUTER_ADDRESSES, ZERO_PERCENT } from '../../constants'
import { useV2RouterContract } from '../../hooks/useContract'
import { PairState } from '../../hooks/useV2Pairs'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'
import { useWalletState } from '../../state/wallet/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount, getLpTokenSymbol } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import PageHeader from '../../components/Header/PageHeader'
import { AppWrapper, CenterredCardAppWrapper } from 'pages/styled'
import useCredentialTokens from 'hooks/useCredentialTokens'
import { WarningMessage } from 'components/Message'
import { AmbidexChainId } from 'constants/networks'
import useConnector from 'hooks/useConnector'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId } = useWalletState()
  const connector = useConnector()

  const theme = useContext(ThemeContext)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const oneCurrencyIsWETH = false
  // TODO: Should replace with Hbar logic
  // Boolean(
  //   chainId &&
  //     ((currencyA && currencyEquals(currencyA, WETH9[chainId])) ||
  //       (currencyB && currencyEquals(currencyB, WETH9[chainId])))
  // )

  // credential token
  const credentialTokens = useCredentialTokens()
  const dexCredentialToken = credentialTokens?.find(
    (token) => token.metadata === DEX_CREDENTIAL_TOKEN_METADATAS[chainId as AmbidexChainId]
  )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toExact() ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  async function onAdd() {
    if (!account || !dexCredentialToken || !connector) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
    }

    // TODO: Should add similar HBAR logic
    // let estimate,
    //   method: (...args: any) => Promise<TransactionResponse>,
    //   args: Array<string | string[] | number>,
    //   value: BigNumber | null
    // if (currencyA === ETHER || currencyB === ETHER) {
    //   const tokenBIsETH = currencyB === ETHER
    //   estimate = router.estimateGas.addLiquidityETH
    //   method = router.addLiquidityETH
    //   args = [
    //     wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
    //     (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
    //     amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
    //     amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
    //     account,
    //     deadline.toHexString(),
    //   ]
    //   value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    // } else {
    //   estimate = router.estimateGas.addLiquidity
    //   method = router.addLiquidity
    //   args = [
    //     wrappedCurrency(currencyA, chainId)?.address ?? '',
    //     wrappedCurrency(currencyB, chainId)?.address ?? '',
    //     parsedAmountA.raw.toString(),
    //     parsedAmountB.raw.toString(),
    //     amountsMin[Field.CURRENCY_A].toString(),
    //     amountsMin[Field.CURRENCY_B].toString(),
    //     account,
    //     deadline.toHexString(),
    //   ]
    //   value = null
    // }

    const { serial_number } = dexCredentialToken
    let args: Array<string | string[] | number>
    if (currencyA.symbol === 'HBAR' || currencyB.symbol === 'HBAR') {
      const tokenBIsHBAR = currencyB.symbol === 'HBAR'
      args = [
        wrappedCurrency(tokenBIsHBAR ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsHBAR ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsHBAR ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsHBAR ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ]
    } else {
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA?.multiply(Math.pow(10, 6)).quotient.toString(), //TODO: Should be removed with new v2-sdk
        parsedAmountB?.multiply(Math.pow(10, 6)).quotient.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
    }

    setAttemptingTxn(true)
    // estimate(...args, value ? { value } : {})
    //   .then((estimatedGasLimit) =>
    await (currencyA.symbol === 'HBAR' || currencyB.symbol === 'HBAR'
      ? connector.addLiquidityHBAR(args, serial_number)
      : connector.addLiquidity(args, serial_number)
    )
      .then((txId) => {
        setAttemptingTxn(false)

        setTxHash(txId)

        ReactGA.event({
          category: 'Liquidity',
          action: 'Add',
          label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
        })
      })
      .catch((error) => {
        const txId = error.message
        ReactGA.event({
          category: 'Error',
          action: 'Error: add liquidity',
          label: `${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} - ${txId}`,
        })
        setAttemptingTxn(false)
        console.error(error)
      })
  }

  const modalHeader = () => {
    return (
      <div style={{ padding: '40px 24px 0 24px' }}>
        {noLiquidity ? (
          <AutoColumn gap="20px">
            <LightCard mt="20px" mb="20px" borderradius="20px">
              <RowFlat>
                <Text fontSize="28px" fontWeight={500} lineHeight="32px" marginRight={20}>
                  {getLpTokenSymbol(currencyA, currencyB)}
                </Text>
                <DoubleCurrencyLogo
                  currency0={currencies[Field.CURRENCY_A]}
                  currency1={currencies[Field.CURRENCY_B]}
                  size={30}
                />
              </RowFlat>
            </LightCard>
          </AutoColumn>
        ) : (
          <AutoColumn gap="6px" justify="center">
            <div>
              <DoubleCurrencyLogo
                currency0={currencies[Field.CURRENCY_A]}
                currency1={currencies[Field.CURRENCY_B]}
                size={24}
              />
            </div>
            <Text fontSize={24} fontWeight={500}>
              {liquidityMinted?.toSignificant(6)}
            </Text>
            <Text fontSize={16} opacity={0.6}>
              {getLpTokenSymbol(currencyA, currencyB) + ' Pool Tokens'}
            </Text>
          </AutoColumn>
        )}
      </div>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        allowedSlippage={allowedSlippage}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'HBAR'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  if (!credentialTokens) return <></>

  return (
    <>
      <PageHeader />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
        currencyToAdd={pair?.liquidityToken}
      />
      <AppWrapper>
        <AppBody>
          <CenterredCardAppWrapper>
            <AddRemoveTabs creating={isCreate} adding={true} />
            <Wrapper>
              <AutoColumn gap="32px">
                <AutoColumn>
                  {noLiquidity ||
                    (isCreate ? (
                      <ColumnCenter style={{ maxWidth: '480px' }}>
                        <BlueCard>
                          <AutoColumn gap="10px">
                            <TYPE.link fontWeight={600} color={'primaryText1'}>
                              You are the first liquidity provider.
                            </TYPE.link>
                            <TYPE.link fontWeight={400} color={'primaryText1'}>
                              The ratio of tokens you add will set the price of this pool.
                            </TYPE.link>
                            <TYPE.link fontWeight={400} color={'primaryText1'}>
                              Once you are happy with the rate click supply to review.
                            </TYPE.link>
                          </AutoColumn>
                        </BlueCard>
                      </ColumnCenter>
                    ) : (
                      <ColumnCenter style={{ maxWidth: '480px' }}>
                        <GradientCard>
                          <TYPE.link fontWeight={400} color="#27292C" paddingX="8px" paddingY="2px" lineHeight="24px">
                            <b>Tip:</b> When you add liquidity, you will receive pool tokens representing your position.
                            These tokens automatically earn fees proportional to your share of the pool, and can be
                            redeemed at any time.
                          </TYPE.link>
                        </GradientCard>
                      </ColumnCenter>
                    ))}
                </AutoColumn>
                <AutoColumn gap="10px">
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onFieldAInput}
                    onMax={() => {
                      onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                    }}
                    onCurrencySelect={handleCurrencyASelect}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                    currency={currencies[Field.CURRENCY_A]}
                    id="add-liquidity-input-tokena"
                    showCommonBases
                  />
                  <ColumnCenter>
                    <Plus size="24" color={'#856cff'} />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onFieldBInput}
                    onCurrencySelect={handleCurrencyBSelect}
                    onMax={() => {
                      onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                    }}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                    currency={currencies[Field.CURRENCY_B]}
                    id="add-liquidity-input-tokenb"
                    showCommonBases
                  />
                </AutoColumn>
                {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
                  <>
                    <CircleGradientCard>
                      <AutoColumn gap="24px">
                        <RowBetween style={{ justifyContent: 'center' }}>
                          <Text fontWeight={500} fontSize={16} color={'#27292C'}>
                            {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                          </Text>
                        </RowBetween>
                        <PoolPriceBar
                          currencies={currencies}
                          poolTokenPercentage={poolTokenPercentage}
                          noLiquidity={noLiquidity}
                          price={price}
                        />
                      </AutoColumn>
                    </CircleGradientCard>
                  </>
                )}
                <AutoColumn>
                  {addIsUnsupported ? (
                    <ButtonPrimary disabled={true}>
                      <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
                    </ButtonPrimary>
                  ) : !account ? (
                    <ButtonLight onClick={toggleWalletModal} style={{ backgroundColor: '#6437E7' }}>
                      Connect Wallet
                    </ButtonLight>
                  ) : (
                    <AutoColumn gap={'md'}>
                      <ButtonError
                        style={{ backgroundColor: '#6437E7' }}
                        onClick={() => {
                          expertMode ? onAdd() : setShowConfirm(true)
                        }}
                        disabled={!isValid || !dexCredentialToken}
                        error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {error ?? 'Supply'}
                        </Text>
                      </ButtonError>
                    </AutoColumn>
                  )}
                </AutoColumn>
              </AutoColumn>
            </Wrapper>
          </CenterredCardAppWrapper>
        </AppBody>
        {!dexCredentialToken && (
          <Flex justifyContent="center" mt="40px" mb="20px">
            <WarningMessage text="You do not have the required credential token." />
          </Flex>
        )}
        {!addIsUnsupported ? (
          pair && !noLiquidity && pairState !== PairState.INVALID ? (
            <AppBody>
              <AutoColumn style={{ padding: '20px' }}>
                <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
              </AutoColumn>
            </AppBody>
          ) : null
        ) : (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </AppWrapper>
    </>
  )
}
