import React from 'react'

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = '16px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  return (
    <StyledSVG viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
      <path
        d="M1.31189 5.3337C0.901471 5.17008 0.431931 5.36947 0.3101 5.79417C-0.135678 7.34814 -0.101777 9.00762 0.417851 10.5517C1.02289 12.3495 2.24708 13.8739 3.87194 14.8527C5.49681 15.8315 7.41662 16.2011 9.28876 15.8955C11.1609 15.5899 12.8635 14.629 14.0928 13.1843C15.3221 11.7397 15.998 9.90515 16 8.00824C16.0019 6.11132 15.3298 4.27543 14.1035 2.82821C12.8772 1.38099 11.1765 0.416599 9.30502 0.10716C7.6977 -0.158597 6.0542 0.0735867 4.59163 0.762375C4.19191 0.95062 4.07025 1.44602 4.29746 1.82495C4.52467 2.20389 5.01459 2.32174 5.41889 2.14355C6.55183 1.64422 7.81123 1.4819 9.04402 1.68573C10.5412 1.93328 11.9017 2.70479 12.8828 3.86257C13.8638 5.02035 14.4016 6.48906 14.4 8.00659C14.3984 9.52412 13.8577 10.9917 12.8743 12.1475C11.8908 13.3032 10.5287 14.0719 9.03101 14.3164C7.5333 14.5609 5.99745 14.2652 4.69756 13.4821C3.39766 12.6991 2.41831 11.4796 1.93428 10.0413C1.53573 8.85707 1.49436 7.58793 1.80589 6.38967C1.91706 5.96206 1.7223 5.49732 1.31189 5.3337Z"
        fill="url(#paint0_linear_603_1030)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_603_1030"
          x1="0"
          y1="0"
          x2="16.6334"
          y2="0.688082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6437E7" />
          <stop offset="1" stopColor="#D401C9" />
        </linearGradient>
      </defs>
    </StyledSVG>
  )
}
