import axios from 'axios'
import { CURRENT_CHAIN_ID, HEDERA_MIRROR_API } from 'constants/index'
import { CredentialTokenType } from 'hooks/useCredentialTokens'

export const axiosInstance = axios.create({
  baseURL: HEDERA_MIRROR_API[CURRENT_CHAIN_ID],
})

export const getExchangeRate = async (): Promise<number> => {
  return await axiosInstance
    .get<{ current_rate: { cent_equivalent: number; hbar_equivalent: number } }>('network/exchangerate')
    .then(({ data }) => data.current_rate.cent_equivalent / data.current_rate.hbar_equivalent / 100)
}

export const getContractId = async (contractAddress: string): Promise<string> => {
  return await axiosInstance
    .get<{ contract_id: string }>(`contracts/${contractAddress}`)
    .then(({ data }) => data.contract_id)
}

export const getTotalSupply = async (tokenId: string): Promise<string> => {
  return await axiosInstance.get<{ total_supply: string }>(`tokens/${tokenId}`).then(({ data }) => data.total_supply)
}

export const getNfts = async (accountId: string, tokenId: string) => {
  return await axiosInstance.get(`accounts/${accountId}/nfts?token.id=${tokenId}&limit=100`).then(({ data }) => {
    return data.nfts.map((item: CredentialTokenType) => ({
      ...item,
      metadata: Buffer.from(item.metadata, 'base64').toString('utf-8'),
    }))
  })
}
