import React from 'react'
import styled from 'styled-components'

import verifiedImg from 'assets/images/verified.png'

const MessageWrapper = styled.div`
  background: rgba(65, 192, 91, 0.1);
  box-shadow: 8px 16px 60px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 8px 16px;
  color: #41c05b;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const SuccessMessage = ({ text }: { text: string }) => {
  return (
    <MessageWrapper>
      <img src={verifiedImg} alt="verified" />
      {text}
    </MessageWrapper>
  )
}
