import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import JSBI from 'jsbi'
import { Link } from 'react-router-dom'
import { Pair } from '@ambidex/sdk'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import { useTokenBalances, useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { CustomLightSpinner } from '../../theme/components'
import { ExternalLink, TYPE, HideSmall } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { RowBetween, RowFixed, AutoRow } from '../../components/Row'
import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useWalletState } from '../../state/wallet/hooks'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { toV2LiquidityToken, useTrackedTokenPairs, useV2LiquidityTokens } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import Circle from '../../assets/images/blue-loader.svg'
import { BIG_INT_ZERO } from '../../constants'
import PageHeader from 'components/Header/PageHeader'
import { Helmet } from 'react-helmet'

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
  background-color: white;
  padding: 30px;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
  width: 164px;
  height: 64px;
  background-color: #5c38ff;
  font-weight: 400;
  font-size: 16px;
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  background: #ffffff;
  padding: 21px 32px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  box-shadow: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LiquidityTokensWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: calc(100vh - 500px);
`
const LiquidityTokensManageWrapper = styled.table`
  width: 100%;
  height: fit-content;
`

const LiquidityTokensManageHeaderItem = styled.th<{ width: string }>`
  padding: 24px 0px;
  width: ${(props) => props.width};
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useWalletState()

  // credential token

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const liquidityTokens = useV2LiquidityTokens(trackedTokenPairs)
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens, index) => ({ liquidityToken: liquidityTokens[index], tokens })),
    [trackedTokenPairs, liquidityTokens]
  )
  const v2PairsBalances = useTokenBalances(account ?? undefined, liquidityTokens)

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(
        ({ liquidityToken }) => liquidityToken && v2PairsBalances[liquidityToken?.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const liquidityTokenCurrencies = useMemo(() => liquidityTokensWithBalances.map(({ tokens }) => tokens), [
    liquidityTokensWithBalances,
  ])

  const v2Pairs = useV2Pairs(liquidityTokenCurrencies)
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  const v2IsLoading =
    trackedTokenPairs.length &&
    !(Object.keys(v2PairsBalances).length && allV2PairsWithLiquidity.length === liquidityTokensWithBalances.length)

  // show liquidity even if its deposited in rewards contract
  const stakingPairs = [] as any[]

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <Helmet>
        <title>Liquidity | Ambidex</title>
      </Helmet>
      <PageHeader />
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />

        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding={'0'}>
              <HideSmall>
                <Text fontWeight={700} fontSize={30}>
                  Your liquidity
                </Text>
              </HideSmall>
              <ButtonRow>
                <ResponsiveButtonSecondary
                  id="join-pool-button"
                  as={Link}
                  padding="6px 8px"
                  borderradius="12px"
                  to="/find"
                >
                  <Text fontWeight={700} fontSize={18} color={theme.text3}>
                    Import Pool
                  </Text>
                </ResponsiveButtonSecondary>
                <ResponsiveButtonPrimary
                  id="join-pool-button"
                  as={Link}
                  padding="6px 8px"
                  borderradius="12px"
                  to="/add/HBAR"
                >
                  <Text fontWeight={700} fontSize={18}>
                    Add Liquidity
                  </Text>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow>
            <LiquidityTokensWrapper>
              {!account ? (
                <Card padding="40px">
                  <TYPE.body color={theme.text3} textAlign="center">
                    Connect to a wallet to view your liquidity.
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <AutoRow>
                  <TYPE.body color={theme.text3} fontSize={24} textAlign="center" width="100%">
                    <CustomLightSpinner src={Circle} alt="loader" size={'16px'} style={{ marginRight: '10px' }} />
                    <Dots>Loading</Dots>
                  </TYPE.body>
                </AutoRow>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <LiquidityTokensManageWrapper>
                  <thead>
                    <tr>
                      <LiquidityTokensManageHeaderItem width="20%">
                        <Text color={theme.text3} fontSize={16} fontWeight={700} textAlign="left">
                          Token Pair
                        </Text>
                      </LiquidityTokensManageHeaderItem>
                      <LiquidityTokensManageHeaderItem width="16%">
                        <Text color={theme.text3} fontSize={16} fontWeight={700} textAlign="left">
                          Pool tokens
                        </Text>
                      </LiquidityTokensManageHeaderItem>
                      <LiquidityTokensManageHeaderItem width="32%">
                        <Text color={theme.text3} fontSize={16} fontWeight={700} textAlign="left">
                          Pooled amount
                        </Text>
                      </LiquidityTokensManageHeaderItem>
                      <LiquidityTokensManageHeaderItem width="12%">
                        <Text color={theme.text3} fontSize={16} fontWeight={700} textAlign="left">
                          Pool share
                        </Text>
                      </LiquidityTokensManageHeaderItem>
                      <LiquidityTokensManageHeaderItem width="20%">&nbsp;</LiquidityTokensManageHeaderItem>
                    </tr>
                  </thead>
                  <tbody>
                    {v2PairsWithoutStakedAmount.map((v2Pair) => (
                      <tr key={v2Pair.liquidityToken.address}>
                        <FullPositionCard pair={v2Pair} />
                      </tr>
                    ))}
                  </tbody>
                </LiquidityTokensManageWrapper>
              ) : (
                <AutoRow justify="center" margin="auto">
                  <Text color={theme.text3} fontSize={16} fontWeight={400}>
                    You have no liquidity yet.
                  </Text>
                  <Text color={theme.text3} fontSize={16} fontWeight={700}>
                    &nbsp;&quot;Add Liquidity&quot;&nbsp;
                  </Text>
                  <Text color={theme.text3} fontSize={16} fontWeight={400}>
                    Click button to add liquidity
                  </Text>
                </AutoRow>
              )}
            </LiquidityTokensWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
