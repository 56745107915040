import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { AppState } from 'state'
import { axiosInstance as queryProxy } from 'utils/queryProxy'
import BigNumber from 'bignumber.js'
import { AccountId } from '@hashgraph/sdk'
import { reset } from './actions'
import { LoanState } from './types'

const initialState: LoanState = {
  data: [],
  user: [],
  isError: false,
}

export const fetchLoanDataAsync = createAsyncThunk<any[], string, { state: AppState }>(
  'loans/fetchLoanDataAsync',
  async (contractAddress) => {
    const { data } = await queryProxy.get(`/loan-call?contractAddress=${contractAddress}&functionName=loanData`)

    return data
  }
)

export const fetchLoanStakingAddress = createAsyncThunk<any[], string, { state: AppState }>(
  'loans/fetchLoanStakingAddress',
  async (contractAddress) => {
    const { data } = await queryProxy.get(`/loan-call?contractAddress=${contractAddress}&functionName=loanStaking`)

    return data
  }
)

export const fetchLoanUserDataAsync = createAsyncThunk<
  { pendingReward: any; stakedAmount: any },
  { account: string; contractAddress: string },
  { state: AppState }
>('loans/fetchLoanUserDataAsync', async ({ account, contractAddress }) => {
  const { data } = await queryProxy.get(
    `/v2/loans/user?account=${AccountId.fromString(account).toSolidityAddress()}&contractAddress=${contractAddress}`
  )

  return data
})

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchLoanDataAsync.fulfilled, (state, action) => {
    const [
      name,
      symbol,
      credentialType,
      maxSupply,
      loanToken,
      redeemablePercentage,
      loanState,
      loanInterestData,
      loanTimeData,
    ] = action.payload

    state.data = [
      {
        ...state.data[0],
        name,
        symbol,
        credentialType,
        maxSupply: new BigNumber(maxSupply.hex, 16),
        loanToken,
        redeemablePercentage: redeemablePercentage?.hex
          ? new BigNumber(redeemablePercentage?.hex, 16)
          : new BigNumber(-1),
        loanState,
        loanInterestData: {
          interestType: loanInterestData[0],
          payoutSchedule: new BigNumber(loanInterestData[1].hex, 16),
          interestRate: new BigNumber(loanInterestData[2].hex, 16),
        },
        loanTimeData: {
          activeTimestamp: new BigNumber(loanTimeData[0].hex, 16),
          duration: new BigNumber(loanTimeData[1].hex, 16),
          defaultedTimestamp: new BigNumber(loanTimeData[2].hex, 16),
        },
      },
    ]
  })
  builder.addCase(fetchLoanUserDataAsync.fulfilled, (state, action) => {
    state.user = [
      {
        pendingReward: new BigNumber(action.payload.pendingReward),
        stakedAmount: new BigNumber(action.payload.stakedAmount),
      },
    ]
  })
  builder.addCase(fetchLoanStakingAddress.fulfilled, (state, action) => {
    const [address] = action.payload
    state.data = [
      {
        ...state.data[0],
        loanStaking: address,
      },
    ]
  })
  builder.addCase(fetchLoanDataAsync.rejected, (state, action) => {
    state.isError = true
  })
  builder.addCase(fetchLoanStakingAddress.rejected, (state, action) => {
    state.isError = true
  })
  builder.addCase(fetchLoanUserDataAsync.rejected, (state, action) => {
    state.user = [
      {
        pendingReward: new BigNumber(0),
        stakedAmount: new BigNumber(0),
      },
    ]
    state.isError = true
  })
  builder.addCase(reset, (state, action) => {
    state.data = []
    state.user = []
  })
})
