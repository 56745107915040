import React, { useEffect } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import Persona from 'persona'
import Header from 'components/Header'
import bgImg from '../../assets/images/bg_connect_wallet.png'
import adxLogo from '../../assets/images/adx_logo_black.svg'
import lineImg from '../../assets/images/line3.svg'
import currencyExchangeIcon from '../../assets/images/currency_exchange.svg'
import swapVertIcon from '../../assets/images/swap_vert.svg'
import waterDropIcon from '../../assets/images/water_drop.svg'
import psychiatryIcon from '../../assets/images/psychiatry.svg'
import arrowOutwardSvg from '../../assets/svg/arrow_outward.svg'
import topographySvg from '../../assets/svg/topography.svg'
import { useWalletState } from '../../state/wallet/hooks'
import { Box, Text } from 'rebass'
import { PERSONA_ENVIRONMENT_IDS } from 'constants/index'
import { AmbidexChainId } from 'constants/networks'
import { Helmet } from 'react-helmet'

const Container = styled.div`
  padding: 40px 0px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: url(${bgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const PageWrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
`

const ImagesBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const Heading = styled.h1`
  font-weight: 800;
  font-size: 64px;
  background: linear-gradient(92.37deg, #6437e7 0%, #d401c9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  width: 75%;

  @media (max-width: 768px) {
    font-size: 48px;
  }
`

const LargeFeatureCard = styled.div`
  padding: 24px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  width: 70%;
  margin: 0 auto;
  margin-top: 40px;
  gap: 16px;

  h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    margin-top: 8px;
  }

  @media (max-width: 1280px) {
    width: 80%;
  }
`

const Features = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

const FeatureCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 16px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 400px;

  @media (max-width: 1280px) {
    width: 80%;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    margin-top: 8px;
  }
`

const ConnectWalletBox = styled.div`
  background: url(${topographySvg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #6437e7;
  border-radius: 8px;
  padding: 36px 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  flex-wrap: wrap;

  @media (max-width: 1280px) {
    width: 80%;
  }
`

const LearnMoreLink = styled.a`
  color: #fff;
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
`

export default function WalletConnect({ history }: RouteComponentProps) {
  const { account, isAuthed } = useWalletState()
  const chainId = (process.env.REACT_APP_CHAIN_ID ?? AmbidexChainId.HEDERA_TESTNET) as AmbidexChainId
  const environmentId = PERSONA_ENVIRONMENT_IDS[chainId]

  const client = new Persona.Client({
    templateId: 'itmpl_RszEJmQkWtDs16UU6EkJvygM',
    environmentId,
    referenceId: account,
    onReady: () => {
      console.log('the client is ready')
    },
    onComplete: ({ inquiryId, fields }) => {
      // Inquiry completed. Optionally tell your server about it.
      console.log(`Sending finished inquiry ${inquiryId} to backend`)
    },
    onCancel: ({ inquiryId, sessionToken }) => console.log('onCancel'),
    onError: (error) => console.log(error),
  })

  useEffect(() => {
    if (isAuthed) {
      history.push('/')
    }
  }, [history, isAuthed])

  return (
    <Container>
      <Helmet>
        <title>Connect Wallet | Ambidex</title>
      </Helmet>
      <PageWrapper>
        <ImagesBox>
          <img src={adxLogo} alt="logo" />
          <img src={lineImg} alt="logo" />
        </ImagesBox>
        <Heading>Tokenized finance made simple and accessible</Heading>
        <LargeFeatureCard>
          <img src={currencyExchangeIcon} alt="lend" />
          <div>
            <h3>Lend and Earn</h3>
            <p>Earn sustainable returns by lending to quality real world projects and companies</p>
          </div>
        </LargeFeatureCard>
        <Features>
          <FeatureCard>
            <img src={swapVertIcon} alt="lend" />
            <div>
              <h3>Swap</h3>
              <p>Explore and trade tokens in the Hedera ecosystem</p>
            </div>
          </FeatureCard>
          <FeatureCard>
            <img src={waterDropIcon} alt="lend" />
            <div>
              <h3>Market Make</h3>
              <p>Earn trading fees by providing liquidity</p>
            </div>
          </FeatureCard>
          <FeatureCard>
            <img src={psychiatryIcon} alt="lend" />
            <div>
              <h3>Earn Rewards</h3>
              <p>Receive reward tokens for engaging with the Ambidex platform</p>
            </div>
          </FeatureCard>
        </Features>
        {account ? (
          isAuthed ? (
            <Redirect to="/" />
          ) : (
            <ConnectWalletBox>
              <Text textAlign="center">
                To apply to be whitelisted,{' '}
                <a
                  style={{ color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    client.open()
                  }}
                >
                  please verify your identity
                </a>
              </Text>
              <Header />
            </ConnectWalletBox>
          )
        ) : (
          <ConnectWalletBox>
            <div>
              <Text fontWeight={700} fontSize={24} lineHeight="30px">
                Please connect and authorize your wallet to access Ambidex
              </Text>
              <Text marginTop="12px">
                Please make sure you have added the Hashpack wallet in your browser.
                <LearnMoreLink href="https://ambidex.fi/" target="_blank">
                  Visit our site to learn more
                  <img src={arrowOutwardSvg} alt="arrow" />
                </LearnMoreLink>
              </Text>
            </div>
            <Box my={3}>
              <Header />
            </Box>
          </ConnectWalletBox>
        )}
      </PageWrapper>
    </Container>
  )
}
