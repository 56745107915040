import { TokenId } from '@hashgraph/sdk'
import { Currency, ETHER, Token } from '@uniswap/sdk-core'
import { AmbidexChainId } from 'constants/networks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import hbarLogo from '../../assets/images/hbar.svg'
import tokenLogo from '../../assets/images/token.svg'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { MAINNET_TOKENS, TESTNET_TOKENS } from 'constants/tokens'

export const getTokenLogo = (symbol: string, chainId: AmbidexChainId) => {
  if (symbol === 'HBAR') return hbarLogo
  if (chainId === AmbidexChainId.HEDERA_TESTNET) {
    const token = TESTNET_TOKENS.find((token) => token.symbol === symbol)
    return token?.logoURI ?? tokenLogo
  } else if (AmbidexChainId.HEDERA_MAINNET) {
    const token = MAINNET_TOKENS.find((token) => token.symbol === symbol)
    return token?.logoURI ?? tokenLogo
  }
  return tokenLogo
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      const defaultUrls =
        currency.chainId === AmbidexChainId.HEDERA_MAINNET || currency.chainId === AmbidexChainId.HEDERA_TESTNET
          ? [getTokenLogo(currency.symbol ?? '', currency.chainId)]
          : []
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, ...defaultUrls]
      }
      return defaultUrls
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
  }
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} {...rest} />
}
