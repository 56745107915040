import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { Text } from 'rebass'
import styled from 'styled-components'
import closeIcon from 'assets/images/close.svg'
import forumIcon from 'assets/images/forum.svg'
import sendIcon from 'assets/images/send.svg'
import chatBgImg from 'assets/images/chat_bg.png'
import { ChatMessagesProvider, useChatMessages } from './context'
import { useLocation } from 'react-router-dom'

const ChatAction = styled.div`
  position: fixed;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  bottom: 40px;
  left: calc(100vw - 120px);
  cursor: pointer;

  button {
    width: 64px;
    height: 64px;
    background: #6437e7;
    border-radius: 100%;
    border: none;
    outline: none;
    user-select: none;
    cursor: pointer;
    margin: 0;
  }
`

const ChatBotWrapper = styled.div`
  background: #ffffff;
  box-shadow: 8px 16px 80px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  width: 480px;
  height: 80%;
  position: fixed;
  bottom: 40px;
  right: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`

const ChatBotHeader = styled.div`
  background: url(${chatBgImg});
  background-repeat: no-repeat;
  background-position: bottom
  padding-bottom: 48px;
  color: #fff;
`

const ChatBotToolbar = styled.div`
  background-color: #6437e7;
  display: flex;
  align-items: center;
  padding: 28px 32px 8px;
`

const AdxLogoImg = styled.img`
  width: 48px;
  height: 48px;
`

const IconButton = styled.button`
  border: none;
  outline: none;
  user-select: none;
  background: none;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: auto;
`

const ChatBotBody = styled.div`
  padding: 0px 32px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 1px;
`

const MessagesBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  padding: 24px 0px;
  padding-right: 12px;
`

const AnswerBox = styled.div`
  display: flex;

  img {
    width: 40px;
    height: 40px;
  }
`

const AnswerMessage = styled.div`
  max-width: 320px;
  background: #6437e7;
  border-radius: 6px;
  padding: 10px;
  color: #fff;
  margin-left: 10px;
  margin-bottom: 32px;
`

const QuestionMessage = styled.div`
  max-width: 320px;
  background: linear-gradient(101.59deg, #f8f5fe 0%, #ffebfe 100%);
  border-radius: 6px;
  color: #27292c;
  padding: 10px;
  margin-left: auto;
  margin-bottom: 32px;
`

const InputBox = styled.div`
  position: relative;
  padding: 10px 0px;
  padding-right: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`

const MessageTextInput = styled.input`
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  height: 60px;
`

const SendButton = styled(ChatAction)`
  position: absolute;
  top: 10px;
  right: -10px;
  left: auto;
`

const ChatMessages = () => {
  const [question, setQuestion] = useState<string>('')
  const { messages, askQuestion } = useChatMessages()
  const ref = useRef<HTMLDivElement>(null)

  const handleSend = () => {
    if (question) {
      askQuestion?.(question)
      ReactGA.event({
        category: 'Chatbot',
        action: 'Question',
        label: question,
      })
      setQuestion('')
    }
  }

  const handleKeydown = (e: any) => {
    if (e.key === 'Enter') {
      handleSend()
    }
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [messages])

  return (
    <>
      <MessagesBox ref={ref}>
        <AnswerBox>
          <img src="/adx_logo.png" alt="ambidex" />
          <AnswerMessage>Hi there! How can I help you today?</AnswerMessage>
        </AnswerBox>
        {messages?.map((message) => {
          if (message.type === 'question') {
            return <QuestionMessage key={message.id}>{message.text}</QuestionMessage>
          }
          return (
            <AnswerBox key={message.id}>
              <img src="/adx_logo.png" alt="ambidex" />
              <AnswerMessage>{message.text === null ? '...' : message.text}</AnswerMessage>
            </AnswerBox>
          )
        })}
      </MessagesBox>
      <InputBox>
        <MessageTextInput
          placeholder="Enter your question..."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={handleKeydown}
        />
        <SendButton onClick={handleSend}>
          <IconButton>
            <img src={sendIcon} alt="send" />
          </IconButton>
        </SendButton>
      </InputBox>
    </>
  )
}

export const ChatBot = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const toggleChatBot = () => setOpen(!open)

  if (location.pathname === '/connect-wallet') return <></>

  return !open ? (
    <ChatAction>
      <IconButton onClick={toggleChatBot}>
        <img src={forumIcon} alt="forum" />
      </IconButton>
    </ChatAction>
  ) : (
    <ChatBotWrapper>
      <ChatBotHeader>
        <ChatBotToolbar>
          <AdxLogoImg src="adx_logo.png" alt="logo" />
          <Text fontSize={24} fontWeight={600} marginLeft="8px">
            Ambidex Chatbot
          </Text>
          <IconButton onClick={toggleChatBot}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </ChatBotToolbar>
      </ChatBotHeader>
      <ChatBotBody>
        <ChatMessagesProvider>
          <ChatMessages />
        </ChatMessagesProvider>
      </ChatBotBody>
    </ChatBotWrapper>
  )
}

export default ChatBot
