import { Web3Provider } from '@ethersproject/providers'
import { AmbidexChainId } from 'constants/networks'

import { NetworkConnector } from './NetworkConnector'
import { HashConnector } from './Hashconnect'
import { Bladeconnect } from './Bladeconnect'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? AmbidexChainId.HEDERA_TESTNET.toString()
)

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const hashpack = new HashConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  defaultChainId: 1,
})

export const blade = new Bladeconnect({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  defaultChainId: 1,
})
