import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div<{ margin?: string }>`
  position: relative;
  margin-top: ${({ margin }) => margin ?? '0px'};
  /* min-width: 480px; */
  min-width: 580px;
  max-width: 600px;
  background: ${({ theme }) => theme.bg0};
  box-shadow: 8px 16px 60px rgb(0 0 0 / 8%);
  border-radius: 16px;
  margin-top: 1rem;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
