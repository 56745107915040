import { Pair } from '@ambidex/sdk'
import { Currency, TokenAmount, Token } from '@uniswap/sdk-core'
import { useEffect, useMemo, useState } from 'react'
import { CURRENT_CHAIN_ID, LP_TOKEN_DECIMAL } from '../constants'
import useConnector from './useConnector'
import { BigNumber } from 'ethers'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function useGetReservesWithSwapFee(tokens: (Token | undefined)[][]) {
  const connector = useConnector()
  const [reserves, setReserves] = useState<
    {
      result: any
      loading: boolean
    }[]
  >(
    tokens.map(() => ({
      result: undefined,
      loading: true,
    }))
  )

  useEffect(() => {
    Promise.all(
      tokens.map(async ([tokenA, tokenB]) => {
        if (tokenA && tokenB) {
          try {
            const [reserves, swapFee] = await Promise.all([
              connector?.getPairReserves(tokenA, tokenB),
              connector?.getPairSwapFee(tokenA, tokenB),
            ])
            return { result: { reserves, swapFee }, loading: false }
          } catch (err: any) {
            console.log(err)
          }
          // return default swap fee
          return {
            result: {
              reserves: { result: { reserve0: BigNumber.from(0), reserve1: BigNumber.from(0) }, loading: false },
              swapFee: { result: { swapFee: 3 }, loading: false },
            },
            loading: false,
          }
        }
        return { result: undefined, loading: false }
      })
    ).then((newReserves) => {
      if (JSON.stringify(reserves) !== JSON.stringify(newReserves)) setReserves(newReserves)
    })
  }, [tokens])

  return useMemo(() => {
    return reserves
  }, [reserves])
}

export function useV2Pairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const tokens = useMemo(() => currencies.map(([currencyA, currencyB]) => [currencyA as Token, currencyB as Token]), [
    currencies,
  ])
  const results = useGetReservesWithSwapFee(tokens)
  return useMemo(() => {
    return results.map((result, i) => {
      if (!tokens[i]) return [PairState.NOT_EXISTS, null]
      const { result: reservesWithSwapFee, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reservesWithSwapFee) return [PairState.NOT_EXISTS, null]
      if (!reservesWithSwapFee.reserves.result || !reservesWithSwapFee.swapFee.result)
        return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reservesWithSwapFee.reserves.result
      const { swapFee } = reservesWithSwapFee.swapFee.result
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()), swapFee),
      ]
    })
  }, [results, tokens])
}

export function useV2LiquidityToken(pair: Pair | null): Token | undefined {
  const [token, setToken] = useState<Token | undefined>(pair?.liquidityToken)
  const connector = useConnector()

  useEffect(() => {
    if (pair?.token0.address && pair?.token1.address) {
      connector?.getPairTokenAddr(pair?.token0 as Token, pair?.token1 as Token).then((addr) => {
        setToken(new Token(CURRENT_CHAIN_ID, addr, LP_TOKEN_DECIMAL))
      })
    }
  }, [pair])

  return useMemo(() => token, [token])
}

export function useV2Pair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const inputs: [[Currency | undefined, Currency | undefined]] = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return useV2Pairs(inputs)[0]
}
