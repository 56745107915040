import { Currency, CurrencyAmount, currencyEquals, Price, Token } from '@uniswap/sdk-core'
import { JSBI } from '@ambidex/sdk'
import { useMemo } from 'react'
import { USDC } from '../constants'
import { PairState, useV2Pairs } from './useV2Pairs'
import { useWalletState } from '../state/wallet/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { CURRENT_CHAIN_ID, WHBAR_ADDRESSES, HBAR_DECIMAL } from 'constants/index'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
const HBAR = new Token(CURRENT_CHAIN_ID, WHBAR_ADDRESSES[CURRENT_CHAIN_ID], HBAR_DECIMAL, 'HBAR', 'Wrapped HBAR')

export default function useUSDCPrice(currency?: Currency): Price | undefined {
  const { chainId } = useWalletState()
  const wrapped = wrappedCurrency(currency, chainId)
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(() => {
    if (currency) {
      return [
        [chainId && wrapped && currencyEquals(HBAR, wrapped) ? undefined : currency, chainId ? HBAR : undefined],
        [wrapped?.equals(USDC) ? undefined : wrapped, USDC],
        [HBAR, USDC],
      ]
    }
    return [
      [undefined, undefined],
      [undefined, undefined],
      [undefined, undefined],
    ]
  }, [chainId, currency, wrapped])

  const [[hbarPairState, hbarPair], [usdcPairState, usdcPair], [usdcHbarPairState, usdcHbarPair]] = useV2Pairs(
    tokenPairs
  )

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    if (wrapped.equals(HBAR)) {
      if (usdcPair) {
        const price = usdcPair.priceOf(HBAR)
        if (price.equalTo(0)) return undefined
        return new Price(currency, USDC, price.denominator, price.numerator)
      } else {
        return undefined
      }
    }
    // handle usdc
    if (wrapped.equals(USDC)) {
      return new Price(USDC, USDC, '1', '1')
    }

    const hbarPairHBARAmount = hbarPair?.reserveOf(HBAR)
    const hbarPairHBARUSDCValue: JSBI =
      hbarPairHBARAmount && usdcHbarPair
        ? !hbarPairHBARAmount.equalTo(0)
          ? usdcHbarPair.priceOf(HBAR).quote(hbarPairHBARAmount).raw
          : JSBI.BigInt(0)
        : JSBI.BigInt(0)

    // all other tokens
    // first try the usdc pair
    if (usdcPairState === PairState.EXISTS && usdcPair && usdcPair.reserveOf(USDC).greaterThan(hbarPairHBARUSDCValue)) {
      const price = usdcPair.priceOf(wrapped)
      return new Price(currency, USDC, price.denominator, price.numerator)
    }
    if (hbarPairState === PairState.EXISTS && hbarPair && usdcHbarPairState === PairState.EXISTS && usdcHbarPair) {
      if (usdcHbarPair.reserveOf(USDC).greaterThan('0') && hbarPair.reserveOf(HBAR).greaterThan('0')) {
        const hbarUsdcPrice = usdcHbarPair.priceOf(USDC)
        const currencyEthPrice = hbarPair.priceOf(HBAR)
        const usdcPrice = hbarUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, USDC, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, currency, hbarPair, hbarPairState, usdcHbarPair, usdcHbarPairState, usdcPair, usdcPairState, wrapped])
}

export function useUSDCValue(currencyAmount: CurrencyAmount | undefined | null) {
  const price = useUSDCPrice(currencyAmount?.currency)
  return useMemo(() => {
    if (!price || !currencyAmount) return null
    return price.quote(currencyAmount)
  }, [currencyAmount, price])
}
