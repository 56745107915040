import React, { useCallback, useContext, useMemo, useState } from 'react'
import { currencyEquals, ETHER, Percent, WETH9 } from '@uniswap/sdk-core'
import ReactGA from 'react-ga4'
import { RouteComponentProps } from 'react-router'
import { Flex, Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useWalletState } from 'state/wallet/hooks'
import { ButtonPrimary, ButtonLight, ButtonError } from '../../components/Button'
import { GradientCard, CircleGradientCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'

import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useCurrency } from '../../hooks/Tokens'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'

import { StyledInternalLink, TYPE } from '../../theme'
import { calculateSlippageAmount, getLpTokenSymbol } from '../../utils'
import useDebouncedChangeHandler from '../../hooks/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { Wrapper } from '../Pool/styleds'
import { useBurnActionHandlers } from '../../state/burn/hooks'
import { useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'
import { Field } from '../../state/burn/actions'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import PageHeader from '../../components/Header/PageHeader'
import { AppWrapper, CenterredCardAppWrapper } from 'pages/styled'
import useCredentialTokens from 'hooks/useCredentialTokens'
import { DEX_CREDENTIAL_TOKEN_METADATAS } from 'constants/index'
import { WarningMessage } from 'components/Message'
import { AmbidexChainId } from 'constants/networks'
import useConnector from 'hooks/useConnector'

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  font-size: 16px;
  width: 100%;
  min-width: 100px;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.bg1};
`
const Option = styled(FancyButton)`
  :hover {
    cursor: pointer;
  }
  height: 56px;
  padding: 0px;
  background-color: #fff;
  color: ${({ theme }) => theme.text1};
  border-radius: none;
  &:focus {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.white};
    border-radius: 8px;
  }
`
const PercentageOptionsContainer = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 4px;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 10px;
`

export default function RemoveLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId } = useWalletState()
  const connector = useConnector()
  const [tokenA, tokenB] = useMemo(() => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)], [
    currencyA,
    currencyB,
    chainId,
  ])

  const theme = useContext(ThemeContext)

  // credential token
  const credentialTokens = useCredentialTokens()
  const dexCredentialToken = credentialTokens?.find(
    (token) => token.metadata === DEX_CREDENTIAL_TOKEN_METADATAS[chainId]
  )

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  async function onRemove() {
    if (!chainId || !account || !deadline || !connector) throw new Error('missing dependencies')
    if (!dexCredentialToken) throw new Error('missing credential token')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsHBAR = currencyB.symbol === 'HBAR'
    const oneCurrencyIsHBAR = currencyA.symbol === 'HBAR' || currencyBIsHBAR

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    const { serial_number } = dexCredentialToken
    let args: Array<string | string[] | number | boolean>
    if (oneCurrencyIsHBAR) {
      args = [
        currencyBIsHBAR ? tokenA.address : tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[currencyBIsHBAR ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
        amountsMin[currencyBIsHBAR ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
        account,
        deadline.toHexString(),
      ]
    } else {
      args = [
        tokenA.address,
        tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
    }

    setAttemptingTxn(true)
    await (oneCurrencyIsHBAR
      ? connector.removeLiquidityHBAR(args, serial_number)
      : connector.removeLiquidity(args, serial_number)
    )
      .then((txId) => {
        setAttemptingTxn(false)

        // addTransaction(response, {
        //   type: TransactionType.REMOVE_LIQUIDITY_V3,
        //   baseCurrencyId: currencyId(currencyA),
        //   quoteCurrencyId: currencyId(currencyB),
        //   expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
        //   expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
        // })
        setTxHash(txId) // TODO: Should replace real hash from hashscan.io

        ReactGA.event({
          category: 'Liquidity',
          action: 'Remove',
          label: [currencyA.symbol, currencyB.symbol].join('/'),
        })
      })
      .catch((error: any) => {
        const txId = error.message
        ReactGA.event({
          category: 'Error',
          action: 'Error: remove liquidity',
          label: `${currencyA.symbol}/${currencyB.symbol} - ${txId}`,
        })
        setAttemptingTxn(false)
        console.error(error)
      })
  }

  function modalHeader() {
    return (
      <div style={{ padding: '40px 24px 0 24px' }}>
        <CircleGradientCard>
          <AutoColumn gap="10px" style={{ padding: '10px 0px' }}>
            <Row>
              <AutoColumn style={{ width: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.08)' }} gap="10px">
                <Text fontSize={20} fontWeight={600} color={'#27292C'} textAlign="center">
                  {formattedAmounts[Field.CURRENCY_A] || '-'}
                </Text>
                <Row justify="center">
                  <CurrencyLogo currency={currencyA} />
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    id="remove-liquidity-tokena-symbol"
                    color={'#27292C'}
                    opacity="0.6"
                    style={{ marginLeft: '8px' }}
                  >
                    {currencyA?.symbol}
                  </Text>
                </Row>
              </AutoColumn>
              <AutoColumn style={{ width: '100%' }} gap="10px">
                <Text fontSize={20} fontWeight={600} color={'#27292C'} textAlign="center">
                  {formattedAmounts[Field.CURRENCY_B] || '-'}
                </Text>
                <Row justify="center">
                  <CurrencyLogo currency={currencyB} />
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    id="remove-liquidity-tokenb-symbol"
                    color={'#27292C'}
                    opacity="0.6"
                    style={{ marginLeft: '8px' }}
                  >
                    {currencyB?.symbol}
                  </Text>
                </Row>
              </AutoColumn>
            </Row>
          </AutoColumn>
        </CircleGradientCard>
      </div>
    )
  }

  function modalBottom() {
    return (
      <div style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '28px' }}>
        <GradientCard style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <RowBetween>
            <Text color={theme.text2} fontWeight={500} fontSize={14}>
              {getLpTokenSymbol(currencyA, currencyB)} Burned
            </Text>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
              <Text fontWeight={600} fontSize={14} paddingLeft={15}>
                {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
              </Text>
            </RowFixed>
          </RowBetween>
          {pair && (
            <>
              <RowBetween>
                <Text color={theme.text2} fontWeight={500} fontSize={14}>
                  Price
                </Text>
                <Text fontWeight={600} fontSize={14} color={theme.text1}>
                  1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                </Text>
              </RowBetween>
              <RowBetween>
                <div />
                <Text fontWeight={600} fontSize={14} color={theme.text1}>
                  1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                </Text>
              </RowBetween>
            </>
          )}
        </GradientCard>
        <Row justify="center">
          <TYPE.italic fontSize={14} textAlign="center" maxWidth={376}>
            {`Output is estimated. If the price changes by more than ${allowedSlippage.toSignificant(
              4
            )}% your transaction will revert.`}
          </TYPE.italic>
        </Row>
        <ButtonPrimary onClick={onRemove} style={{ backgroundColor: '#6437E7' }}>
          <Text fontWeight={500} fontSize={20}>
            Confirm
          </Text>
        </ButtonPrimary>
      </div>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  const oneCurrencyIsWETH = false
  // const oneCurrencyIsWETH = Boolean(
  //   chainId &&
  //     ((currencyA && currencyEquals(WETH9[chainId], currencyA)) ||
  //       (currencyB && currencyEquals(WETH9[chainId], currencyB)))
  // )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  if (!credentialTokens) return <></>

  return (
    <>
      <PageHeader />
      <AppWrapper>
        <AppBody>
          <CenterredCardAppWrapper>
            <AddRemoveTabs creating={false} adding={false} />
            <Wrapper>
              <TransactionConfirmationModal
                isOpen={showConfirm}
                onDismiss={handleDismissConfirmation}
                attemptingTxn={attemptingTxn}
                hash={txHash ? txHash : ''}
                content={() => (
                  <ConfirmationModalContent
                    title={'You will receive'}
                    onDismiss={handleDismissConfirmation}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )}
                pendingText={pendingText}
              />
              <AutoColumn gap="20px">
                <ColumnCenter style={{ maxWidth: '480px' }}>
                  <GradientCard>
                    <TYPE.link fontWeight={400} color="#27292C" paddingX="8px" paddingY="2px" lineHeight="24px">
                      <b>Tip:</b> Removing pool tokens converts your position back into underlying tokens at the current
                      rate, proportional to your share of the pool. Accrued fees are included in the amounts you
                      receive.
                    </TYPE.link>
                  </GradientCard>
                </ColumnCenter>
                <AutoColumn gap="10px">
                  <Text color="#00000099" textAlign="center">
                    Amount
                  </Text>
                  <Text fontSize={48} fontWeight={600} color="#27292C" textAlign="center">
                    {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                  </Text>
                </AutoColumn>
                <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} size={20} />
                <CircleGradientCard>
                  <AutoColumn gap="10px" style={{ padding: '10px 0px' }}>
                    <Row>
                      <AutoColumn style={{ width: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.08)' }} gap="10px">
                        <Text fontSize={20} fontWeight={600} color={'#27292C'} textAlign="center">
                          {formattedAmounts[Field.CURRENCY_A] || '-'}
                        </Text>
                        <Row justify="center">
                          <CurrencyLogo currency={currencyA} />
                          <Text
                            fontSize={14}
                            fontWeight={500}
                            id="remove-liquidity-tokena-symbol"
                            color={'#27292C'}
                            opacity="0.6"
                            style={{ marginLeft: '8px' }}
                          >
                            {currencyA?.symbol}
                          </Text>
                        </Row>
                      </AutoColumn>
                      <AutoColumn style={{ width: '100%' }} gap="10px">
                        <Text fontSize={20} fontWeight={600} color={'#27292C'} textAlign="center">
                          {formattedAmounts[Field.CURRENCY_B] || '-'}
                        </Text>
                        <Row justify="center">
                          <CurrencyLogo currency={currencyB} />
                          <Text
                            fontSize={14}
                            fontWeight={500}
                            id="remove-liquidity-tokenb-symbol"
                            color={'#27292C'}
                            opacity="0.6"
                            style={{ marginLeft: '8px' }}
                          >
                            {currencyB?.symbol}
                          </Text>
                        </Row>
                      </AutoColumn>
                    </Row>
                    {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                      <RowBetween style={{ justifyContent: 'flex-end' }}>
                        {oneCurrencyIsETH ? (
                          <StyledInternalLink
                            to={`/remove/v2/${
                              currencyA === ETHER ? WETH9[AmbidexChainId.MAINNET].address : currencyIdA
                            }/${currencyB === ETHER ? WETH9[AmbidexChainId.MAINNET].address : currencyIdB}`}
                          >
                            Receive WETH
                          </StyledInternalLink>
                        ) : oneCurrencyIsWETH ? (
                          <StyledInternalLink
                            to={`/remove/v2/${
                              currencyA && currencyEquals(currencyA, WETH9[AmbidexChainId.MAINNET])
                                ? 'ETH'
                                : currencyIdA
                            }/${
                              currencyB && currencyEquals(currencyB, WETH9[AmbidexChainId.MAINNET])
                                ? 'ETH'
                                : currencyIdB
                            }`}
                          >
                            Receive ETH
                          </StyledInternalLink>
                        ) : null}
                      </RowBetween>
                    ) : null}
                  </AutoColumn>
                </CircleGradientCard>
                {pair && (
                  <GradientCard>
                    <RowBetween>
                      <Text fontSize={14}>Price</Text>
                      <Text fontSize={14} fontWeight={600}>
                        1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                        {currencyB?.symbol}
                      </Text>
                    </RowBetween>
                    <RowBetween>
                      <div />
                      <Text fontSize={14} fontWeight={600} paddingTop="5px">
                        1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                        {currencyA?.symbol}
                      </Text>
                    </RowBetween>
                  </GradientCard>
                )}
                <div style={{ position: 'relative' }}>
                  {!account ? (
                    <ButtonLight onClick={toggleWalletModal} style={{ backgroundColor: '#63dada' }}>
                      Connect Wallet
                    </ButtonLight>
                  ) : (
                    <RowBetween>
                      <ButtonError
                        onClick={() => {
                          setShowConfirm(true)
                        }}
                        disabled={!isValid || !dexCredentialToken}
                        error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                        style={{ backgroundColor: '#6437E7' }}
                      >
                        <Text fontSize={16} fontWeight={500}>
                          {error || 'Remove'}
                        </Text>
                      </ButtonError>
                    </RowBetween>
                  )}
                </div>
              </AutoColumn>
            </Wrapper>
          </CenterredCardAppWrapper>
        </AppBody>
        {!dexCredentialToken && (
          <Flex justifyContent="center" mt="40px" mb="20px">
            <WarningMessage text="You do not have the required credential token." />
          </Flex>
        )}
        {pair ? (
          <AppBody>
            <AutoColumn style={{ padding: '20px' }}>
              <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
            </AutoColumn>
          </AppBody>
        ) : null}
      </AppWrapper>
    </>
  )
}
