import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount } from '@uniswap/sdk-core'
import { useTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { Pair } from '@ambidex/sdk'
import { useEffect, useMemo, useState } from 'react'
import JSBI from 'jsbi'
import { CURRENT_CHAIN_ID, LP_TOKEN_DECIMAL } from '../constants/index'
import useConnector from './useConnector'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched

//TODO: Should get the total supply from mirror Node API in the future
export function usePairTotalSupply(pair: Pair | null): TokenAmount | undefined {
  const [supply, setSupply] = useState<TokenAmount | undefined>(undefined)
  const connector = useConnector()

  useEffect(() => {
    if (pair) {
      connector
        ?.getPairSupply(pair.token0.address, pair.token1.address)
        .then(({ supply }) => {
          setSupply(
            new TokenAmount(
              new Token(CURRENT_CHAIN_ID, pair.liquidityToken.address, LP_TOKEN_DECIMAL),
              JSBI.BigInt(supply)
            )
          )
        })
        .catch(() => {
          setSupply(
            new TokenAmount(new Token(CURRENT_CHAIN_ID, pair.liquidityToken.address, LP_TOKEN_DECIMAL), JSBI.BigInt(0))
          )
        })
    }
  }, [pair])
  return useMemo(() => supply, [supply])
}

export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}
