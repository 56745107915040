import { JSBI } from '@ambidex/sdk'
import {
  AccountBalanceQuery,
  AccountId,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  ContractId,
  Hbar,
  TokenAssociateTransaction,
  TokenId,
} from '@hashgraph/sdk'
import { Token } from '@uniswap/sdk-core'
import {
  CREDENTIAL_TOKEN_IDS,
  CURRENT_CHAIN_ID,
  ERROR_MESSAGE,
  GAS_ADD_LIQUIDITY_EXISTING_HBAR_PAIR,
  GAS_ADD_LIQUIDITY_EXISTING_PAIR,
  GAS_ADD_LIQUIDITY_NEW_PAIR,
  GAS_CREATE_PAIR,
  GAS_LOAN_ACTION,
  GAS_LOAN_STAKING_ACTION,
  GAS_REMOVE_LIQUIDITY,
  GAS_REMOVE_LIQUIDITY_HBAR,
  GAS_SWAP_HBAR,
  GAS_SWAP_NON_HBAR,
  HBAR_DECIMAL,
  MASTERCHEF_ADDRESSES,
  RESERVE_CACHE_DURATION,
  USD_AMOUNT_CONTRACT_CREATE,
  V2_FACTORY_CONTRACT_IDS,
  V2_ROUTER_CONTRACT_IDS,
  WHBAR_ADDRESSES,
  ZERO_ADDRESS,
} from 'constants/index'
import { AmbidexChainId } from 'constants/networks'
import { BigNumber } from 'ethers'
import Long from 'long'
import invariant from 'tiny-invariant'
import { getExchangeRate, getNfts } from 'utils/hederaMirror'
import { getPairAddress, getTokenId } from 'utils/hederaUtils'
import { pairProxyCall, tokenInfoProxyCall } from 'utils/queryProxy'

export interface NetworkConnectorArguments {
  urls: { [chainId: number]: string }
  defaultChainId?: number
}

// taken from ethers.js, compatible interface with web3 provider
type AsyncSendable = {
  isMetaMask?: boolean
  host?: string
  path?: string
  sendAsync?: (request: any, callback: (error: any, response: any) => void) => void
  send?: (request: any, callback: (error: any, response: any) => void) => void
}

class RequestError extends Error {
  constructor(message: string, public code: number, public data?: unknown) {
    super(message)
  }
}

interface BatchItem {
  request: { jsonrpc: '2.0'; id: number; method: string; params: unknown }
  resolve: (result: any) => void
  reject: (error: Error) => void
}

export class MiniRpcProvider implements AsyncSendable {
  public readonly isMetaMask: false = false
  public readonly chainId: number
  public readonly url: string
  public readonly host: string
  public readonly path: string
  public readonly batchWaitTimeMs: number

  private nextId = 1
  private batchTimeoutId: ReturnType<typeof setTimeout> | null = null
  private batch: BatchItem[] = []

  constructor(chainId: number, url: string, batchWaitTimeMs?: number) {
    this.chainId = chainId
    this.url = url
    const parsed = new URL(url)
    this.host = parsed.host
    this.path = parsed.pathname
    // how long to wait to batch calls
    this.batchWaitTimeMs = batchWaitTimeMs ?? 50
  }

  public readonly clearBatch = async () => {
    console.debug('Clearing batch', this.batch)
    const batch = this.batch
    this.batch = []
    this.batchTimeoutId = null
    let response: Response
    try {
      response = await fetch(this.url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', accept: 'application/json' },
        body: JSON.stringify(batch.map((item) => item.request)),
      })
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to send batch call')))
      return
    }

    if (!response.ok) {
      batch.forEach(({ reject }) => reject(new RequestError(`${response.status}: ${response.statusText}`, -32000)))
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to parse JSON response')))
      return
    }
    const byKey = batch.reduce<{ [id: number]: BatchItem }>((memo, current) => {
      memo[current.request.id] = current
      return memo
    }, {})
    for (const result of json) {
      const {
        resolve,
        reject,
        request: { method },
      } = byKey[result.id]
      if ('error' in result) {
        reject(new RequestError(result?.error?.message, result?.error?.code, result?.error?.data))
      } else if ('result' in result && resolve) {
        resolve(result.result)
      } else {
        reject(new RequestError(`Received unexpected JSON-RPC response to ${method} request.`, -32000, result))
      }
    }
  }

  public readonly sendAsync = (
    request: {
      jsonrpc: '2.0'
      id: number | string | null
      method: string
      params?: unknown[] | Record<string, unknown>
    },
    callback: (error: any, response: any) => void
  ): void => {
    this.request(request.method, request.params)
      .then((result) => callback(null, { jsonrpc: '2.0', id: request.id, result }))
      .catch((error) => callback(error, null))
  }

  public readonly request = async (
    method: string | { method: string; params: unknown[] },
    params?: unknown[] | Record<string, unknown>
  ): Promise<unknown> => {
    if (typeof method !== 'string') {
      return this.request(method.method, method.params)
    }
    if (method === 'eth_chainId') {
      return `0x${this.chainId.toString(16)}`
    }
    const promise = new Promise((resolve, reject) => {
      this.batch.push({
        request: {
          jsonrpc: '2.0',
          id: this.nextId++,
          method,
          params,
        },
        resolve,
        reject,
      })
    })
    this.batchTimeoutId = this.batchTimeoutId ?? setTimeout(this.clearBatch, this.batchWaitTimeMs)
    return promise
  }
}

export type MasterChefArgs = {
  poolId: number
  account: string
  amount?: any
}

export type LoanActionMethod = 'purchaseLoan' | 'refundLoan' | 'redeemLoan' | 'redeemAll'

export type LoanActionArgs = {
  account: string
  amount?: any
  serialNumber: any
}

export type LoanStakingActionMethod = 'stake' | 'unstake' | 'claimReward'

export type LoanStakingActionArgs = {
  account: string
  amount?: any
  serialNumber: any
}

export class NetworkConnector {
  private readonly providers: { [chainId: number]: MiniRpcProvider }
  private currentChainId: number

  lpTokens: Record<string, string>
  reserveCache: Record<string, { data: { reserve0: BigNumber; reserve1: BigNumber }; expireAt: number }>
  localPairs: Record<string, Array<[string, string]>>
  swapFeeCache: Record<string, { data: { swapFee: number } }>

  constructor({ urls, defaultChainId }: NetworkConnectorArguments) {
    invariant(defaultChainId || Object.keys(urls).length === 1, 'defaultChainId is a required argument with >1 url')

    this.currentChainId = defaultChainId || Number(Object.keys(urls)[0])
    this.providers = Object.keys(urls).reduce<{ [chainId: number]: MiniRpcProvider }>((accumulator, chainId) => {
      accumulator[Number(chainId)] = new MiniRpcProvider(Number(chainId), urls[Number(chainId)])
      return accumulator
    }, {})

    this.lpTokens = {}
    this.reserveCache = {}
    this.swapFeeCache = {}
    this.localPairs = {}
    const localPairs = localStorage.getItem('ambidexPairs')
    if (localPairs) {
      this.localPairs = JSON.parse(localPairs)
    }
  }

  public get provider(): MiniRpcProvider {
    return this.providers[this.currentChainId]
  }

  public async activate(): Promise<any> {
    return { provider: this.providers[this.currentChainId], chainId: this.currentChainId, account: null }
  }

  public async getProvider(): Promise<MiniRpcProvider> {
    return this.providers[this.currentChainId]
  }

  public async getProviderClient(): Promise<any> {
    return undefined
  }

  public async getChainId(): Promise<number> {
    return this.currentChainId
  }

  public async getAccount(): Promise<null> {
    return null
  }

  public deactivate() {
    return
  }

  /**
   * The returned reserve values might be in a different order than the input tokens, e.g. (tokenA, tokenB) might return (reserveB, reserveA) or (reserveA, reserveB)
   */
  public async getPairReserves(tokenA?: Token, tokenB?: Token) {
    try {
      if (!tokenA || !tokenB) {
        throw new Error('Invalid input tokens')
      }

      console.log(`getPairReserves: ${tokenA.symbol}:${tokenB.symbol}`)

      const addr = await getPairAddress(tokenA.address, tokenB.address)

      // Return cached data if exists & not expired
      const cachedReserve = this.reserveCache[addr]
      if (cachedReserve && new Date().getTime() <= cachedReserve.expireAt) {
        console.log(`Using reserve data from cache for the pair - ${tokenA.symbol}:${tokenB.symbol}`)
        return {
          result: cachedReserve.data,
          loading: false,
        }
      }

      const [reserve0, reserve1] = await pairProxyCall(addr, 'getReserves', []).catch((err) => {
        if (err.response.status === 500 && err.response.data.message === ERROR_MESSAGE.INVALID_CONTRACT) {
          // Cache reserve data with 0,0
          return [{ hex: 0 }, { hex: 0 }]
        }
        throw err
      })

      console.log(`reserve values for ${tokenA.symbol}:${tokenB.symbol}: ${reserve0.hex} -- ${reserve1.hex}`)

      const reserve = {
        result: {
          reserve0: BigNumber.from(reserve0.hex),
          reserve1: BigNumber.from(reserve1.hex),
        },
        loading: false,
      }
      // Set cache data
      this.reserveCache[addr] = {
        data: reserve.result,
        expireAt: new Date().getTime() + RESERVE_CACHE_DURATION,
      }

      return reserve
    } catch (err) {
      console.log('getPairReserves error', err)
      return {
        result: undefined,
        loading: false,
      }
    }
  }

  public async getTokenBalances(
    accountId: string | undefined
  ): Promise<{
    hbar: Long.Long | undefined
    tokens: Map<string, Long.Long> | undefined
    tokenDecimals: Map<string, number> | undefined
  }> {
    if (accountId) {
      try {
        const client = await this.getProviderClient()
        const query = new AccountBalanceQuery().setAccountId(accountId)
        const accountBalance = await query.execute(client)

        return {
          hbar: accountBalance.hbars.toTinybars(),
          tokens: accountBalance.tokens?._map,
          tokenDecimals: accountBalance.tokenDecimals?._map,
        }
      } catch {
        return {
          hbar: undefined,
          tokens: undefined,
          tokenDecimals: undefined,
        }
      }
    }
    return {
      hbar: undefined,
      tokens: undefined,
      tokenDecimals: undefined,
    }
  }

  public async getPairBalanceState(account: string | undefined, tokenA: Token, tokenB: Token) {
    // Returns pairState(true if exists, else if not) and lpTokenBalanceState(true if exists, else if not)
    const { result } = await this.getPairReserves(tokenA, tokenB)
    if (!result || result.reserve0.eq(BigNumber.from('0'))) {
      return [false, false]
    }
    const lpToken = await this.getPairTokenAddr(tokenA, tokenB)
    const lpTokenId = getTokenId(lpToken)
    const { tokens } = await this.getTokenBalances(account)
    if (tokens && lpTokenId && tokens?.get(lpTokenId) && !tokens?.get(lpTokenId)?.equals(Long.ZERO)) {
      return [true, true]
    }
    return [true, false]
  }

  public async getPairSwapFee(tokenA?: Token, tokenB?: Token) {
    try {
      if (!tokenA || !tokenB) {
        throw new Error('Invalid input tokens')
      }

      console.log(`getPairSwapFee: ${tokenA.symbol}:${tokenB.symbol}`)

      const addr = await getPairAddress(tokenA.address, tokenB.address)

      // Return cached data if exists & not expired
      const cachedReserve = this.swapFeeCache[addr]
      if (cachedReserve) {
        console.log(`Using swap fee from cache for the pair - ${tokenA.symbol}:${tokenB.symbol}`)
        return {
          result: cachedReserve.data,
          loading: false,
        }
      }

      const [swapFeeData] = await pairProxyCall(addr, 'swapFee', []).catch((err) => {
        if (err.response.status === 500 && err.response.data.message === ERROR_MESSAGE.INVALID_CONTRACT) {
          // Cache default swap fee (0.3%)
          return [{ hex: '0x03' }]
        }
        throw err
      })
      const swapFeeBN = BigNumber.from(swapFeeData.hex)
      console.log(`swap fee for ${tokenA.symbol}:${tokenB.symbol}: ${swapFeeBN.toNumber()}`)

      const swapFee = {
        result: {
          swapFee: swapFeeBN.toNumber(),
        },
        loading: false,
      }
      // Set cache data
      this.swapFeeCache[addr] = {
        data: swapFee.result,
      }

      return swapFee
    } catch (err) {
      console.log('getPairSwapFee error', err)
      return {
        result: undefined,
        loading: false,
      }
    }
  }

  public async getLpToken(addr: string) {
    if (this.lpTokens[addr]) {
      return this.lpTokens[addr]
    }

    const [lpToken] = await pairProxyCall(addr, 'lpToken', []).catch((err) => {
      if (err?.response?.status === 500 && err?.response?.data?.message === ERROR_MESSAGE.INVALID_CONTRACT) {
        this.lpTokens[addr] = ZERO_ADDRESS
      }
      return [ZERO_ADDRESS]
    })

    if (lpToken !== ZERO_ADDRESS) {
      this.lpTokens[addr] = lpToken
    }

    return lpToken
  }

  public async getPairSupply(tokenA: string, tokenB: string) {
    const pairAddr = await getPairAddress(tokenA, tokenB)
    const lpTokenAddr = await this.getLpToken(pairAddr)
    const { totalSupply } = await tokenInfoProxyCall(TokenId.fromSolidityAddress(lpTokenAddr).toString())
    return {
      addr: pairAddr,
      supply: BigNumber.from(totalSupply),
    }
  }

  public async getPairTokenAddr(tokenA: Token, tokenB: Token): Promise<string> {
    if (!tokenA || !tokenB) {
      return ZERO_ADDRESS
    }
    console.log(`getPairTokenAddr: ${tokenA.symbol}:${tokenB.symbol}`)
    const pairAddr = await getPairAddress(tokenA.address, tokenB.address)
    const lpToken = await this.getLpToken(pairAddr)
    return lpToken
  }

  public getLocalPairs(account: string) {
    if (this.localPairs[account]) {
      return this.localPairs[account]
    } else {
      return []
    }
  }

  public saveLocalPair(account: string, tokenA: string, tokenB: string) {
    if (this.localPairs[account]) {
      const accountPairs = this.localPairs[account]
      if (
        !accountPairs.find(
          ([token1, token2]) => (token1 === tokenA && token2 === tokenB) || (token2 === tokenA && token1 === tokenB)
        )
      ) {
        this.localPairs[account] = [...accountPairs, [tokenA, tokenB]]
      }
    } else {
      this.localPairs[account] = [[tokenA, tokenB]]
    }
    const data = JSON.stringify(this.localPairs)
    localStorage.setItem('ambidexPairs', data)
  }

  public removeReserveCache() {
    this.reserveCache = {}
  }

  public async deleteLpToken(token1: string, token2?: string): Promise<void> {
    const address2 = token2 ?? WHBAR_ADDRESSES[CURRENT_CHAIN_ID]
    const pairAddr = await getPairAddress(token1, address2)
    this.deleteLpToken(pairAddr)
  }

  public async getCreatePairTx(
    token1: string,
    account: string,
    token2?: string
  ): Promise<ContractExecuteTransaction | null> {
    const address2 = token2 ?? WHBAR_ADDRESSES[CURRENT_CHAIN_ID]
    const pairAddr = await getPairAddress(token1, address2)
    const lpToken = await this.getLpToken(pairAddr)
    if (lpToken !== ZERO_ADDRESS) {
      return null
    }

    const exchangeRate = await getExchangeRate()
    const tx = new ContractExecuteTransaction()
      .setContractId(V2_FACTORY_CONTRACT_IDS[CURRENT_CHAIN_ID])
      .setGas(GAS_CREATE_PAIR)
      .setFunction('createPair', new ContractFunctionParameters().addAddress(token1).addAddress(address2))
      .setPayableAmount(new Hbar(Math.ceil(USD_AMOUNT_CONTRACT_CREATE / exchangeRate)))

    return tx
  }

  public getAddLiquidityHBARTx(args: any, serialNumber: any): ContractExecuteTransaction {
    const [tokenAddr, tokenAmount, minTokenAmount, minHbarAmount, account, deadline] = args
    const tx = new ContractExecuteTransaction()
      .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
      .setGas(GAS_ADD_LIQUIDITY_EXISTING_HBAR_PAIR)
      .setFunction(
        'addLiquidityHBAR',
        new ContractFunctionParameters()
          .addAddress(tokenAddr)
          .addUint256(tokenAmount)
          .addUint256(0) // TODO: Should replace with exact min amount
          .addUint256(minHbarAmount)
          .addAddress(AccountId.fromString(account).toSolidityAddress())
          .addUint256(deadline)
          .addInt64(serialNumber)
      )
      .setPayableAmount(
        new Hbar(JSBI.divide(JSBI.BigInt(minHbarAmount), JSBI.BigInt(Math.pow(10, HBAR_DECIMAL).toString())).toString())
      )

    return tx
  }

  public async getAddLiquidityTx(args: any, serialNumber: any): Promise<ContractExecuteTransaction> {
    const [
      token1Addr,
      token2Addr,
      token1Amount,
      token2Amount,
      minToken1Amount,
      minToken2Amount,
      account,
      deadline,
    ] = args

    const pairAddr = await getPairAddress(token1Addr, token2Addr)
    const lpToken = await this.getLpToken(pairAddr)
    const exchangeRate = await getExchangeRate()
    const pairExists = lpToken !== ZERO_ADDRESS

    const tx = new ContractExecuteTransaction()
      .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
      .setGas(pairExists ? GAS_ADD_LIQUIDITY_EXISTING_PAIR : GAS_ADD_LIQUIDITY_NEW_PAIR)
      .setFunction(
        'addLiquidity',
        new ContractFunctionParameters()
          .addAddress(token1Addr)
          .addAddress(token2Addr)
          .addUint256(token1Amount)
          .addUint256(token2Amount)
          .addUint256(minToken1Amount)
          .addUint256(minToken2Amount)
          .addAddress(AccountId.fromString(account).toSolidityAddress())
          .addUint256(deadline)
          .addInt64(serialNumber)
      )
      .setPayableAmount(new Hbar(pairExists ? 0 : Math.ceil(USD_AMOUNT_CONTRACT_CREATE / exchangeRate)))

    return tx
  }

  public getRemoveLiquidityHBARTx(args: any, serialNumber: any): ContractExecuteTransaction {
    const [tokenAddr, liquidityAmount, minTokenAmount, minHbarAmount, account, deadline] = args
    const tx = new ContractExecuteTransaction()
      .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
      .setGas(GAS_REMOVE_LIQUIDITY_HBAR)
      .setFunction(
        'removeLiquidityHBAR',
        new ContractFunctionParameters()
          .addAddress(tokenAddr)
          .addUint256(liquidityAmount)
          .addUint256(minTokenAmount)
          .addUint256(minHbarAmount)
          .addAddress(AccountId.fromString(account).toSolidityAddress())
          .addUint256(deadline)
          .addInt64(serialNumber)
      )

    return tx
  }

  public getRemoveLiquidityTx(args: any, serialNumber: any): ContractExecuteTransaction {
    const [token1Addr, token2Addr, liquidityAmount, minToken1Amount, minToken2Amount, account, deadline] = args
    const tx = new ContractExecuteTransaction()
      .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
      .setGas(GAS_REMOVE_LIQUIDITY)
      .setFunction(
        'removeLiquidity',
        new ContractFunctionParameters()
          .addAddress(token1Addr)
          .addAddress(token2Addr)
          .addUint256(liquidityAmount)
          .addUint256(minToken1Amount)
          .addUint256(minToken2Amount)
          .addAddress(AccountId.fromString(account).toSolidityAddress())
          .addUint256(deadline)
          .addInt64(serialNumber)
      )

    return tx
  }

  public getSwapTx(
    methodName: string,
    args: any,
    value: string,
    serialNumber: any
  ): ContractExecuteTransaction | undefined {
    let account
    let tx
    console.log('swap: ', methodName, args)
    value =
      value !== '0x0'
        ? JSBI.divide(JSBI.BigInt(value), JSBI.BigInt(Math.pow(10, HBAR_DECIMAL).toString())).toString()
        : value

    const gas_fee = methodName.includes('HBAR') ? GAS_SWAP_HBAR : GAS_SWAP_NON_HBAR

    if (
      methodName === 'swapExactHBARForTokensSupportingFeeOnTransferTokens' ||
      methodName === 'swapExactHBARForTokens' ||
      methodName === 'swapHBARForExactTokens'
    ) {
      const [amount, path, to, deadline] = args
      account = to
      tx = new ContractExecuteTransaction()
        .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
        .setGas(gas_fee)
        .setFunction(
          methodName,
          new ContractFunctionParameters()
            .addUint256(amount)
            .addAddressArray(path)
            .addAddress(AccountId.fromString(to).toSolidityAddress())
            .addUint256(deadline)
            .addInt64(serialNumber)
        )
        .setPayableAmount(new Hbar(value))
      account = to
    } else if (
      methodName === 'swapExactTokensForHBARSupportingFeeOnTransferTokens' ||
      methodName === 'swapExactTokensForHBAR' ||
      methodName === 'swapExactTokensForTokensSupportingFeeOnTransferTokens' ||
      methodName === 'swapExactTokensForTokens' ||
      methodName === 'swapTokensForExactHBAR' ||
      methodName === 'swapTokensForExactTokens'
    ) {
      const [amountIn, amountOut, path, to, deadline] = args
      tx = new ContractExecuteTransaction()
        .setContractId(V2_ROUTER_CONTRACT_IDS[CURRENT_CHAIN_ID])
        .setGas(gas_fee)
        .setFunction(
          methodName,
          new ContractFunctionParameters()
            .addUint256(amountIn)
            .addUint256(amountOut)
            .addAddressArray(path)
            .addAddress(AccountId.fromString(to).toSolidityAddress())
            .addUint256(deadline)
            .addInt64(serialNumber)
        )
        .setPayableAmount(new Hbar(value))
      account = to
    }

    return tx
  }

  public getAssociateTokenTx(account: string, tokenAddr: string): TokenAssociateTransaction {
    const tx = new TokenAssociateTransaction()
      .setAccountId(account)
      .setTokenIds([TokenId.fromSolidityAddress(tokenAddr)])

    return tx
  }

  public getMasterChefActionTx(methodName: string, args: MasterChefArgs, gas: number): ContractExecuteTransaction {
    console.log('Masterchef Action: ', methodName, args)

    const { poolId, amount, account } = args
    if (typeof poolId === undefined) {
      throw new Error('Pool Id is undefined')
    }

    const params = new ContractFunctionParameters().addUint256(poolId)
    if (amount) {
      params.addInt64(amount)
    }

    const tx = new ContractExecuteTransaction()
      .setContractId(ContractId.fromSolidityAddress(MASTERCHEF_ADDRESSES[CURRENT_CHAIN_ID]))
      .setGas(gas)
      .setFunction(methodName, params)

    return tx
  }

  public getLoanActionTx(
    loanAddress: string,
    methodName: LoanActionMethod,
    args: LoanActionArgs
  ): ContractExecuteTransaction {
    console.log('Loan Action: ', methodName, args)

    const { amount, account, serialNumber } = args

    const params = new ContractFunctionParameters()
    if (amount) {
      params.addInt64(amount)
    }
    if (serialNumber) {
      params.addInt64(serialNumber)
    }

    const tx = new ContractExecuteTransaction()
      .setContractId(ContractId.fromSolidityAddress(loanAddress))
      .setGas(GAS_LOAN_ACTION)
      .setFunction(methodName, params)

    return tx
  }

  public getLoanStakingActionTx(
    loanStakingAddress: string,
    methodName: LoanStakingActionMethod,
    args: LoanStakingActionArgs
  ): ContractExecuteTransaction {
    console.log('LoanStaking Action: ', methodName, args)

    const { amount, account, serialNumber } = args

    const params = new ContractFunctionParameters()
    if (amount) {
      params.addInt64(amount)
    }
    if (serialNumber) {
      params.addInt64(serialNumber)
    }

    const tx = new ContractExecuteTransaction()
      .setContractId(ContractId.fromSolidityAddress(loanStakingAddress))
      .setGas(GAS_LOAN_STAKING_ACTION)
      .setFunction(methodName, params)

    return tx
  }

  public async getCredentialTokens(accountId: string) {
    const network = (process.env.REACT_APP_CHAIN_ID ?? AmbidexChainId.HEDERA_TESTNET) as AmbidexChainId
    const tokenId = CREDENTIAL_TOKEN_IDS[network]
    const credentialTokens = await getNfts(accountId, tokenId)

    return credentialTokens
  }
}
