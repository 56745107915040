import { Currency, Token } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import flatMap from 'lodash.flatmap'
import { CUSTOM_BASES, WHBAR_ADDRESSES, CURRENT_CHAIN_ID, USDC, HBAR_DECIMAL } from '../constants'
import { useWalletState } from '../state/wallet/hooks'

const bases = [new Token(CURRENT_CHAIN_ID, WHBAR_ADDRESSES[CURRENT_CHAIN_ID], HBAR_DECIMAL, 'HBAR'), USDC]

export function useAllCurrencyCombinations(currencyA?: Currency, currencyB?: Currency): [Token, Token][] {
  const { chainId } = useWalletState()
  const [tokenA, tokenB] = [
    currencyA instanceof Token ? currencyA : undefined,
    currencyB instanceof Token ? currencyB : undefined,
  ]

  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])),
    []
  )

  return useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA, tokenB]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]

              const customBasesA: Token[] | undefined = customBases?.[tokenA.address]
              const customBasesB: Token[] | undefined = customBases?.[tokenB.address]

              if (!customBasesA && !customBasesB) return true

              if (customBasesA && !customBasesA.find((base) => tokenB.equals(base))) return false
              if (customBasesB && !customBasesB.find((base) => tokenA.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, basePairs, chainId]
  )
}
