import { Currency, Percent, Price } from '@uniswap/sdk-core'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'

const PriceColumn = styled(AutoColumn)`
  width: 33.33%;
`
const PriceColumnWrapper = styled(AutoRow)`
  row-gap: 1px;
  ${PriceColumn}:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
`
export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {
  return (
    <AutoColumn gap="md">
      <PriceColumnWrapper justify="space-around">
        <PriceColumn justify="center">
          <Text fontWeight={600} fontSize={16}>
            {price?.toSignificant(6) ?? '-'}
          </Text>
          <Text fontWeight={500} fontSize={14} color="#27292C" opacity={0.6} pt={2}>
            {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
          </Text>
        </PriceColumn>
        <PriceColumn justify="center">
          <Text fontWeight={600} fontSize={16}>
            {price?.invert()?.toSignificant(6) ?? '-'}
          </Text>
          <Text fontWeight={500} fontSize={14} color="#27292C" opacity={0.6} pt={2}>
            {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
          </Text>
        </PriceColumn>
        <PriceColumn justify="center">
          <Text fontWeight={600} fontSize={16}>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Text>
          <Text fontWeight={500} fontSize={14} color="#27292C" opacity={0.6} pt={2}>
            Share of Pool
          </Text>
        </PriceColumn>
      </PriceColumnWrapper>
    </AutoColumn>
  )
}
