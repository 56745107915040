import { createSlice } from '@reduxjs/toolkit'

import { AmbidexChainId } from 'constants/networks'
import { CredentialTokenType } from 'hooks/useCredentialTokens'
import { BLADE_WALLET_LOCALSTORAGE_KEY, CURRENT_CHAIN_ID, HASHPACK_LOCALSTORAGE_KEY } from 'constants/index'
import { hashpack, blade } from 'connectors'

export interface WalletState {
  name: string
  account: string
  chainId: AmbidexChainId
  credentialTokens: CredentialTokenType[] | null
  isAuthed: boolean
}

const initialState: WalletState = loadLocalData()

function loadLocalData(): WalletState {
  const defaultState = {
    name: '',
    account: '',
    chainId: CURRENT_CHAIN_ID,
    isAuthed: false,
    credentialTokens: null,
  }

  // CHECK HASHPACK
  const hashconnectData = localStorage.getItem(HASHPACK_LOCALSTORAGE_KEY)
  if (hashconnectData) {
    const saveData = JSON.parse(hashconnectData)
    if (saveData.pairingData?.length > 0) {
      return {
        ...defaultState,
        name: 'Hashpack',
        account: saveData.pairingData[saveData.pairingData.length - 1].accountIds[0],
      }
    }
  }

  // CHECK BLADE
  const bladeWalletData = localStorage.getItem(BLADE_WALLET_LOCALSTORAGE_KEY)
  if (bladeWalletData) {
    const saveData = JSON.parse(bladeWalletData)
    if (saveData.bladeAccountId) {
      return {
        ...defaultState,
        name: 'Blade',
        account: saveData.bladeAccountId,
      }
    }
  }

  return defaultState
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const { account, chainId, name } = action.payload
      state.account = account
      state.chainId = chainId
      state.name = name
    },
    setCredentialTokens: (state, action) => {
      state.credentialTokens = action.payload.credentialTokens
    },
    disconnect: (state) => {
      state.account = ''
      hashpack.clearPairings()
      blade.deactivate()
    },
  },
})

export const { setAccount, setCredentialTokens, disconnect } = walletSlice.actions
export const selectAccount = (state: any) => state.wallet

export default walletSlice.reducer
