import { AutoRow, RowFixed } from 'components/Row'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import styled from 'styled-components'
import { Text } from 'rebass'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { useDispatch } from 'react-redux'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import usePrevious from '../../hooks/usePrevious'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, TYPE } from '../../theme'
import AccountDetails from '../AccountDetails'
import { useWalletState } from '../../state/wallet/hooks'
import { setAccount } from '../../state/wallet/reducer'
import { ArrowLeft } from 'react-feather'

import Modal from '../Modal'
import Option from './Option'
import PendingView from './PendingView'
import { LightCard } from '../Card'

const CloseIcon = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 17px 24px;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.08);
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
  ENSName,
}: {
  pendingTransactions: string[] // hashes of pending
  confirmedTransactions: string[] // hashes of confirmed
  ENSName?: string
}) {
  // important that these are destructed from the account-specific web3-react context
  const { account } = useWalletState()
  const dispatch = useDispatch()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState<any>()

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  // close modal when a connection is successful

  const tryActivation = async (connector: any) => {
    let name = ''
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: name,
    })
    setPendingWallet(connector) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)

    connector
      ?.activate()
      .then(({ account, chainId }: any) => {
        dispatch(setAccount({ account, chainId, name }))
        setWalletView(WALLET_VIEWS.ACCOUNT)
      })
      .catch((e: any) => {
        console.log('Activation failed: ', e)
      })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    return Object.keys(SUPPORTED_WALLETS).map((key) => (
      <Option
        id={`connect-${key}`}
        onClick={() => {
          tryActivation(SUPPORTED_WALLETS[key].connector)
        }}
        key={key}
        active={false}
        color={SUPPORTED_WALLETS[key].color}
        link={SUPPORTED_WALLETS[key].href}
        header={SUPPORTED_WALLETS[key].name}
        subheader={null} //use option.descriptio to bring back multi-line
        icon={SUPPORTED_WALLETS[key].iconURL}
        installed={SUPPORTED_WALLETS[key].connector.installedExtension}
      />
    ))
  }

  function getModalContent() {
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          ENSName={ENSName}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      )
    }
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow color="blue">
            <HoverText
              onClick={() => {
                setPendingError(false)
                setWalletView(WALLET_VIEWS.ACCOUNT)
              }}
            >
              <RowFixed>
                <ArrowLeft size="24" color={'#27292C'} />
                <Text fontSize={20} fontWeight={700} paddingLeft={13}>
                  Back
                </Text>
              </RowFixed>
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <Text fontSize={20} fontWeight={600}>
              Connect to a wallet
            </Text>
          </HeaderRow>
        )}
        <Divider />
        <ContentWrapper>
          <AutoRow style={{ flexWrap: 'nowrap' }}>
            <Text fontSize={16} color={'#000000'} opacity={'0.6'}>
              Please connect a wallet to use the app.
            </Text>
          </AutoRow>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView connector={pendingWallet} setPendingError={setPendingError} tryActivation={tryActivation} />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
