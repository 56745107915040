import { Token } from '@uniswap/sdk-core'
import BigNumber from 'bignumber.js'
import { CURRENT_CHAIN_ID, WHBAR_ADDRESSES } from 'constants/index'
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch } from 'state'
import { useWalletState } from 'state/wallet/hooks'
import { BIG_ZERO } from 'utils/bigNumber'
import { fetchFarmsDataAsync, fetchFarmUserDataAsync } from './reducer'
import { DeserializedFarm, DeserializedFarmUserData, Farm } from './types'

const deserializeFarmUserData = (farm: Farm): DeserializedFarmUserData => {
  return {
    stakedAmountAMBI: farm.userData ? new BigNumber(farm.userData.stakedAmountAMBI) : BIG_ZERO,
    stakedAmountLP: farm.userData ? new BigNumber(farm.userData.stakedAmountLP) : BIG_ZERO,
    pendingRewards: farm.userData ? new BigNumber(farm.userData.pendingRewards) : BIG_ZERO,
    loanRewardEarned: farm.userData ? new BigNumber(farm.userData.loanRewardEarned) : BIG_ZERO,
  }
}

const deserializeFarm = (farm: Farm): DeserializedFarm => {
  const { pid, lpSymbol, lpAddress, tokenA, tokenB } = farm

  return {
    pid,
    lpSymbol,
    lpAddress,
    tokenA: new Token(CURRENT_CHAIN_ID, tokenA.address ?? '', tokenA.decimals, tokenA.symbol),
    tokenB: new Token(CURRENT_CHAIN_ID, tokenB.address ?? '', tokenB.decimals, tokenB.symbol),
    exchangeRate: farm.exchangeRate ? new BigNumber(farm.exchangeRate) : BIG_ZERO,
    tokenATotal: farm.tokenATotal ? new BigNumber(farm.tokenATotal) : BIG_ZERO,
    tokenBTotal: farm.tokenBTotal ? new BigNumber(farm.tokenBTotal) : BIG_ZERO,
    totalStakedAmountLP: farm.totalStakedAmountLP ? new BigNumber(farm.totalStakedAmountLP) : BIG_ZERO,
    totalStakedAmountAMBI: farm.totalStakedAmountAMBI ? new BigNumber(farm.totalStakedAmountAMBI) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    userData: deserializeFarmUserData(farm),
    // TODO: consider re-enabling farm APR and dailyVolumeUSD later
    // dailyVolumeUSD: farm.dailyVolumeUSD ? new BigNumber(farm.dailyVolumeUSD) : BIG_ZERO,
  }
}

export const usePollFarmsWithUserData = () => {
  const dispatch = useAppDispatch()
  const { account } = useWalletState()

  useSlowRefreshEffect(() => {
    dispatch(fetchFarmsDataAsync()).then(() => {
      if (account) {
        dispatch(fetchFarmUserDataAsync(account))
      }
    })
  }, [dispatch, account])
}

export const useFarms = () => {
  const farms = useSelector((state: AppState) => state.farms)
  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { userDataLoaded, dailyVolumeDataLoaded, publicDataLoaded, fetchDataFailed } = farms
  return {
    userDataLoaded,
    publicDataLoaded,
    dailyVolumeDataLoaded,
    fetchDataFailed,
    data: deserializedFarmsData,
  }
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm | null => {
  const farm = useSelector((state: AppState) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  if (!farm) return null

  return deserializeFarm(farm)
}

export const usePoolTotalLiquidity = (farm: DeserializedFarm) => {
  if (farm.tokenATotal.gt(0) && farm.tokenBTotal.gt(0)) {
    if (farm.tokenA.address.toLowerCase() === WHBAR_ADDRESSES[CURRENT_CHAIN_ID].toLowerCase()) {
      return farm.exchangeRate.times(farm.tokenATotal).times(2)
    }
    if (farm.tokenB.address.toLowerCase() === WHBAR_ADDRESSES[CURRENT_CHAIN_ID].toLowerCase()) {
      return farm.exchangeRate.times(farm.tokenBTotal).times(2)
    }
    if (farm.tokenA.symbol === 'USDC') {
      return farm.tokenATotal.times(2)
    }
    if (farm.tokenB.symbol === 'USDC') {
      return farm.tokenBTotal.times(2)
    }
  }
  return BIG_ZERO
}

export const useLpApr = (farm: DeserializedFarm) => {
  const totalLiquidityUSD = usePoolTotalLiquidity(farm)
  // TODO: consider re-enabling farm APR and dailyVolumeUSD later
  // if (totalLiquidityUSD.gt(0)) {
  //   return farm.dailyVolumeUSD.times(0.3).dividedBy(100).times(365).dividedBy(totalLiquidityUSD).times(100)
  // }
  return BIG_ZERO
}

export const useFarmLiquidity = (farm: DeserializedFarm) => {
  const totalLiquidityUSD = usePoolTotalLiquidity(farm)
  if (totalLiquidityUSD.gt(0) && farm.lpTotalSupply.gt(0)) {
    return totalLiquidityUSD.div(farm.lpTotalSupply).times(farm.totalStakedAmountLP)
  }
  return BIG_ZERO
}

export const useLiquidity = (farm: DeserializedFarm) => {
  const totalLiquidityUSD = usePoolTotalLiquidity(farm)
  if (totalLiquidityUSD.gt(0) && farm.lpTotalSupply.gt(0)) {
    return totalLiquidityUSD.div(farm.lpTotalSupply).times(farm.userData.stakedAmountLP)
  }
  return BIG_ZERO
}
