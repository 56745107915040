import lendBannerBgImg from 'assets/images/lend_banner_bg.png'
import PageHeader from 'components/Header/PageHeader'
import { CURRENT_CHAIN_ID } from 'constants/index'
import LOANS from 'constants/loans'
import AppBody from 'pages/AppBody'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Text } from 'rebass'
import { useResetLoans } from 'state/loans/hooks'
import styled from 'styled-components'

const PageWrapper = styled(AppBody)`
  max-width: 1240px;
  box-shadow: none;
  background-color: transparent;
  padding: 30px;
  width: 100%;
  margin-top: 16px;
  color: #27292c;
`

const LoanGrid = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-rows: minmax(100px, auto);

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const LoanCard = styled.div`
  margin: 0 auto;
  display: grid;
  background: #ffffff;
  box-shadow: 8px 16px 60px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`

const LoanBackImg = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`

const CompanyInfo = styled.div`
  padding: 16px 16px 24px 16px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const CompanyLogo = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CompanyLogoImg = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  overflow: hidden;
`

const LoanInfo = styled.div`
  padding: 28px 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const LoanInfoItem = styled.div`
  background: rgba(39, 41, 44, 0.08);
  border-radius: 4px;
  padding: 4px 6px;
  color: #272727;
  font-size: 14px;
`

const LoanStatusItem = styled(LoanInfoItem)`
  background: rgba(33, 146, 255, 0.08);
  color: #2192ff;
`

const LoanBanner = styled.div`
  background-image: url(${lendBannerBgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
  padding: 37px 46px 30px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  gap: 80px;
  cursor: pointer;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const AmbiMoneyBox = styled.div`
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.08);
  box-shadow: 7px 14px 70px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 36px 18px;
  display: inline-flex;
  align-items: center;
  gap: 14px;
  position: relative;
`

const AmbiMoneyLogo = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 2px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
`

const BannerApr = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 5px 21px 104px 10px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  padding: 15px 27px;
  right: -20px;
  bottom: -35px;
`

const Apr = styled.div`
  background: rgba(39, 41, 44, 0.08);
  border-radius: 5px;
  padding: 5px 7px;
`

const BannerDetails = styled.ul`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 20px;

  li {
    position: relative;
  }

  li:first-child:before {
    display: none;
  }

  li:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #ededed;
    border-radius: 100%;
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 1575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;

    li:first-child:before {
      display: block;
    }
  }
`

export default function LendListPage({ history }: any) {
  const handleClick = (loan: any) => {
    history.push(`/lend/detail/${loan.id}`)
  }

  useResetLoans()

  const handleBannerClick = () => {
    const loan = LOANS[CURRENT_CHAIN_ID].find((loan) => loan.industry === 'Money Market Fund')
    if (loan) {
      history.push(`/lend/detail/${loan.id}`)
    }
  }

  return (
    <>
      <Helmet>
        <title>Our Borrowers | Ambidex</title>
      </Helmet>
      <PageHeader />
      <PageWrapper>
        <Text fontSize="32px" fontWeight={700} mb="8px">
          Our Borrowers
        </Text>
        <Text fontSize="18px" fontWeight={300} mb="32px">
          Lend to our curated set of high quality real world companies and projects
        </Text>
        <LoanBanner onClick={handleBannerClick}>
          <AmbiMoneyBox>
            <AmbiMoneyLogo>
              <img src="/adx_logo.png" alt="logo" style={{ width: 56, height: 56 }} />
            </AmbiMoneyLogo>
            <div>
              <Text fontSize="28px" fontWeight={700} lineHeight="35px">
                AmbiMoney
              </Text>
              <Text fontSize="24px" color="#6437e7">
                Money Market Fund
              </Text>
            </div>
            <BannerApr>
              <Apr>APR 5%</Apr>
            </BannerApr>
          </AmbiMoneyBox>
          <Box>
            <Text fontSize="40px" fontWeight={700} color="#fff" fontFamily="Kanit" lineHeight={1}>
              Earn up to <span style={{ fontSize: 64 }}>5%</span>
            </Text>
            <Text fontSize="40px" fontWeight={700} color="#fff" fontFamily="Kanit" lineHeight={1}>
              per annum on your idle USDC
            </Text>
            <Text
              fontSize="24px"
              color="#fff"
              fontFamily="Kanit"
              lineHeight="36px"
              marginTop="24px"
              marginBottom="46px"
            >
              By lending to a <span style={{ color: '#6AFF52' }}>secure</span> money market fund
            </Text>
            <BannerDetails>
              <li>Transparent - no hidden fees</li>
              <li>Flexible - 24 hours redemptions</li>
              <li>Reliable - stable interest payouts</li>
            </BannerDetails>
          </Box>
        </LoanBanner>
        <LoanGrid>
          {LOANS[CURRENT_CHAIN_ID].filter((loan) => loan.industry !== 'Money Market Fund').map((loan, i) => (
            <LoanCard key={`loan-${i}`} onClick={() => handleClick(loan)}>
              <LoanBackImg src={loan.backImg} />
              <CompanyInfo>
                <CompanyLogo>
                  <CompanyLogoImg src={loan.logoImg} />
                </CompanyLogo>
                <Box ml="8px">
                  <Text fontWeight={700} lineHeight="20px">
                    {loan.name}
                  </Text>
                  <Text fontSize="14px" lineHeight="18px" color="#6437e7">
                    {loan.industry}
                  </Text>
                </Box>
              </CompanyInfo>
              <LoanInfo>
                <LoanStatusItem>{loan.status}</LoanStatusItem>
                <LoanInfoItem>{loan.apr} APR</LoanInfoItem>
                <LoanInfoItem>{loan.duration} years</LoanInfoItem>
              </LoanInfo>
            </LoanCard>
          ))}
        </LoanGrid>
      </PageWrapper>
    </>
  )
}
