import { Fraction, Rounding } from '@uniswap/sdk-core'
import BN from 'bignumber.js'
import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import { ConfirmationPendingContent } from 'components/TransactionConfirmationModal'
import Row, { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { ethers, BigNumber } from 'ethers'
import AppBody from 'pages/AppBody'
import { ChevronDown, ChevronUp } from 'react-feather'
import { RouteComponentProps } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { Text } from 'rebass'
import { useWalletModalToggle } from 'state/application/hooks'
import { useWalletState } from 'state/wallet/hooks'
import { useFarms, useLpApr, useLiquidity, usePollFarmsWithUserData } from 'state/farms/hooks'
import { DeserializedFarm } from 'state/farms/types'
import styled, { ThemeContext } from 'styled-components'
import Modal from './../../components/Modal/index'
import StakeFarmModal from './StakeFarmModal'
import ReactGA from 'react-ga4'
import PageHeader from '../../components/Header/PageHeader'
import {
  AMBI_TOKEN_ADDRESSES,
  USDC_ADDRESSES,
  CURRENT_CHAIN_ID,
  FarmAction,
  GAS_MASTERCHEF_ACTION,
} from '../../constants'
import { fetchFarmUserDataAsync } from 'state/farms/reducer'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { Helmet } from 'react-helmet'
import { getTokenId } from 'utils/hederaUtils'
import useConnector from 'hooks/useConnector'

const PageWrapper = styled(AppBody)`
  max-width: 1200px;
  box-shadow: none;
  background-color: transparent;
  padding: 30px;
  width: 100%;
  margin-top: 16px;
`

type FarmColumnType = {
  align?: string
  col?: number
}

const FarmColumn = styled(Column)<FarmColumnType>`
  width: ${({ col }) => (col ? `${100 * col}%` : '100%')};
  text-align: ${({ align }) => (align ? align : 'left')};
`

const FarmRowHeader = styled(RowBetween)`
  position: relative;
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  ${FarmColumn} {
    &:nth-child(1) {
      width: 33.3%;
    }
    &:nth-child(2) {
      width: 33.3%;
    }
    &:nth-child(3) {
      width: 33.3%;
    }
  }
`

const FarmRow = styled(RowBetween)`
  position: relative;
  padding: 20px 0;
  ${FarmColumn} {
    &:nth-child(1) {
      width: 33.3%;
      padding-left: 4px;
    }
    &:nth-child(2) {
      width: 33.3%;
    }
    &:nth-child(3) {
      width: 33.3%;
      padding-right: 24px;
    }
  }
`

type FarmRowWrapperType = {
  active?: boolean
}
const FarmRowWrapper = styled.div<FarmRowWrapperType>`
  background-color: ${({ active }) => (active ? 'white' : 'transparent')};
  box-shadow: ${({ active }) => (active ? '8px 16px 60px rgba(0, 0, 0, 0.08)' : 'none')};
  cursor: pointer;
  border-radius: ${({ active }) => (active ? '8px' : 'none')};
  ${FarmRow} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    &:hover {
      border-radius: ${({ active }) => (active ? 'none' : '8px')};
    }
  }
`

const FormDetailRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const FormDetailColumn = styled.div`
  padding: 40px 40px 20px 40px;
  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const FormDetailColumnButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  column-gap: 16px;
  align-items: end;
`
const FarmLabel = styled.div`
  background: linear-gradient(92.37deg, rgba(100, 55, 231, 0.08) 0%, rgba(212, 1, 201, 0.08) 100%);
  border-radius: 4px;
  padding: 4px 8px;
  margin: auto;
`

const FarmLabelText = styled.span`
  background: linear-gradient(92.37deg, #6437e7 0%, #d401c9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`

const ChevronColumn = styled(Column)`
  position: absolute;
  right: 4px;
  top: 22px;
`

const FarmButtonPrimary = styled(ButtonPrimary)`
  background-color: #5c38ff;
  font-size: 16px;
  font-weight: 600;
  width: 160px;
  padding: 16px 0px;
  &:disabled {
    background-color: #5c38ff;
  }
`

const FarmButtonSecondary = styled(ButtonSecondary)`
  background: white;
  border-color: #6437e7;
  font-weight: 600;
  font-size: 16px;
  width: 160px;
  padding: 16px 0px;
  &:last-of-type {
    margin: 8px auto 32px auto !important;
  }
`

const ModalWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`
const PoolView = styled.div<{ isActive: boolean }>`
  overflow: hidden;
  transition: ${(props) => (props.isActive ? 'height 0.5s ease 15ms' : 'height 0.5s ease 0ms')};
`

const EarnedRow = styled(Row)<{ show: boolean }>`
  justify-content: space-between;
  padding: 4px 0;
  margin: auto;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`

const FarmView = ({ farm, isOpen, onOpen }: { farm: DeserializedFarm; isOpen: boolean; onOpen: () => void }) => {
  const theme = useContext(ThemeContext)
  // TODO: consider re-enabling farm APR and dailyVolumeUSD later
  // const lpApr = useLpApr(farm)
  const liquidity = useLiquidity(farm)
  const { account } = useWalletState()
  const connector = useConnector()
  const toggleWalletModal = useWalletModalToggle()
  const dispatch = useAppDispatch()

  const [showActions, setShowActions] = useState(isOpen)
  const toggleActions = () => setShowActions(!showActions)

  const [farmAction, setFarmAction] = useState<FarmAction>(FarmAction.IDLE)
  const [modalOpen, setModalOpen] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [needAssociation, setNeedAssociation] = useState({
    AMBI: false,
    USDC: false,
  })
  const [harvesitngMsg, setHarvesitngMsg] = useState('')

  const hasAMBIRewards = useMemo(() => farm.userData.pendingRewards.gt(0), [farm.userData])
  const hasLoanRewards = useMemo(() => farm.userData.loanRewardEarned.gt(0), [farm.userData])

  const poolViewRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    if (account && connector) {
      const ambiTokenId = getTokenId(AMBI_TOKEN_ADDRESSES[CURRENT_CHAIN_ID]) || ''
      const usdcTokenId = getTokenId(USDC_ADDRESSES[CURRENT_CHAIN_ID]) || ''
      connector.getTokenBalances(account).then(({ tokens }) => {
        if (tokens) {
          if (!tokens.get(ambiTokenId))
            setNeedAssociation((prev) => {
              return { ...prev, AMBI: true }
            })
          if (!tokens.get(usdcTokenId))
            setNeedAssociation((prev) => {
              return { ...prev, USDC: true }
            })
        }
      })
    }
  }, [account, connector])

  useEffect(() => {
    if (showActions) {
      onOpen()
    }
  }, [showActions])

  useEffect(() => {
    setShowActions(isOpen)
  }, [isOpen])

  const onConfirm = (amount: BigNumber) => {
    setAttemptingTxn(true)
    const methodNames: Record<FarmAction, string> = {
      [FarmAction.STAKE_LP]: 'depositLPTokens',
      [FarmAction.UNSTAKE_LP]: 'withdrawLPTokens',
      [FarmAction.STAKE_AMBI]: 'stakeAMBITokens',
      [FarmAction.UNSTAKE_AMBI]: 'unstakeAMBITokens',
      [FarmAction.IDLE]: '',
      [FarmAction.HARVEST]: 'claimReward',
    }
    connector
      ?.masterChefAction(
        methodNames[farmAction],
        {
          poolId: farm.pid,
          amount: amount.toString(),
          account,
        },
        GAS_MASTERCHEF_ACTION[farmAction]
      )
      .then((txId) => {
        setAttemptingTxn(false)
        setTxHash(txId)
        ReactGA.event({
          category: 'Farm',
          action: methodNames[farmAction],
          label: farm.lpSymbol,
        })

        dispatch(fetchFarmUserDataAsync(account))
      })
      .catch((error) => {
        setAttemptingTxn(false)
        const txId = error.message
        ReactGA.event({
          category: 'Error',
          action: `Error: farm - ${methodNames[farmAction]}`,
          label: `${farm.lpSymbol} - ${txId}`,
        })
        console.error(error)
      })
  }

  const onHarvest = async () => {
    setFarmAction(FarmAction.HARVEST)
    setModalOpen(true)
    try {
      if (hasAMBIRewards) {
        if (needAssociation.AMBI) {
          await connector?.associateToken(account, AMBI_TOKEN_ADDRESSES[CURRENT_CHAIN_ID])
          setNeedAssociation((prev) => {
            return { ...prev, AMBI: false }
          })
        }
        setHarvesitngMsg('Harvesting AMBI...')
        await connector?.masterChefAction(
          'claimReward',
          { poolId: farm.pid, account },
          GAS_MASTERCHEF_ACTION[FarmAction.HARVEST]
        )
      }
      if (hasLoanRewards) {
        if (needAssociation.USDC) {
          await connector?.associateToken(account, USDC_ADDRESSES[CURRENT_CHAIN_ID])
          setNeedAssociation((prev) => {
            return { ...prev, USDC: false }
          })
        }
        setHarvesitngMsg('Harvesting USDC Loan Rewards...')
        await connector?.masterChefAction(
          'claimLoanReward',
          { poolId: farm.pid, account },
          GAS_MASTERCHEF_ACTION[FarmAction.HARVEST]
        )
      }

      dispatch(fetchFarmUserDataAsync(account))

      ReactGA.event({
        category: 'Farm',
        action: 'Harvest',
        label: farm.lpSymbol,
      })
    } catch (error: any) {
      ReactGA.event({
        category: 'Error',
        action: 'Error: farm - harvest AMBI',
        label: `${farm.lpSymbol} - ${error.message}`,
      })
      console.error(error)
    }
    setModalOpen(false)
    setFarmAction(FarmAction.IDLE)
  }

  const onFarmAction = (type: FarmAction) => {
    setTxHash('')
    setFarmAction(type)
    setModalOpen(true)
  }

  const convertToSignificant = (amount: BN, digits = 2) => {
    const amountStr = amount.toFixed(6, BN.ROUND_DOWN)
    const numerator = ethers.utils.parseUnits(amountStr, 6)
    const significantDigits = amount.isZero() ? 1 : Math.floor(Math.log10(Number(amountStr))) + 1 + digits

    return new Fraction(numerator.toString(), Math.pow(10, 6)).toSignificant(
      significantDigits,
      undefined,
      Rounding.ROUND_DOWN
    )
  }

  const getModalContent = () => {
    if (farmAction === FarmAction.HARVEST) {
      return (
        <ConfirmationPendingContent
          onDismiss={toggleModal}
          pendingText={`${needAssociation ? 'To harvest, you need to associate the AMBI token' : harvesitngMsg}`}
        />
      )
    } else {
      return (
        <StakeFarmModal
          toggleModal={toggleModal}
          lpAddress={farm.lpAddress}
          lpSymbol={farm.lpSymbol}
          totalStakedAmountLP={farm.userData.stakedAmountLP}
          totalStakedAmountAMBI={farm.userData.stakedAmountAMBI}
          onConfirm={onConfirm}
          attemptingTxn={attemptingTxn}
          hash={txHash ? txHash : ''}
          type={farmAction}
        ></StakeFarmModal>
      )
    }
  }

  return (
    <FarmRowWrapper active={showActions}>
      <FarmRow onClick={toggleActions}>
        <FarmColumn>
          <RowFixed>
            <DoubleCurrencyLogo currency0={farm.tokenA} currency1={farm.tokenB} margin={true} size={24} />
            <Text fontSize={16} color={theme.text1} paddingLeft="18px" fontWeight={600}>
              {farm.tokenA.symbol} - {farm.tokenB.symbol}
            </Text>
          </RowFixed>
        </FarmColumn>
        <FarmColumn>
          <Text fontSize={16} color={theme.text1}>
            {convertToSignificant(farm.userData.pendingRewards)} AMBI
          </Text>
        </FarmColumn>
        {/* TODO: consider re-enabling farm APR and dailyVolumeUSD later */}
        {/* <FarmColumn>
          <Text fontSize={16} color={theme.text1}>
            {lpApr ? lpApr.toFixed(2) + '%' : '-'}
          </Text>
        </FarmColumn> */}
        <FarmColumn align="right">
          <Text fontSize={16} color={theme.text1}>
            ${convertToSignificant(liquidity)}
          </Text>
        </FarmColumn>
        {
          <ChevronColumn>
            {!showActions ? <ChevronDown size={16} cursor="pointer" /> : <ChevronUp size={16} cursor="pointer" />}
          </ChevronColumn>
        }
      </FarmRow>
      <PoolView
        isActive={showActions}
        ref={poolViewRef}
        style={
          showActions
            ? {
                height: poolViewRef.current.scrollHeight ? `${poolViewRef.current.scrollHeight}px` : '250px',
              }
            : { height: '0px' }
        }
      >
        {account ? (
          <FormDetailRow>
            <FormDetailColumn>
              <AutoColumn>
                <FarmLabel>
                  <FarmLabelText>Staked LP</FarmLabelText>
                </FarmLabel>
                <Text fontSize={24} color="#27292C" fontWeight={600} margin="auto" paddingTop="16px">
                  {convertToSignificant(farm.userData.stakedAmountLP)}
                </Text>
              </AutoColumn>
              <FormDetailColumnButtonWrapper>
                <FarmButtonSecondary
                  onClick={() => {
                    onFarmAction(FarmAction.UNSTAKE_LP)
                  }}
                >
                  Unstake LP
                </FarmButtonSecondary>
                <FarmButtonPrimary
                  onClick={() => {
                    onFarmAction(FarmAction.STAKE_LP)
                  }}
                >
                  Stake LP
                </FarmButtonPrimary>
              </FormDetailColumnButtonWrapper>
            </FormDetailColumn>
            <FormDetailColumn>
              <AutoColumn>
                <FarmLabel>
                  <FarmLabelText>Staked AMBI</FarmLabelText>
                </FarmLabel>
                <Text fontSize={24} color="#27292C" fontWeight={600} margin="auto" paddingTop="16px">
                  {convertToSignificant(farm.userData.stakedAmountAMBI)}
                </Text>
              </AutoColumn>
              <FormDetailColumnButtonWrapper>
                <FarmButtonSecondary
                  onClick={() => {
                    onFarmAction(FarmAction.UNSTAKE_AMBI)
                  }}
                >
                  Unstake AMBI
                </FarmButtonSecondary>
                <FarmButtonPrimary
                  onClick={() => {
                    onFarmAction(FarmAction.STAKE_AMBI)
                  }}
                >
                  Stake AMBI
                </FarmButtonPrimary>
              </FormDetailColumnButtonWrapper>
            </FormDetailColumn>
            <FormDetailColumn>
              <AutoColumn>
                <FarmLabel>
                  <FarmLabelText>Earned Rewards</FarmLabelText>
                </FarmLabel>
                <EarnedRow style={{ paddingTop: '16px' }} show>
                  <Text fontSize={24} color="#27292C" fontWeight={600} width="100%" style={{ textAlign: 'center' }}>
                    {convertToSignificant(farm.userData.pendingRewards)}
                    <span style={{ paddingLeft: '10px', fontWeight: 500 }}>AMBI</span>
                  </Text>
                </EarnedRow>
                <EarnedRow show={farm.userData.loanRewardEarned.toFixed(2) !== '0.00'}>
                  <Text fontSize={24} color="#27292C" fontWeight={600} width="100%" style={{ textAlign: 'center' }}>
                    {convertToSignificant(farm.userData.loanRewardEarned)}
                    <span style={{ paddingLeft: '10px', fontWeight: 500 }}>USDC</span>
                  </Text>
                </EarnedRow>
              </AutoColumn>
              <FormDetailColumnButtonWrapper>
                <FarmButtonPrimary disabled={!(hasAMBIRewards || hasLoanRewards)} onClick={onHarvest} padding="40px">
                  Harvest
                </FarmButtonPrimary>
              </FormDetailColumnButtonWrapper>
            </FormDetailColumn>
          </FormDetailRow>
        ) : (
          <Row padding="12px 24px">
            <FarmButtonPrimary onClick={toggleWalletModal}>Connect Wallet</FarmButtonPrimary>
          </Row>
        )}
      </PoolView>
      <Modal isOpen={modalOpen} onDismiss={toggleModal} minHeight={false} maxHeight={90}>
        <ModalWrapper>{getModalContent()}</ModalWrapper>
      </Modal>
    </FarmRowWrapper>
  )
}

export default function FarmPage() {
  const { data: farmsLP, userDataLoaded, fetchDataFailed, publicDataLoaded } = useFarms()
  const { account } = useWalletState()
  const [openPoolId, setOpenPoolId] = useState(-1)

  usePollFarmsWithUserData()

  return (
    <>
      <Helmet>
        <title>Farm | Ambidex</title>
      </Helmet>
      <PageHeader />
      <PageWrapper>
        <Text fontSize={30} fontWeight={600} marginBottom="28px">
          Farm
        </Text>
        <FarmRowHeader>
          <FarmColumn>
            <Text fontSize={16} fontWeight={700} marginBottom="4px">
              Pool
            </Text>
          </FarmColumn>
          <FarmColumn>
            <Text fontSize={16} fontWeight={700} marginBottom="4px">
              Earned
            </Text>
          </FarmColumn>
          {/* TODO: consider re-enabling farm APR and dailyVolumeUSD later */}
          {/* <FarmColumn>
            <Text fontSize={16} fontWeight={700} marginBottom="4px">
              APR
            </Text>
          </FarmColumn> */}
          <FarmColumn align="right">
            <Text fontSize={16} fontWeight={700} marginBottom="4px">
              Liquidity
            </Text>
          </FarmColumn>
        </FarmRowHeader>
        {(!userDataLoaded || !publicDataLoaded) && !fetchDataFailed && <Text paddingY="20px">Loading...</Text>}
        {fetchDataFailed && <Text paddingY="20px">Failed to fetch data, please try again</Text>}
        {userDataLoaded &&
          publicDataLoaded &&
          (farmsLP.length === 0 ? (
            <Text paddingY="20px">There are currently no active pools.</Text>
          ) : (
            farmsLP.map((farm) => (
              <FarmView
                key={farm.pid}
                farm={farm}
                isOpen={farm.pid === openPoolId}
                onOpen={() => setOpenPoolId(farm.pid)}
              />
            ))
          ))}
      </PageWrapper>
    </>
  )
}
