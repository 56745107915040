import styled from 'styled-components'

export const StandardPageWrapper = styled.div`
  padding-top: 160px;
  width: 100%;
`
export const AppWrapper = styled.div`
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SwapPageAppWrapper = styled.div`
  padding: 50px 32px;
`
export const CenterredCardAppWrapper = styled.div`
  position: relative;
  padding: 24px 40px 40px 40px;
`
