import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { Pair, Trade } from '@ambidex/sdk'
import { useMemo } from 'react'
import { useUserSingleHopOnly } from 'state/user/hooks'
import { isTradeBetter } from 'utils/isTradeBetter'
import { BETTER_TRADE_LESS_HOPS_THRESHOLD } from '../constants'
import { useAllCurrencyCombinations } from './useAllCurrencyCombinations'
import { PairState, useV2Pairs } from './useV2Pairs'

export enum V2TradeState {
  LOADING,
  INVALID,
  NO_ROUTE_FOUND,
  VALID,
  SYNCING,
}

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): { isLoading: boolean; allowedPairs: Pair[] } {
  const allCurrencyCombinations = useAllCurrencyCombinations(currencyA, currencyB)
  const allPairs = useV2Pairs(allCurrencyCombinations)
  const isLoading = !(allCurrencyCombinations.length === allPairs.length && allPairs.every(([state]) => state != 0))
  // only pass along valid pairs, non-duplicated pairs
  return useMemo(() => {
    const allowedPairs = Object.values(
      allPairs
        // filter out invalid pairs
        .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
        // filter out duplicated pairs
        .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
          memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
          return memo
        }, {})
    )
    return { isLoading, allowedPairs }
  }, [isLoading, allPairs])
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useV2TradeExactIn(
  currencyAmountIn?: CurrencyAmount,
  currencyOut?: Currency
): { state: V2TradeState; trade: Trade | null } {
  const { isLoading, allowedPairs } = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)
  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (isLoading)
      return {
        state: V2TradeState.LOADING,
        trade: null,
      }
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return {
          state: V2TradeState.VALID,
          trade:
            Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null,
        }
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return {
        state: V2TradeState.VALID,
        trade: bestTradeSoFar,
      }
    }
    return {
      state: V2TradeState.NO_ROUTE_FOUND,
      trade: null,
    }
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useV2TradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount
): { state: V2TradeState; trade: Trade | null } {
  const { isLoading, allowedPairs } = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (isLoading)
      return {
        state: V2TradeState.LOADING,
        trade: null,
      }
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return {
          state: V2TradeState.VALID,
          trade:
            Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null,
        }
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return {
        state: V2TradeState.VALID,
        trade: bestTradeSoFar,
      }
    }
    return {
      state: V2TradeState.NO_ROUTE_FOUND,
      trade: null,
    }
  }, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly])
}
