import { useEffect, useState } from 'react'
import { useWalletState } from 'state/wallet/hooks'
import useConnector from './useConnector'

export type CredentialTokenType = {
  account_id: string
  created_timestamp: string
  delegating_spender: string | null
  deleted: boolean
  metadata: string
  modified_timestamp: string
  serial_number: number
  spender: string | null
  token_id: string
}

export default function useCredentialTokens() {
  const [credentialTokens, setCredentialTokens] = useState<CredentialTokenType[]>()
  const { account } = useWalletState()
  const connector = useConnector()

  useEffect(() => {
    if (account && connector) {
      connector.getCredentialTokens(account).then((nfts) => setCredentialTokens(nfts))
    }
  }, [account, connector])

  return credentialTokens
}
