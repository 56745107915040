import filLogo from '../assets/images/fil.png'
import shibarLogo from '../assets/images/shibar.svg'
import usdcLogo from '../assets/images/usdc.svg'
import sauceLogo from '../assets/images/sauce.svg'
import solLogo from '../assets/images/sol.jpeg'

export const TESTNET_TOKENS = [
  {
    id: '0.0.6024',
    symbol: 'USDC',
    decimals: 6,
    logoURI: usdcLogo,
  },
  {
    id: '0.0.3684093',
    symbol: 'KB2',
    decimals: 6,
  },
  {
    id: '0.0.6176',
    symbol: 'SOL',
    decimals: 6,
    logoURI: solLogo,
  },
  {
    id: '0.0.6191',
    symbol: 'FIL',
    decimals: 6,
    logoURI: filLogo,
  },
  {
    id: '0.0.3071919',
    symbol: 'RTL1',
    decimals: 6,
  },
  {
    id: '0.0.6198',
    symbol: 'SHIBAR',
    decimals: 6,
    logoURI: shibarLogo,
  },
]

export const MAINNET_TOKENS = [
  {
    id: '0.0.456858',
    symbol: 'USDC',
    decimals: 6,
    logoURI: usdcLogo,
  },
  {
    id: '0.0.2026966',
    symbol: 'KBR',
    decimals: 6,
  },
  {
    id: '0.0.1939133',
    symbol: 'SIERRA',
    decimals: 6,
  },
  {
    id: '0.0.1939182',
    symbol: 'BRAVO',
    decimals: 6,
  },
]
