/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Box, Flex, Link, Text } from 'rebass'
import styled from 'styled-components'
import DocSvg from '../../assets/images/doc.svg'
import { CURRENT_CHAIN_ID } from 'constants/index'
import LOANS from 'constants/loans'

const TabBox = styled.div`
  background: #f5f5f5;
  width: 100%;
  padding-top: 26px;
  margin-top: 20px;
`

const Tabs = styled.div`
  max-width: 992px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  gap: 36px;
`

const TabPanel = styled.div`
  max-width: 992px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 30px;
`

const Tab = styled('div')<{ active?: boolean }>`
  padding-bottom: 10px;
  font-weight: 600;
  color: ${({ active }) => (active ? '#6437E7' : '#27292C')};
  border-bottom: ${({ active }) => (active ? '2px solid #6437E7' : 'none')};
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  min-height: 640px;
  object-fit: cover;
`

const LoanInfoBox = styled.div`
  background: #ffffff;
  box-shadow: 4px 8px 60px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px 48px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const LoanInfoItem = styled.div`
  text-align: center;
`

const LoanOfferTable = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 32px;
  border-bottom: 0;
  overflow: hidden;
`

const LoanOfferRow = styled.div`
  padding: 13px 20px 15px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const PdfLink = styled(Link)`
  text-decoration: none;
  color: #6437e7;
  display: flex;
  align-items: center;
  gap: 8px;
`

const InterestPaymentScheduleTable = styled.table`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 16px;

  td,
  th {
    padding: 12px 10px 12px 16px;
    width: 33.3%;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-collapse: collapse;
    text-align: left;
  }

  td:last-child,
  th:last-child {
    border-right: none;
  }

  tbody tr:last-child td,
  tbody tr:last-child th {
    border-bottom: none;
  }
`

const MarkdownWrapper = styled.div`
  img {
    max-width: 100%;
    min-width: 100%;
  }

  p {
    line-height: 1.5;
  }
`

enum OverviewTab {
  PROJECT,
  DETAILS,
  NEWS,
}

export const LoanOverview = ({ loanContractAddress }: { loanContractAddress: string }) => {
  const [status, setStatus] = useState<OverviewTab>(OverviewTab.PROJECT)
  const [markdown, setMarkdown] = useState('')
  const loan = LOANS[CURRENT_CHAIN_ID].find((l) => l.loanAddress === loanContractAddress)

  useEffect(() => {
    if (loan && loan.markdownPath) {
      fetch(loan.markdownPath)
        .then((res) => res.text())
        .then((text) => setMarkdown(text))
    }
  }, [])

  return (
    <>
      <TabBox>
        <Tabs>
          <Tab active={status === OverviewTab.PROJECT} onClick={() => setStatus(OverviewTab.PROJECT)}>
            The project
          </Tab>
          {loan?.investmentOffer && (
            <Tab active={status === OverviewTab.DETAILS} onClick={() => setStatus(OverviewTab.DETAILS)}>
              Loan details
            </Tab>
          )}
          {loan?.news && (
            <Tab active={status === OverviewTab.NEWS} onClick={() => setStatus(OverviewTab.NEWS)}>
              News
            </Tab>
          )}
        </Tabs>
      </TabBox>
      {status === OverviewTab.PROJECT && (
        <TabPanel>
          <MarkdownWrapper>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={markdown}
              linkTarget="_blank"
              transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.REACT_APP_BASE_URL}${uri}`)}
            />
          </MarkdownWrapper>
        </TabPanel>
      )}
      {status === OverviewTab.DETAILS && (
        <TabPanel>
          <LoanInfoBox>
            <LoanInfoItem>
              <Text fontSize={14} fontWeight={300} lineHeight="18px">
                APR
              </Text>
              <Text fontWeight={600} lineHeight="20px" marginTop="4px">
                {loan?.investmentOffer?.apr}
              </Text>
            </LoanInfoItem>
            <LoanInfoItem>
              <Text fontSize={14} fontWeight={300} lineHeight="18px">
                Maturity Date
              </Text>
              <Text fontWeight={600} lineHeight="20px" marginTop="4px">
                {loan?.investmentOffer?.maturityDate}
              </Text>
            </LoanInfoItem>
            <LoanInfoItem>
              <Text fontSize={14} fontWeight={300} lineHeight="18px">
                Next interest payment date
              </Text>
              <Text fontWeight={600} lineHeight="20px" marginTop="4px">
                {loan?.investmentOffer?.nextInterestPaymentDate}
              </Text>
            </LoanInfoItem>
            <LoanInfoItem>
              <Text fontSize={14} fontWeight={300} lineHeight="18px">
                Financing Volume
              </Text>
              <Text fontWeight={600} lineHeight="20px" marginTop="4px">
                {loan?.investmentOffer?.financingVolume}
              </Text>
            </LoanInfoItem>
          </LoanInfoBox>
          <LoanOfferTable>
            <LoanOfferRow>
              <Text fontWeight={600} flex={1}>
                Loan type:
              </Text>
              <Text flex={1}>{loan?.investmentOffer?.loanType}</Text>
            </LoanOfferRow>
            <LoanOfferRow>
              <Text fontWeight={600} flex={1}>
                Interest payment frequency:
              </Text>
              <Text flex={1}>{loan?.investmentOffer?.interestPaymentFrequency}</Text>
            </LoanOfferRow>
            <LoanOfferRow>
              <Text fontWeight={600} flex={1}>
                Fully issued as of:
              </Text>
              <Text flex={1}>{loan?.investmentOffer?.fullyIssuedAsOf}</Text>
            </LoanOfferRow>
            <LoanOfferRow>
              <Text fontWeight={600} flex={1}>
                Minimum investment amount:
              </Text>
              <Text flex={1}>{loan?.investmentOffer?.minimumInvestmentAmount} USDC</Text>
            </LoanOfferRow>
            <LoanOfferRow>
              <Text fontWeight={600} flex={1}>
                Maximum investment amount:
              </Text>
              <Text flex={1}>{loan?.investmentOffer?.maximumInvestmentAmount}</Text>
            </LoanOfferRow>
            {loan?.investmentOffer?.relevantDocuments?.length > 0 && (
              <LoanOfferRow>
                <Text fontWeight={600} flex={1}>
                  Relevant Documents (PDF):
                </Text>
                <Flex flex={1} display="flex" flexDirection="column" style={{ gap: '12px' }}>
                  {loan?.investmentOffer?.relevantDocuments?.map((doc: any, i: number) => (
                    <PdfLink href={doc.url} key={i}>
                      <img src={DocSvg} />
                      {doc.name}
                    </PdfLink>
                  ))}
                </Flex>
              </LoanOfferRow>
            )}
          </LoanOfferTable>
          <Text marginTop="32px" fontSize={24} fontWeight={700} lineHeight="30px">
            Interest payment schedule
          </Text>
          <InterestPaymentScheduleTable>
            <thead>
              <tr>
                <th>Date of distribution</th>
                <th>Interest distributed (estimated range provided for future payments)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loan?.investmentOffer?.interestPaymentSchedules.map((schedule: any, i: number) => (
                <tr key={i}>
                  <td>{schedule.date}</td>
                  <td>{schedule.interest}</td>
                  <td>{schedule.status}</td>
                </tr>
              ))}
            </tbody>
          </InterestPaymentScheduleTable>
          <Text marginTop="32px" fontSize={24} fontWeight={700} lineHeight="30px">
            Financing Structure
          </Text>
          <Text marginTop="16px" lineHeight="24px">
            {loan?.investmentOffer?.financingStructure}
          </Text>
        </TabPanel>
      )}
      {status === OverviewTab.NEWS && (
        <TabPanel>
          {loan?.news.map((item: any, i: number) => (
            <Box key={i} marginBottom="40px">
              <Text fontSize={24} color="#6437e7" lineHeight="30px" marginBottom="24px" fontWeight={700}>
                {item.date}
              </Text>
              <Text lineHeight="24px">{item.content}</Text>
            </Box>
          ))}
        </TabPanel>
      )}
    </>
  )
}

export default LoanOverview
