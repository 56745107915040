import { Transaction, AccountId, TransactionId } from '@hashgraph/sdk'
import { HEDERA_NODE_IDS } from 'constants/index'

export function makeBytes(trans: Transaction, signingAcctId: string) {
  const transId = TransactionId.generate(signingAcctId)

  trans.setTransactionId(transId)
  trans.setNodeAccountIds(HEDERA_NODE_IDS.map((nodeId: string) => new AccountId(+nodeId)))

  trans.freeze()

  const transBytes = trans.toBytes()

  return transBytes
}
