import { Currency, CurrencyAmount, Token, TokenAmount } from '@uniswap/sdk-core'
import { JSBI, Pair } from '@ambidex/sdk'
import { useEffect, useMemo, useState } from 'react'
import { useAllTokens } from '../../hooks/Tokens'
import { useMulticall2Contract } from '../../hooks/useContract'
import { useSlowRefreshEffect } from '../../hooks/useRefreshEffect'
import { isAddress } from '../../utils'
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/hooks'
import { useTotalUniEarned } from '../stake/hooks'
import { UNI } from './../../constants/index'
import { Interface } from '@ethersproject/abi'
import ERC20ABI from 'abis/erc20.json'
import { Erc20Interface } from 'abis/types/Erc20'
import { AppState, useAppDispatch } from 'state'
import { useSelector } from 'react-redux'
import { useV2LiquidityTokens } from 'state/user/hooks'
import { CURRENT_CHAIN_ID, LP_TOKEN_DECIMAL, WHBAR_ADDRESSES } from '../../constants/index'
import { isAccountWhitelisted } from 'utils'
import { setCredentialTokens } from './reducer'
import { getTokenId } from 'utils/hederaUtils'
import useConnector from 'hooks/useConnector'

export function usePollCredentialTokens() {
  const dispatch = useAppDispatch()
  const { account } = useWalletState()
  const connector = useConnector()

  useSlowRefreshEffect(() => {
    const fetchCredentialTokens = async () => {
      if (account && connector) {
        const credentialTokens = await connector.getCredentialTokens(account)
        dispatch(setCredentialTokens({ credentialTokens }))
      }
    }
    fetchCredentialTokens()
  }, [dispatch, account, connector])
}

export function useWalletState(): AppState['wallet'] {
  return useSelector((state: AppState) => {
    const { account, credentialTokens } = state.wallet
    return {
      ...state.wallet,
      isAuthed: isAccountWhitelisted(account) || (Array.isArray(credentialTokens) && credentialTokens.length > 0),
    }
  })
}

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */

export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticall2Contract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])
  const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface
  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20Interface,
    'balanceOf',
    [address],
    undefined,
    100_000
  )

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[token.address] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading,
  ]
}

export function useTokenBalances(
  address?: string,
  currencies?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  const [balances, setBalances] = useState({})
  const tokens = useMemo(() => currencies ?? [], [currencies])
  const connector = useConnector()

  useEffect(() => {
    if (address && tokens && connector) {
      connector?.getTokenBalances(address).then((tokenBalances) => {
        const newBalances = tokens.reduce((prev, token) => {
          if (token && token.address) {
            const tokenId = getTokenId(token.address)
            if (token.address == WHBAR_ADDRESSES[CURRENT_CHAIN_ID]) {
              const numerator = JSBI.BigInt((tokenBalances.hbar ?? 0).toString())
              return {
                ...prev,
                [token.address]: new TokenAmount(token, numerator),
              }
            }
            if (tokenId && tokenBalances.tokens?.get(tokenId)) {
              const numerator = JSBI.BigInt((tokenBalances.tokens.get(tokenId) ?? 0).toString())
              const denominator = JSBI.BigInt(Math.pow(10, tokenBalances.tokenDecimals?.get(tokenId) ?? 1))
              return {
                ...prev,
                [token.address]: new TokenAmount(token, numerator),
              }
            }
            return {
              ...prev,
              [token.address]: undefined,
            }
          }

          return prev
        }, {})
        if (
          JSON.stringify(balances) !== JSON.stringify(newBalances) ||
          Object.keys(balances).length !== Object.keys(newBalances).length
        ) {
          setBalances(newBalances)
        }
      })
    }
  }, [address, connector, currencies, tokens])
  return useMemo(() => balances, [balances])
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function usePairTokenBalance(account?: string, pair?: Pair) {
  const inputs = useMemo(() => [[pair?.token0 as Token, pair?.token1 as Token]] as [Token, Token][], [pair])
  const liquidityToken = useV2LiquidityTokens(inputs)[0]
  const tokenBalance = useTokenBalance(account, liquidityToken)

  return useMemo(() => {
    if (!liquidityToken) return undefined
    return tokenBalance
      ? new TokenAmount(
          new Token(CURRENT_CHAIN_ID, pair?.liquidityToken.address as string, LP_TOKEN_DECIMAL),
          tokenBalance.raw
        )
      : undefined
  }, [tokenBalance, liquidityToken, pair?.liquidityToken.address])
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies,
  ])

  const tokenBalances = useTokenBalances(account, tokens)

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        return undefined
      }) ?? [],
    [account, currencies, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(
    account,
    useMemo(() => [currency], [currency])
  )[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useWalletState()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
